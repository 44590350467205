import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import PhoneLoginScreen from "./PhoneLogin";
import PhoneVerification from "./PhoneVerification";
import { useAuth } from "context/auth";

export default function Phone() {
  const { phoneLogin } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [
    confirm,
    setConfirm,
  ] = useState<firebase.auth.ConfirmationResult | null>(null);
  const { url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (confirm === null || phoneNumber === null) {
      history.replace(url);
    }
  }, [confirm, history, url, phoneNumber]);

  return (
    <Switch>
      <Route exact path={url}>
        <PhoneLoginScreen
          onSubmit={(_confirm: any, _phone: any) => {
            setConfirm(_confirm);
            setPhoneNumber(_phone);
            history.push(`${url}/verify`);
          }}
        />
      </Route>
      <Route exact path={`${url}/verify`}>
        <PhoneVerification
          onSubmit={(code) => phoneLogin(confirm!, code)}
          phoneNumber={phoneNumber!}
          onBack={() => {
            setConfirm(null);
          }}
        />
      </Route>
    </Switch>
  );
}
