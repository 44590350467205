import React, { ReactNode } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { Text, Flex } from "rebass/styled-components";
import { Platform, useMeQuery, MeQuery } from "graphql/__generated__/types";
import { useFirebase } from "./firebase";
import Button from "components/Button";

interface Props {
  children: ReactNode;
}

interface ContextProps {
  data?: MeQuery;
  logout: () => Promise<void>;
  googleLogin: () => Promise<void>;
  emailLogin: (email: string, password: string) => Promise<void>;
  sendVerificationCode: (
    phone: string
  ) => Promise<firebase.auth.ConfirmationResult>;
  phoneLogin: (
    confirmResult: firebase.auth.ConfirmationResult,
    code: string
  ) => Promise<void>;
}

// @ts-ignore
export const AuthContext = React.createContext<ContextProps>();

function AuthProvider(props: Props) {
  const { verifier } = useFirebase();
  const { loading, error, data, refetch, client: apolloClient } = useMeQuery({
    variables: {
      platform: Platform.Ios,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return <Text>Error</Text>;
  }

  if (data?.me.__typename === "User") {
    if (
      !data.me.listener ||
      data.me.listener.isBanned ||
      !data.me.listener.isEnabled
    ) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bg="background"
          sx={{ flex: 1, height: "100vh" }}
        >
          <Text variant="header" mb={2}>
            {!data.me.listener
              ? "You must be a Listener to access the webapp"
              : data.me.listener.isBanned || data.me.isSuspended
              ? "Your account has been suspended"
              : !data.me.listener.isEnabled &&
                "Your account has not yet been approved. Check back soon!"}
          </Text>
          <Text variant="body" mb={3}>
            Questions? Contact support@hearme.app
          </Text>
          <Button label="Log out" onClick={() => logout()} />
        </Flex>
      );
    }
  }

  return (
    <AuthContext.Provider
      value={{
        data,
        googleLogin,
        emailLogin,
        sendVerificationCode,
        phoneLogin,
        logout,
      }}
      {...props}
    />
  );

  async function logout() {
    await firebase.auth().signOut();
    await apolloClient.clearStore();
    await refetch();
  }

  async function googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
    await refetch();
  }

  async function emailLogin(email: string, password: string) {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    await refetch();
  }

  async function sendVerificationCode(phone: string) {
    return firebase.auth().signInWithPhoneNumber(phone, verifier);
  }

  async function phoneLogin(
    confirmResult: firebase.auth.ConfirmationResult,
    code: string
  ) {
    await confirmResult.confirm(code);
    await refetch();
  }
}

const useAuth = () => React.useContext(AuthContext) as ContextProps;

export { AuthProvider, useAuth };
