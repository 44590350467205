import React, { useState } from "react";
import AuthCard from "components/AuthCard";
import Input from "components/Input";
import Link from "components/Link";

interface Props {
  onSubmit: (verificationCode: string) => Promise<void>;
  onBack: () => void;
  phoneNumber: string;
}

export default function PhoneVerification(props: Props) {
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <AuthCard
      as="form"
      onSubmit={onSubmit}
      mb={5}
      header="Verify"
      subheader={`Code sent to ${props.phoneNumber}`}
      input={
        <Input
          required
          id="verification-code"
          label="Verification code"
          value={verificationCode}
          onChange={(e: any) => {
            setVerificationCode(e.currentTarget.value);
          }}
          error={error}
        />
      }
      helperLabel="Didn't receive the code?"
      helper={
        <Link
          to=""
          onClick={(e) => {
            e.preventDefault();
            props.onBack();
          }}
        >
          Go back
        </Link>
      }
      mainButton="Login"
      disabled={loading}
    />
  );

  async function onSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();
    setLoading(true);
    try {
      await props.onSubmit(verificationCode);
      setLoading(false);
    } catch (e) {
      setError("Invalid code");
      setLoading(false);
    }
  }
}
