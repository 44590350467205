import React from "react";
import { User } from "graphql/__generated__/types";
import { useAuth } from "./auth";

// @ts-ignore
export const UserContext = React.createContext<User>();

function UserProvider(props: any) {
  const { data } = useAuth();
  const xmppToken =
    data?.chatToken.__typename === "Token" ? data.chatToken.xmppToken : null;
  const user =
    data!.me.__typename === "User"
      ? {
          ...data!.me,
          xmppToken,
        }
      : null;
  return <UserContext.Provider value={user} {...props} />;
}

const useUser = (): User & { chatToken: string; xmppToken: string } =>
  // @ts-ignore
  React.useContext(UserContext);

export { UserProvider, useUser };
