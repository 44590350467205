import { useMemo, useEffect } from "react";
import throttle from "lodash.throttle";
import {
  sendMessageAction,
  getPreviousMessagesAction,
  sendTypingAction,
  useDispatch,
  useSelector,
  sendReadIndicatorAction,
  setMessageRemovedAction
} from "../types/actions";
import {
  OneToOneConversation,
  FullActiveConversation,
  FullDedicatedConversation,
} from "../types/entities";
import { getUnreadMessagesCount } from "./helpers";

type FullConversation = FullActiveConversation | FullDedicatedConversation;

interface IUseChat {
  sendMessage: (body: string) => void;
  conversation?: FullConversation;
  getPreviousMessages: () => void;
  sendTypingIndicator: () => void;
  sendReadIndicator: () => void;
  removeMessage: (id: string) => void;
}

export default function useChat(conversationId: string): IUseChat {
  const dispatch = useDispatch();

  const sendReadIndicator = () =>
    dispatch(sendReadIndicatorAction(conversationId));

  const conversation = useSelector(
    (s) => s.conversations[conversationId] as OneToOneConversation | undefined
  );

  useEffect(() => {
    if (conversation) {
      sendReadIndicator();
    }
  }, [conversation?.messages[conversation?.messages.length - 1]?.id]);

  const allParticipants = useSelector((s) => s.participants);

  const fullConversation: FullConversation | undefined = useMemo(() => {
    if (conversation) {
      const member = allParticipants[conversation.memberId];
      const listener = allParticipants[conversation.listenerId];
      const unreadCount = getUnreadMessagesCount(conversation);
      return { ...conversation, member, listener, unreadCount };
    }
    return undefined;
  }, [allParticipants, conversation]);

  const getPreviousMessages = () => {
    if (!conversation?.fullyLoaded) {
      dispatch(getPreviousMessagesAction(conversationId));
    }
  };

  const sendMessage = (body: string) =>
    dispatch(sendMessageAction(body, conversationId));

  const removeMessage = (id: string) => dispatch(setMessageRemovedAction(id, conversationId))

  const sendTypingIndicator = throttle(
    () => dispatch(sendTypingAction(conversationId)),
    1000
  );

  return {
    sendMessage,
    getPreviousMessages,
    conversation: fullConversation,
    sendTypingIndicator,
    sendReadIndicator,
    removeMessage
  };
}
