import { from, of } from "rxjs";
import {
  filter,
  map,
  concatMap,
  take,
  catchError,
  delay,
} from "rxjs/operators";
import {
  sendMessageAction,
  addOptimisticMessageAction,
  setMessageErrorAction,
} from "../types/actions";
import { v4 as uuid } from "uuid";
import { sendMessageRequest } from "../service";
import { IEpic } from "./";
import { filterNulls, retryIfOffline, xmppSend } from "./helpers";
import { combineEpics } from "redux-observable";

const addOptimisticMessageEpic: IEpic = (action$, _, { service }) =>
  action$.pipe(
    filter(sendMessageAction.match),
    map((action) =>
      addOptimisticMessageAction(
        uuid(),
        action.payload.body,
        new Date().toString(),
        action.payload.conversationId
      )
    )
  );

const markMessageErroredEpic: IEpic = (action$, state$) =>
  action$.pipe(
    filter(addOptimisticMessageAction.match),
    delay(10000),
    filter((action) => {
      const msgs =
        state$.value.conversations[action.payload.conversationId].messages;
      const msg = msgs.find((m) => m.id === action.payload.id);
      return msg ? msg.status !== "sent" : false;
    }),
    map((action) =>
      setMessageErrorAction(action.payload.id, action.payload.conversationId)
    )
  );

const sendMessageEpic: IEpic = (action$, state$, { service }) =>
  action$.pipe(
    filter(addOptimisticMessageAction.match),
    concatMap((action) => {
      return of(action).pipe(
        retryIfOffline(
          service,
          (a) =>
            state$.value.participants[
              state$.value.conversations[a.payload.conversationId].myId
            ].isOnline,
          "sendMessageEpic"
        ),
        concatMap((a) =>
          from(
            xmppSend(
              service.xmpp,
              sendMessageRequest(
                a.payload.id,
                a.payload.conversationId,
                a.payload.body
              )
            )
          )
        ),
        catchError((err) =>
          of(
            setMessageErrorAction(
              action.payload.id,
              action.payload.conversationId
            )
          )
        ),
        take(1)
      );
    }),
    filterNulls(),
    filter(setMessageErrorAction.match)
  );

export default combineEpics(
  addOptimisticMessageEpic,
  markMessageErroredEpic,
  sendMessageEpic
);
