import { combineReducers } from "@reduxjs/toolkit";
import conversations from "./conversations";
import participants from "./participants";
import inAppNotification from "./inAppNotification";
import connectionStatus from "./connectionStatus";

const rootReducer = combineReducers({
  conversations,
  participants,
  inAppNotification,
  connectionStatus,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
