import { createReducer } from "@reduxjs/toolkit";
import { InAppNotification } from "../types/entities";
import {
  addInAppNotificationAction,
  removeInAppNotificationAction,
} from "../types/actions";

const inAppNotificationReducer = createReducer<InAppNotification | null>(
  null,
  builder =>
    builder
      .addCase(addInAppNotificationAction, (state, action) => {
        return action.payload.notification;
      })
      .addCase(removeInAppNotificationAction, (state, action) => {
        return null;
      }),
);

export default inAppNotificationReducer;
