import React from "react";
import { Flex } from "rebass/styled-components";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Phone from "./Phone";
import EmailLogin from "./EmailLogin";

export default function Authentication() {
  const { url } = useRouteMatch();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg="background"
      height="95vh"
    >
      <Switch>
        <Route path={`${url}/email`}>
          <EmailLogin />
        </Route>
        <Route path={`${url}`}>
          <Phone />
        </Route>
      </Switch>
    </Flex>
  );
}
