import React from "react";
import useChat from "store/hooks/useChat";
import MessagesContainer, {
  RenderableMessage,
} from "components/MessagesContainer";

interface Props {
  id: string;
}

export default function DedicatedChatContainer(props: Props) {
  const {
    conversation,
    getPreviousMessages,
    sendMessage,
    sendTypingIndicator,
  } = useChat(props.id);

  if (conversation === undefined) return null;

  const isListener = conversation.listenerId === conversation.myId;
  const [me, partner] = isListener
    ? [conversation.listener, conversation.member]
    : [conversation.member, conversation.listener];
  const usersTyping = conversation.typingIds.includes(partner.id)
    ? [partner.name]
    : undefined;
  const messages: RenderableMessage[] = conversation.messages.map((m) => {
    const author = m.authorId === me.id ? me : partner;
    return { ...m, author };
  });

  return (
    <MessagesContainer
      messages={messages}
      usersTyping={usersTyping}
      conversationId={conversation.id}
      isEnded={conversation.isEnded}
      getOlderMessages={getPreviousMessages}
      myId={conversation.myId}
      sendMessage={sendMessage}
      sendTypingIndicator={sendTypingIndicator}
      gettingOlderMessages={conversation.loading}
      fullyLoaded={conversation.fullyLoaded}
    />
  );
}
