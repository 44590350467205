import { useCallback } from 'react';
import {
  getConversationAction,
  getGroupConversationAction,
  endConversationAction,
  removeConversationAction,
  AddConversationPayload,
  AddGroupConversationPayload,
  addParticipantsAction,
  useDispatch,
  getActiveConversationAction,
} from "../types/actions";
import { Participant } from "store/types/entities";

export type BaseParticipant = Pick<Participant, "id" | "name" | "avatarUrl">;
type ConversationParticipant = Participant & {
  conversationId: string;
};

interface IUseChats {
  getConversation: (args: AddConversationPayload) => void;
  getActiveConversation: (id: string, myUserId: string) => void;
  getGroupConversation: (args: AddGroupConversationPayload) => void;
  endConversation: (id: string) => void;
  removeConversation: (id: string) => void;
  addParticipants: (ps: ConversationParticipant[]) => void;
}

export default function useChats(): IUseChats {
  const dispatch = useDispatch();

  const getConversation = useCallback((args: AddConversationPayload) => {
    dispatch(getConversationAction(args));
  }, [])

  const getActiveConversation = useCallback((id: string, myUserId: string) => {
    dispatch(getActiveConversationAction(id, myUserId));
  }, []);

  const getGroupConversation = useCallback((args: AddGroupConversationPayload) => {
    dispatch(getGroupConversationAction(args));
  }, []);

  const endConversation = useCallback((id: string) => {
    dispatch(endConversationAction(id));
  }, []);

  const removeConversation = useCallback((id: string) => {
    dispatch(removeConversationAction(id));
  }, []);

  const addParticipants = useCallback((ps: ConversationParticipant[]) => {
    dispatch(addParticipantsAction(ps));
  }, []);

  return {
    getConversation,
    getActiveConversation,
    endConversation,
    removeConversation,
    getGroupConversation,
    addParticipants,
  };
}
