import gql from "graphql-tag";

export const CHAT_REQUEST_STATE_UPDATED = gql`
  subscription chatRequestStateUpdated {
    chatRequestStateUpdated {
      id
      state
      recommendedForYou
      languagePreference {
        id
        name
        label
      }
      member {
        id
        alias
      }
      selectedTopic {
        id
        name
      }
      createdAt
    }
  }
`;
