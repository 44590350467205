import { jid } from "@xmpp/client";
import {
  EventPresence,
  EventMessage,
  EventMessageDisplayed,
  XMPPEvent,
} from "../types/events";
import {
  addMessageAction,
  setOnlineStatusAction,
  setTypingAction,
  setDisplayedIndicatorAction,
} from "../types/actions";

const getJidLocal = (fullJid: string): string => jid(fullJid).getLocal();
const getJidResource = (fullJid: string): string => jid(fullJid).getResource();
const getDelay = (delay: any) =>
  new Date(delay ? delay.attrs.stamp : new Date()).toString();

function getPresenceStanza(stanza: any): EventPresence | null {
  const valid =
    stanza.attrs.type === undefined || stanza.attrs.type === "unavailable";
  if (!valid) return null;
  const isOnline = stanza.attrs.type !== "unavailable";
  const userId = getJidResource(stanza.attrs.from);
  if (userId === "") return null;
  return {
    userId,
    isOnline,
    type: "presence",
  };
}

function getMessageDisplayed(stanza: any): EventMessageDisplayed {
  const displayed = stanza.getChild("displayed");
  const stanzaId = displayed.attrs.id;
  const conversationId = getJidLocal(stanza.attrs.from);
  let by = getJidResource(stanza.attrs.from);
  return {
    messageStanzaId: Number(stanzaId),
    conversationId,
    by,
    type: "message-displayed",
  };
}

function getMessageStanza(stanza: any): EventMessage | null {
  const body = stanza.getChild("body");
  if (!body) return null;
  const subject = stanza.getChild("subject");
  const subjectText = subject ? subject.text() : null;
  const bodyText = body.text();
  const delay = stanza.getChild("delay");
  const stanzaId = stanza.getChild("stanza-id").attrs.id;
  const createdAt = getDelay(delay);
  const authorId = getJidResource(stanza.attrs.from) || "system";
  return {
    authorId,
    type: "message",
    body: bodyText,
    createdAt,
    id: stanza.attrs.id,
    conversationId: getJidLocal(stanza.attrs.from),
    status: "sent",
    stanzaId: Number(stanzaId),
    subject: subjectText,
  };
}

export function getEventFromStanza(stanza: any): XMPPEvent | null {
  if (stanza.is("presence")) {
    return getPresenceStanza(stanza);
  }
  if (stanza.is("message") && stanza.attrs.type === "groupchat") {
    if (stanza.getChild("composing")) {
      return {
        type: "typing",
        userId: getJidResource(stanza.attrs.from),
        isTyping: true,
        conversationId: getJidLocal(stanza.attrs.from),
      };
    }

    if (stanza.getChild("paused")) {
      return {
        type: "typing",
        userId: getJidResource(stanza.attrs.from),
        isTyping: false,
        conversationId: getJidLocal(stanza.attrs.from),
      };
    }

    if (stanza.getChild("body")) {
      return getMessageStanza(stanza);
    }
    if (stanza.getChild("displayed")) {
      return getMessageDisplayed(stanza);
    }
  }
  return null;
}

export function getActionFromEvent(event: XMPPEvent) {
  switch (event.type) {
    case "message":
      return addMessageAction(event);
    case "presence":
      return setOnlineStatusAction(event);
    case "typing":
      return setTypingAction(event);
    case "message-displayed":
      return setDisplayedIndicatorAction(event);
  }
}

export function getMessageFromArchive(
  stanza: any,
): EventMessage | EventMessageDisplayed | null {
  const forwarded = stanza
    .getChild("result")
    .getChild("forwarded")
    .clone();
  const message = forwarded
    .getChild("message")
    .append(forwarded.getChild("delay"));
  if (message.getChild("displayed")) {
    return getMessageDisplayed(message);
  }
  return getMessageStanza(message);
}
