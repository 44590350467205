const sharedColors = {
  primary: "#F38181",
  primaryLight: "#F4A188",
  secondary: "#8BC5A2",
  white: "#FFFFFF",
  error: "#9F2919",
};

const colorsLight = {
  ...sharedColors,
  selection: "#FBECE6",
  background: "#F4EEE6",
  panel: "#F8F4ED",
  divider: "#EFE6D9",
  overlay: "#FCF8F1",
  error: "#9F2919",
  disabled: "#DFD9D7",
  modalOverlay: "#403D44",
  text: {
    primary: "#403D44",
    secondary: "#706776",
    tertiary: "#939295",
    input: "#403D44",
  },
} as const;

const colorsDark = {
  ...sharedColors,
  selection: "#392D2D",
  secondary: "#5F816C",
  primaryLight: "#F38181",
  background: "#1E1E1E",
  panel: "#202020",
  divider: "#383838",
  overlay: "#202020",
  error: "#9F2919",
  disabled: "#706776",
  modalOverlay: "#403D44",
  text: {
    primary: "#FFFFFF",
    secondary: "#D5D5D5",
    tertiary: "#939295",
    input: "#FFFFFF",
  },
} as const;

export default {
  light: colorsLight,
  dark: colorsDark,
};
