import React, { useState, useRef, FormEvent, KeyboardEvent } from "react";
import { Flex, FlexProps } from "rebass/styled-components";
import { Textarea } from "@rebass/forms/styled-components";
import Icon from "components/Icon";

interface Props extends FlexProps {
  disabled?: boolean;
  conversationId: string;
  sendMessage: (body: string) => void;
  sendTypingIndicator: () => void;
}

export default function MessageComposer(props: Props) {
  const { disabled, ...rest } = props;
  const [message, setMessage] = useState<string>("");
  const textRef = useRef<any>();

  const onSubmit = (e?: FormEvent<HTMLDivElement>) => {
    e && e.preventDefault();

    if (message.length > 0) {
      props.sendMessage(message);
      setMessage("");
      textRef.current.style.height = "35px";
    }
  };

  const onEnterPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (message.length > 0) {
        onSubmit();
      }
    }
  };

  return (
    <Flex
      bg="overlay"
      alignItems="center"
      as="form"
      onSubmit={onSubmit}
      {...rest}
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 10,
        paddingX: "15px",
        paddingY: "10px",
        ...rest.sx,
      }}
    >
      <Textarea
        id="message"
        name="message"
        aria-label="Message composer"
        placeholder={disabled ? "Chat ended" : "Write a message"}
        onKeyDown={onEnterPress}
        value={message}
        disabled={disabled}
        color="text.input"
        autoComplete="off"
        sx={{
          fontFamily: "canada-type-gibson, sans-serif",
          fontWeight: 300,
          fontStyle: "normal",
          resize: "none",
          overflow: "hidden",
          ":focus": {
            outlineWidth: 0,
          },
          "::-webkit-input-placeholder": {
            color: disabled ? "text.tertiary" : "text.secondary",
          },
          border: "none",
        }}
        rows={1}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setMessage(e.target.value);
          const target = e.target as HTMLTextAreaElement;
          textRef.current.style.height = `${target.scrollHeight}px`;
          props.sendTypingIndicator();
        }}
        ref={textRef}
      />
      <Flex
        as="button"
        sx={{
          flex: 1,
          width: 30,
          height: 30,
          borderRadius: 9999,
          marginBottom: "1px",
          border: "none",
          outline: "none",
          minWidth: "auto",
          ":focus": {
            outline: "none",
          },
          bg: disabled || message.length === 0 ? "disabled" : "secondary",
          ":hover": {
            cursor: disabled || message.length === 0 ? "default" : "pointer",
          },
        }}
        alignItems="center"
        alignSelf="flex-end"
        justifyContent="center"
        aria-label="Send message"
        disabled={disabled || message.length === 0}
        type="submit"
      >
        <Icon name="arrow-forward-outline" size="medium" />
      </Flex>
    </Flex>
  );
}
