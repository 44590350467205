import { useMemo } from "react";
import { useSelector } from "../types/actions";
import {
  DedicatedConversation,
  FullDedicatedConversation,
} from "../types/entities";
import sortBy from "lodash.sortby";
import { getUnreadMessagesCount, areArraysEqual } from "./helpers";

interface IUseDedicatedChat {
  dedicatedConvos: FullDedicatedConversation[];
  unreadConvosCount: number /** How many {convos} are unread. Counts 1 for each unread convo */;
  doesChatExist: (id: string) => boolean;
}

export default function useDedicatedChats(): IUseDedicatedChat {
  const convos = useSelector(
    s =>
      Object.values(s.conversations).filter(
        (c): c is DedicatedConversation => c.type === "dedicated",
      ),
    areArraysEqual,
  );

  const doesChatExist = (id: string) => !!convos.find(c => c.id === id);

  const participants = useSelector(
    s =>
      convos.map(c => ({
        member: s.participants[c.memberId],
        listener: s.participants[c.listenerId],
      })),
    (as, bs) =>
      areArraysEqual(
        as,
        bs,
        (a, b) => a.member === b.member && a.listener === b.listener,
      ),
  );

  const dedicatedConvos = useMemo(() => {
    const fullConvos = convos.map((c, idx) => {
      const unreadCount = getUnreadMessagesCount(c);
      return { ...c, ...participants[idx], unreadCount };
    });
    return sortBy(fullConvos, c => {
      const lastMessage = c.messages[c.messages.length - 1];
      if (lastMessage) return -Date.parse(lastMessage.createdAt);
      else return 0;
    });
  }, [convos, participants]);

  const unreadConvosCount = useMemo(
    () => dedicatedConvos.filter(dc => dc.unreadCount > 0).length,
    [dedicatedConvos],
  );

  return {
    dedicatedConvos,
    unreadConvosCount,
    doesChatExist,
  };
}
