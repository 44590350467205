import React from "react";

import ChatCard from "components/ChatCard";

import useChats from "store/hooks/useChats";
import { FullActiveConversation } from "store/types/entities";

interface Props {
  id?: string;
  openChat: (id: string) => void;
  activeConversation: FullActiveConversation;
}

const ActiveChat = ({ activeConversation, openChat, id }: Props) => { 
  const { removeConversation } = useChats();

  const isListener = activeConversation.listenerId === activeConversation.myId;

  const [, partner] = isListener
    ? [activeConversation.listener, activeConversation.member]
    : [activeConversation.member, activeConversation.listener];

  const filtered = activeConversation.messages.filter(
    (m) => m.subject !== "preSessionData"
  );

  const lastMessage = filtered[filtered.length - 1];

  const isTyping = activeConversation.typingIds.includes(partner.id);

  return (
    <ChatCard
      onClick={() => openChat(activeConversation.id)}
      key={activeConversation.id}
      name={partner.name}
      avatarUrl={partner.avatarUrl}
      isOnline={partner.isOnline}
      isTyping={isTyping}
      lastMessage={lastMessage && lastMessage.body}
      lastMessageSentAt={
        lastMessage && new Date(lastMessage.createdAt)
      }
      unreadCount={activeConversation.unreadCount}
      imListener={isListener}
      selected={activeConversation.id === id}
      onRemoveClick={() => removeConversation(activeConversation.id)}
      isEnded={activeConversation.isEnded}
    />
  );
};

export default ActiveChat;

