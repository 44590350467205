import React, { useContext, useState, FormEvent } from "react";
import Modal from "components/Modal";
import { Box, Flex, Text } from "rebass/styled-components";
import { ThemeContext } from "styled-components/macro";
import Button from "components/Button";
import ToggleButton from "components/ToggleButton";
import { List } from "immutable";
import { ReportCategory } from "graphql/__generated__/types";
import IconButton from "components/IconButton";
import TextArea from "components/TextArea";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reason: string, categories: ReportCategory[]) => Promise<void>;
  name: string;
}

const reportTypes: ReportCategory[] = [
  ReportCategory.Crisis,
  ReportCategory.ContactInfo,
  ReportCategory.Inappropriate,
  ReportCategory.Underage,
];

export default function ReportModal({
  isOpen,
  onClose,
  name,
  onSubmit,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedTypes, setSelectedTypes] = useState<List<ReportCategory>>(
    List()
  );
  const [error, setError] = useState("");
  const theme = useContext(ThemeContext);

  const toggleRType = (rType: ReportCategory) => {
    selectedTypes.contains(rType)
      ? setSelectedTypes(selectedTypes.filter((t) => t !== rType))
      : setSelectedTypes(selectedTypes.push(rType));
  };

  const onSubmitClick = async (e?: FormEvent<HTMLDivElement>) => {
    e && e.preventDefault();
    setError("");
    setIsSubmitting(true);
    if (!reason && selectedTypes.size === 0) {
      setError("Please select a category or enter a reason");
      setIsSubmitting(false);
      return;
    }
    try {
      await onSubmit(reason, selectedTypes.toArray());
      onClose();
      setReason("");
      setSelectedTypes(List());
    } catch (e) {
      setError(
        "An error occurred. If this keeps happening, contact support@hearme.app"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} label={`Report ${name}`}>
      <Box p={4} bg="panel" maxWidth={710} as="form">
        <Flex justifyContent="space-between">
          <Text variant="header">{`Report ${name}`}</Text>
          <IconButton
            name="close-outline"
            fill={theme.colors.text.primary}
            onClick={onClose}
          />
        </Flex>
        <Flex marginTop={2} marginBottom={4}>
          <Text variant="main" maxWidth="90%">
            Something wrong? Let us know. We take these reports very seriously
            and act on them as quickly as possible.
          </Text>
        </Flex>
        <Flex>
          <TextArea
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setReason(e.target.value);
            }}
            placeholder="Tell us what happened (Optional)"
          />
        </Flex>
        <Flex flexWrap="wrap" marginTop={2}>
          {reportTypes.map((rType) => (
            <ToggleButton
              key={rType}
              isSelected={selectedTypes.contains(rType)}
              onToggle={() => toggleRType(rType)}
              label={getLabelOfRType(rType)}
            />
          ))}
        </Flex>
        {error && (
          <Text
            variant="body"
            color="error"
            marginTop={2}
            marginBottom={0}
            ml={1}
          >
            {error}
          </Text>
        )}
        <Flex justifyContent="space-between" marginTop={4}>
          <Text
            variant="body"
            maxWidth="90%"
            color="text.secondary"
            alignSelf="center"
          >
            We’ll notify you via email once we’ve acted on your report. Thanks
            for keeping HearMe safe!
          </Text>
          <Button
            label={"SUBMIT REPORT"}
            disabled={isSubmitting}
            onClick={onSubmitClick}
            bg="error"
            type="submit"
          />
        </Flex>
      </Box>
    </Modal>
  );
}

function getLabelOfRType(rType: ReportCategory) {
  switch (rType) {
    case "CONTACT_INFO":
      return "Sharing/requesting contact info";
    case "CRISIS":
      return "Crisis";
    case "INAPPROPRIATE":
      return "Inappropriate behaviour/topic";
    case "UNDERAGE":
      return "Under 18";
    default:
      return "UNKNOWN";
  }
}
