import { ConversationsState } from "./conversations";
import { addPreviousMessagesAction } from "../types/actions";
import { EventMessage, EventMessageDisplayed } from "store/types/events";
import uniqBy from "lodash.uniqby";
import maxBy from "lodash.maxby";

type AddPrev = ReturnType<typeof addPreviousMessagesAction>['payload']

export const addPreviousMessages = (state: ConversationsState, payload: AddPrev ) => {
   
 const convo = state[payload.conversationId];
      const messages = payload.messages.filter(
        (m): m is EventMessage => m.type === "message",
      );
      convo.messages.unshift(...messages);
      convo.messages = uniqBy(convo.messages, m => m.id);
      convo.messages.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      convo.loading = false;

      if (convo.type === "active" || convo.type === "dedicated") {
        const displays = payload.messages.filter(
          (m): m is EventMessageDisplayed => m.type === "message-displayed",
        );
        const myBatchLastRead = maxBy(
          displays.filter(d => d.by === convo.myId),
          m => m.messageStanzaId,
        );
        const partnerBatchLastRead = maxBy(
          displays.filter(d => d.by !== convo.myId),
          m => m.messageStanzaId,
        );
        const myChatLastRead = maxBy(
          convo.messages.filter(m => m.displayed && m.authorId !== convo.myId),
          m => m.stanzaId
        )
        const partnerChatLastRead = maxBy(
          convo.messages.filter(m => m.displayed && m.authorId === convo.myId),
          m => m.stanzaId
        )
        const myLastRead = Math.max(myBatchLastRead?.messageStanzaId || 0, myChatLastRead?.stanzaId || 0);
        const partnerLastRead = Math.max(partnerBatchLastRead?.messageStanzaId || 0, partnerChatLastRead?.stanzaId || 0);

        if (myLastRead) {
          // I sent a read indicator, mark partners messages read
          convo.messages = convo.messages.map(m =>
            m.authorId !== convo.myId && m.status === "sent" &&
            myLastRead >= m.stanzaId
              ? { ...m, displayed: true }
              : m,
          );
        }
        if (partnerLastRead) {
          // Partner sent a read indicator, mark my messages read
          convo.messages = convo.messages.map(m =>
            m.authorId === convo.myId && m.status === "sent" &&
            partnerLastRead >= m.stanzaId
              ? { ...m, displayed: true }
              : m,
          );
        }
      }
 }