import { useMemo } from "react";
import { shallowEqual } from "react-redux";

import { useSelector } from "../types/actions";
import { FullActiveConversation, ActiveConversation, Participant } from "../types/entities";
import { getUnreadMessagesCount } from "./helpers";

const useActiveChats = (): FullActiveConversation[] => {
  const activeConversations = useSelector<ActiveConversation[]>((s) =>
    Object.values(s.conversations).filter(
      (c): c is ActiveConversation => c.type === "active"
    )
  );

  const allParticipants = useSelector((s) => 
    activeConversations.filter(ac => ac).map((convo) => ( 
      {
        convoId: convo.id,
        member: s.participants[convo.memberId],
        listener: s.participants[convo.listenerId],
      }
    )),
    shallowEqual
  );

  const conversations = useMemo(() => activeConversations.map((convo) => {
    const participants = allParticipants.find(p => p.convoId === convo.id); 

    let activeConvo = null;

    if (convo && participants) {
      const unreadCount = getUnreadMessagesCount(convo);

      activeConvo = { ...convo, ...participants, unreadCount };
    }

    return activeConvo as FullActiveConversation;
  }), [activeConversations]);

  return conversations;
}

export default useActiveChats;

