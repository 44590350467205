import React, { useEffect } from "react";
import { useUser } from "context/user";
import HeaderBar from "components/HeaderBar";
import { Flex } from "rebass/styled-components";
import {
  Switch,
  useHistory,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import Authentication from "routes/Authentication";
import Chats from "routes/Chats";
// import { ChatsProvider } from "context/chat";
import { PendingChatRequestsProvider } from "context/pendingRequests";
import { DedicatedChatsProvider } from "context/dedicatedChats";
import ChatHeader from "components/ChatHeader";
import Modal from "react-modal";
import ReduxProvider from "./ReduxProvider";

// Needed for accessibility of modals
Modal.setAppElement("#root");

interface Props {}

export default function App(props: Props) {
  const user = useUser();
  const history = useHistory();
  const url = useLocation();

  useEffect(() => {
    if (user === null) {
      history.push("/auth");
    } else if (url.pathname.includes("/auth")) {
      history.push("/chats");
    }
  }, [user]);

  return (
    <Switch>
      <Route path="/auth">
        <UnauthenticatedApp />
      </Route>
      <Route path="*">
        <AuthenticatedApp />
      </Route>
    </Switch>
  );
}

function AuthenticatedApp() {
  const user = useUser();
  if (user === null) return null; // We need this so that the app doesn't render before the useEffect routes them to /auth
  return (
    <ReduxProvider>
      {/* <ChatsProvider> */}
      <DedicatedChatsProvider>
        <PendingChatRequestsProvider>
          <Flex flexDirection="column" sx={{ display: "flex", height: "100%" }}>
            <ChatHeader />
            <Switch>
              <Route path="/chats">
                <Chats />
              </Route>
              <Route path="/">
                <Redirect to="/chats" />
              </Route>
            </Switch>
          </Flex>
        </PendingChatRequestsProvider>
      </DedicatedChatsProvider>
      {/* </ChatsProvider> */}
    </ReduxProvider>
  );
}

function UnauthenticatedApp() {
  return (
    <>
      <HeaderBar />
      <Switch>
        <Route path="/auth">
          <Authentication />
        </Route>
      </Switch>
    </>
  );
}
