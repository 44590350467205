import React from "react";
import Icon, { IconProps } from "components/Icon";

interface Props extends IconProps {
  onClick: () => void;
}

export default function IconButton(props: Props) {
  return (
    <Icon
      {...props}
      containerProps={{
        as: "button",
        type: "button",
        onClick: props.onClick,
        sx: {
          border: "none",
          outline: "none",
          bg: "transparent",
          ":hover": {
            cursor: "pointer",
          },
        },
        ...props.containerProps,
      }}
    />
  );
}
