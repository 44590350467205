import React, { useRef, useState } from "react";
import { Provider } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { client } from "@xmpp/client";
import { createService, IService } from "store/service/xmppService";
import { useFullscreenLoader } from "../../context/fullscreenLoader";
import createStore from "store";
import { useUser } from "context/user";

const DOMAIN =
  process.env.REACT_APP_STAGE !== "PRODUCTION"
    ? "chat.staging.hearme.app"
    : "chat.hearme.app"

const SERVICE = `wss://${DOMAIN}:5223/ws`;

const ReduxProvider = (props: any) => {
  const { setIsActive } = useFullscreenLoader();
  const apolloClient = useApolloClient();
  const user = useUser();
  const [ready, setReady] = useState(false);
  const serviceRef = useRef<IService | null>();

  /** This is used for performance gain not to create the store on every render
   * https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  const storeRef = useRef<ReturnType<typeof createStore> | null>(null);
  if (storeRef.current === null && user.xmppToken) {
    const xmpp = client({
      service: SERVICE,
      domain: DOMAIN,
      username: user.id,
      password: user.xmppToken,
    });
    xmpp.setMaxListeners(1000);
    xmpp
      .start()
      .then(() => {
        setIsActive(false);
        setReady(true);
      })
      .catch((e) => {
        xmpp.stop();
        console.debug(e);
      });
    // xmpp.on("stanza", console.log);
    const service = createService({ xmpp, apolloClient });
    serviceRef.current = service;
    storeRef.current = createStore({ service });
  }

  if (storeRef.current !== null && user == null) {
    if (serviceRef.current) {
      serviceRef.current.xmpp.stop().catch((e) => {
        console.warn("Failed to stop XMPP service");
        // Add the breadcrumb, but don't throw an exception.
        // Sentry.addBreadcrumb({
        //   message: "Failed to stop XMPP client",
        //   data: e,
        // });
      });
    }
    storeRef.current = null;
    setReady(false);
  }

  const storeLoading = !ready || storeRef.current === null;
  if (user && storeLoading) return <></>;
  if (!user && storeLoading) return <React.Fragment {...props} />;

  return <Provider {...props} store={storeRef.current} />;
};

export default ReduxProvider;
