import React from "react";
import { Box, BoxProps, Text } from "rebass/styled-components";

interface Props extends BoxProps {
  count: number;
}

export default function UnreadBadge(props: Props) {
  return (
    <Box
      display="inline-block"
      height={20}
      minWidth={20}
      px={"6px"}
      bg="primary"
      sx={{
        borderRadius: 9999,
        flexShrink: 0,
        flexGrow: 0,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      {...props}
    >
      <Text
        aria-label="Unread count"
        variant="medium"
        sx={{
          fontSize: 14,
          textAlign: "center",
          lineHeight: "20px",
          verticalAlign: "middle",
          color: "white",
        }}
      >
        {props.count}
      </Text>
    </Box>
  );
}
