import React, { useContext, useState } from "react";
import { Box, Flex, Text, FlexProps } from "rebass/styled-components";
import Avatar from "components/Avatar";
import Button from "components/Button";
import { ThemeContext } from "styled-components/macro";
import getTextFromMood from "helpers/getTextFromMood";
import ReportModal from "components/ReportModal";
import {
  useLeaveReportMutation,
  ReportCategory,
} from "graphql/__generated__/types";
import TextButton from "components/TextButton";

interface Props extends FlexProps {
  avatarUrl?: string;
  name: string;
  isOnline?: boolean;
  joinedOn: Date;
  topic?: string;
  mood: number;
  reason?: string;
  conversationId: string;
  isEnded: boolean;
  isDedicated?: boolean;
  onEndClick: () => Promise<void>;
  onCreateDedicatedChatClick: () => Promise<void>;
}

export default function RequestDetails(props: Props) {
  const {
    avatarUrl,
    name,
    isOnline,
    joinedOn,
    topic,
    conversationId,
    mood,
    reason,
    onEndClick,
    isDedicated,
    isEnded,
    onCreateDedicatedChatClick,
    sx,
    ...rest
  } = props;
  const theme = useContext(ThemeContext);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [createReport] = useLeaveReportMutation();
  const [isDedicatedChatCreated, setIsDedicatedChatCreated] = useState(false);
  const closeReportModal = () => setIsReportModalOpen(false);
  const openReportModal = () => setIsReportModalOpen(true);

  const onReport = async (reason: string, categories: ReportCategory[]) => {
    await createReport({
      variables: {
        id: conversationId,
        reason,
        categories,
      },
    });
  };

  const onCreateDedicated = async () => {
    if (isDedicatedChatCreated) return;
    try {
      await onCreateDedicatedChatClick();
      setIsDedicatedChatCreated(true);
    } catch (e) {}
  };

  return (
    <>
      <ReportModal
        isOpen={isReportModalOpen}
        name={name}
        onClose={closeReportModal}
        onSubmit={onReport}
      />
      <Flex
        py={5}
        bg="overlay"
        flexDirection="column"
        justifyContent="space-between"
        width={310}
        minWidth={310}
        maxWidth={310}
        {...rest}
        sx={{
          borderLeft: "1px solid",
          borderColor: "divider",
          ...sx,
        }}
      >
        <Flex flexDirection="column">
          <Avatar
            size={100}
            m={1}
            url={avatarUrl}
            name={name}
            isOnline={isOnline}
            alignSelf="center"
          />
          <Text aria-label="username" variant="header" m={2} alignSelf="center">
            {name}
          </Text>
          <Text aria-label="date" variant="body" mb={3} alignSelf="center">
            Joined on {joinedOn.toLocaleDateString()}
          </Text>
          {topic && (
            <Box alignSelf="start" mx={5} my={2}>
              <Text mb={1} variant="medium">
                Topic
              </Text>
              <Text variant="body" aria-label="topic">
                {topic}
              </Text>
            </Box>
          )}
          {mood && (
            <Box alignSelf="start" mx={5} marginTop={3} marginBottom={2}>
              <Text mb={1} variant="medium">
                I'm feeling
              </Text>
              <Text variant="large" aria-label="mood">
                {getTextFromMood(mood)}
              </Text>
            </Box>
          )}
          {reason && (
            <Box alignSelf="start" mx={5} my={2}>
              <Text mb={1} variant="medium">
                Because...
              </Text>
              <Text variant="body" aria-label="reason">
                {reason}
              </Text>
            </Box>
          )}
        </Flex>
        <Flex alignSelf="center" alignItems="center" flexDirection="column">
          {isEnded && (isDedicatedChatCreated || isDedicated) ? null : (
            <Button
              label={isEnded ? "CREATE DEDICATED CHAT" : "END CHAT"}
              bg={isEnded ? theme.colors.secondary : theme.colors.error}
              onClick={isEnded ? onCreateDedicated : onEndClick}
              mb={4}
            />
          )}
          <TextButton
            label="REPORT"
            color={theme.colors.error}
            onClick={openReportModal}
          />
        </Flex>
      </Flex>
    </>
  );
}
