import React from "react";
import { useRouteMatch, Route, Switch, useHistory } from "react-router-dom";
import { Flex } from "rebass/styled-components";
import ChatsSidebar from "components/ChatsSidebar";
import GroupChatContainer from "components/GroupChatContainer";
import ChatContainer from "components/ChatContainer";
import DedicatedChatContainer from "components/DedicatedChatContainer";
import EmptyChatContainer from "components/EmptyChatContainer";

export default function Chats() {
  const { url } = useRouteMatch();
  const history = useHistory();
  return (
    <Flex flexDirection="row" bg="background" sx={{ flex: 1, minHeight: 0 }}>
      <Switch>
        <Route exact path={url}>
          <ChatsSidebar
            openDedicatedChat={openDedicatedChat}
            openGroupChat={openGroupChat}
            openChat={openChat}
          />
          <EmptyChatContainer />
        </Route>
        <Route
          exact
          path={`${url}/active/:id`}
          render={({ match }) => (
            <>
              <ChatsSidebar
                openDedicatedChat={openDedicatedChat}
                openGroupChat={openGroupChat}
                openChat={openChat}
                id={match.params.id}
              />
              <ChatContainer id={match.params.id} />
            </>
          )}
        />
        <Route
          path={`${url}/dedicated/:id`}
          render={({ match }) => (
            <>
              <ChatsSidebar
                openDedicatedChat={openDedicatedChat}
                openGroupChat={openGroupChat}
                openChat={openChat}
                id={match.params.id}
              />
              <DedicatedChatContainer id={match.params.id} />
            </>
          )}
        />
        <Route
          path={`${url}/group/:id`}
          render={({ match }) => (
            <>
              <ChatsSidebar
                openDedicatedChat={openDedicatedChat}
                openGroupChat={openGroupChat}
                openChat={openChat}
                id={match.params.id}
              />
              <GroupChatContainer id={match.params.id} />
            </>
          )}
        />
      </Switch>
    </Flex>
  );

  function openChat(id: string): void {
    history.push(`${url}/active/${id}`);
  }

  function openGroupChat(id: string): void {
    history.push(`${url}/group/${id}`);
  }

  function openDedicatedChat(id: string): void {
    history.push(`${url}/dedicated/${id}`);
  }
}
