import { createReducer } from "@reduxjs/toolkit";
import { Participant } from "../types/entities";
import {
  addConversationAction,
  addGroupConversationAction,
  setOnlineStatusAction,
  addParticipantAction,
  sendPresenceIndicatorAction,
  addParticipantsAction,
  setOnlineStatusesAction,
} from "../types/actions";

const participantsReducers = createReducer<
  Record<Participant["id"], Participant>
>({}, builder =>
  builder
    .addCase(addConversationAction, (state, action) => {
      if (!state[action.payload.member.id]) {
        state[action.payload.member.id] = {
          ...action.payload.member,
          isListener: false,
          isOnline: false,
        };
      }
      if (!state[action.payload.listener.id]) {
        state[action.payload.listener.id] = {
          ...action.payload.listener,
          isListener: true,
          isOnline: false,
        };
      }
    })
    .addCase(addGroupConversationAction, (state, action) => {
      action.payload.participants.forEach(p => {
        if (!state[p.id]) {
          state[p.id] = {
            ...p,
            isOnline: false,
          };
        }
      });
    })
    .addCase(setOnlineStatusAction, (state, action) => {
      if (state[action.payload.userId]) {
        state[action.payload.userId].isOnline = action.payload.isOnline;
      }
    })
    .addCase(setOnlineStatusesAction, (state, action) => {
      action.payload.forEach(u => {
        if (state[u.userId]) {
          state[u.userId].isOnline = u.isOnline;
        }
      });
    })
    .addCase(addParticipantAction, (state, action) => {
      const { conversationId, ...participant } = action.payload;
      state[participant.id] = participant;
    })
    .addCase(addParticipantsAction, (state, action) => {
      action.payload.participants.forEach(p => {
        if (!state[p.id]) {
          state[p.id] = p;
        }
      });
    })
    .addCase(sendPresenceIndicatorAction, (state, action) => {
      if (!action.payload.online) {
        /** Set all participants to offline when closing the app, so we fetch their presences anew when coming back */
        Object.values(state).forEach(p => {
          p.isOnline = false;
        });
      }
    }),
);

export default participantsReducers;
