import React, { useContext } from "react";
import { Box, Text, BoxProps } from "rebass/styled-components";
import Avatar from "components/Avatar";
import { formatDistanceToNow } from "date-fns";
import UnreadBadge from "components/UnreadBadge";
import { ThemeContext } from "styled-components";
import { adjustColorBrightness } from "helpers/adjustColorBrightness";
import ContentLoader from "react-content-loader";
import IconButton from "components/IconButton";

interface Props extends BoxProps {
  onClick?: () => void;
  avatarUrl?: string;
  name: string;
  isOnline?: boolean;
  isTyping?: boolean;
  lastMessage?: string;
  lastMessageSentAt?: Date;
  unreadCount?: number;
  imListener?: boolean;
  selected?: boolean;
  timerStart?: Date;
  isGroupChat?: boolean;
  isEnded?: boolean;
  onRemoveClick?: () => void;
}

export default function ChatCard({
  unreadCount = 0,
  onRemoveClick,
  ...props
}: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Box
      px={20}
      py={15}
      onClick={props.onClick}
      bg={props.selected ? "selection" : "overlay"}
      height={85}
      display="inline-flex"
      {...props}
      sx={{
        position: "relative",
        justifyContent: "space-between",
        ":hover": {
          backgroundColor: props.selected
            ? "selection"
            : adjustColorBrightness(theme.colors.overlay, -3),
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
        }}
      >
        <Avatar
          url={props.avatarUrl}
          name={props.name}
          isOnline={props.isOnline}
          mr={3}
          alignSelf="center"
        />
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <Text
            variant="main"
            mb={"3px"}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 200,
            }}
          >
            {props.name}
          </Text>
          {props.isTyping ? (
            <Text
              variant="body"
              color="text.secondary"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 200,
                fontFamily: "canada-type-gibson, sans-serif",
                fontWeight: unreadCount > 0 ? 500 : 300,
                fontStyle: "italic",
              }}
              mb={"3px"}
            >
              Typing...
            </Text>
          ) : (
            <Text
              variant="body"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 200,
                fontFamily: "canada-type-gibson, sans-serif",
                fontWeight: unreadCount > 0 ? 500 : 300,
                fontStyle: "normal",
              }}
              mb={"3px"}
            >
              {props.isEnded
                ? "Chat ended"
                : props.lastMessage || "No messages sent"}
            </Text>
          )}
          {props.lastMessageSentAt && (
            <Text variant="label">
              {`${formatDistanceToNow(props.lastMessageSentAt)} ago`}
            </Text>
          )}
        </Box>
      </Box>
      {unreadCount > 0 && (
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <UnreadBadge count={unreadCount} mr={2} />
        </Box>
      )}
      {props.isEnded && (
        <IconButton
          name="close-outline"
          onClick={() => onRemoveClick && onRemoveClick()}
          fill={theme.colors.text.primary}
        />
      )}
      {props.selected && (
        <Box
          width={4}
          height={"100%"}
          bg={"primary"}
          sx={{
            position: "absolute",
            right: -0.5, // Cover the border of the chat sidebar
            top: 0,
            bottom: 0,
          }}
        />
      )}
    </Box>
  );
}

export const ChatCardLoader = () => (
  <Box px={20} py={15} height={95} display="inline-flex">
    <ContentLoader
      speed={2}
      width={350}
      height={95}
      viewBox="0 0 350 95"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="73" rx="1" ry="1" width="120" height="10" />
      <rect x="73" y="25" rx="1" ry="1" width="180" height="8" />
      <rect x="73" y="50" rx="1" ry="1" width="80" height="6" />
      <circle cx="28" cy="28" r="28" />
    </ContentLoader>
  </Box>
);
