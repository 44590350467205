import React from "react";
import { Flex, Text } from "rebass/styled-components";

interface Props {}

export default function EmptyChatContainer() {
  return (
    <Flex
      flex={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Text mb={3} variant="header">
        No chat selected
      </Text>
      <Text variant="body">Select a chat from the sidebar to get started</Text>
    </Flex>
  );
}
