import React, { useContext } from "react";
import { Text } from "rebass/styled-components";
import { ThemeContext } from "styled-components";
import { adjustColorBrightness } from "helpers/adjustColorBrightness";

interface Props {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
}

export default function TextButton(props: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Text
      onClick={!props.disabled ? props.onClick : () => {}}
      disabled={props.disabled}
      sx={{
        letterSpacing: 1.5,
        fontSize: 14,
        fontFamily: "canada-type-gibson, sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        color: props.disabled
          ? theme.colors.disabled
          : props.color || theme.colors.text.primary,
        borderRadius: 15,
        ":hover": {
          color: props.disabled
            ? theme.colors.disabled
            : adjustColorBrightness(
                props.color || theme.colors.text.primary,
                10
              ),
          cursor: props.disabled ? "default" : "pointer",
        },
      }}
      {...props}
    >
      {props.label.toUpperCase()}
    </Text>
  );
}
