import React, { useContext } from "react";
import { Text, BoxProps, Flex } from "rebass/styled-components";
import Avatar from "components/Avatar";
import { ThemeContext } from "styled-components";
import { adjustColorBrightness } from "helpers/adjustColorBrightness";
import Icon from "components/Icon";

interface Props {
  avatarUrl?: string;
  name: string;
  onSettingsClick: () => void;
  onProfileClick: () => void;
  containerProps?: BoxProps;
}

export default function ProfileBar(props: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Flex
      px={20}
      py={15}
      onClick={props.onProfileClick}
      bg={"panel"}
      height={95}
      sx={{
        position: "relative",
        justifyContent: "space-between",
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderColor: "divider",
        ":hover": {
          backgroundColor: adjustColorBrightness(theme.colors.panel, -3),
          cursor: "pointer",
        },
      }}
      {...props.containerProps}
    >
      <Flex>
        <Avatar
          url={props.avatarUrl}
          name={props.name}
          mr={3}
          alignSelf="center"
        />
        <Flex
          sx={{
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            variant="main"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 200,
            }}
          >
            {props.name}
          </Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        <Icon
          name={"settings-outline"}
          fill={theme.colors.text.secondary}
          containerProps={{
            "aria-label": "settings",
          }}
        />
      </Flex>
    </Flex>
  );
}
