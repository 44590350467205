import React, { useContext, FormEvent } from "react";
import Modal from "components/Modal";
import { Box, Flex, Text } from "rebass/styled-components";
import { ThemeContext } from "styled-components/macro";
import Button from "components/Button";
import IconButton from "components/IconButton";
import { useTheme } from "context/theme";
import { useAuth } from "context/auth";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function SettingsModal({ isOpen, onClose }: Props) {
  const theme = useContext(ThemeContext);
  const { logout } = useAuth();
  const { setIsDarkMode, isDark } = useTheme();

  const onDoneClick = async (e?: FormEvent<HTMLDivElement>) => {
    e && e.preventDefault();
    onClose();
  };

  const toggleDarkMode = () => {
    if (isDark) setIsDarkMode(false);
    else setIsDarkMode(true);
  };

  const onLogoutClick = () => logout();

  return (
    <Modal isOpen={isOpen} onClose={onClose} label="Settings">
      <Box p={4} bg="panel" maxWidth={710} as="form">
        <Flex justifyContent="space-between">
          <Text variant="header">Settings</Text>
          <IconButton
            name="close-outline"
            fill={theme.colors.text.primary}
            onClick={onClose}
          />
        </Flex>
        <Flex marginTop={2} marginBottom={4}>
          <Text variant="main" maxWidth="90%">
            We’ll be adding more here soon. For now, go to the mobile app to
            configure your profile and other options.
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="flex-start">
          <Text variant="smallSectionHeader" mb={2}>
            GENERAL
          </Text>
          <Button
            label={"TOGGLE DARK MODE"}
            onClick={toggleDarkMode}
            type="submit"
          />
          <Text variant="smallSectionHeader" my={2} mt={3}>
            ACCOUNT
          </Text>
          <Button
            label={"LOG OUT"}
            onClick={onLogoutClick}
            type="submit"
            bg="error"
          />
        </Flex>
        <Flex justifyContent="flex-end" marginTop={4}>
          <Button label={"DONE"} onClick={onDoneClick} type="submit" />
        </Flex>
      </Box>
    </Modal>
  );
}
