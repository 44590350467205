import React from "react";
import { Image, Box, Text, BoxProps } from "rebass/styled-components";
import ContentLoader from "react-content-loader";

interface Props extends BoxProps {
  url?: string;
  name: string;
  isOnline?: boolean;
  size?: number;
}

export default function Avatar(props: Props) {
  const size = props.size || 48;
  return (
    <Box
      display="inline-block"
      aria-label={props.name}
      width={size}
      minWidth={size}
      height={size}
      minHeight={size}
      bg={props.url ? undefined : "primary"}
      sx={{
        borderRadius: 9999,
        color: "white",
        verticalAlign: "top",
        position: "relative",
      }}
      {...props}
    >
      {props.url ? (
        <>
          <Placeholder size={size} />
          <Image
            aria-hidden
            src={props.url}
            sx={{
              width: size,
              height: size,
              borderRadius: 9999,
              position: "absolute",
            }}
            alt={props.name}
          />
        </>
      ) : (
        <Text
          aria-hidden
          variant="medium"
          style={{
            fontSize: size / 2,
            textAlign: "center",
            lineHeight: `${size}px`,
            color: "white",
            display: "border-box",
          }}
        >
          {props.name.length > 0 && props.name[0].toUpperCase()}
        </Text>
      )}
      {props.isOnline && (
        <Box
          aria-label="online"
          sx={{
            position: "absolute",
            height: Math.min(size / 3, 25),
            width: Math.min(size / 3, 25),
            borderRadius: 999,
            border: `2px solid`,
            borderColor: "overlay",
            backgroundColor: "secondary",
            right: "-0.5px",
            bottom: 0,
          }}
        />
      )}
    </Box>
  );
}

const Placeholder = ({ size = 48 }: { size?: number }) => (
  <Box sx={{ position: "absolute" }}>
    <ContentLoader
      speed={2}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx={size / 2} cy={size / 2} r={size / 2} />
    </ContentLoader>
  </Box>
);
