import { xml } from "@xmpp/client";
import { v4 as uuid } from "uuid";

const CHAT_HOST = process.env.REACT_APP_STAGE !== "PRODUCTION" ?
  "conference.chat.staging.hearme.app" :
  "conference.chat.hearme.app";

const getJid = (chatJid: string, userId?: string) => {
  let jid = `${chatJid}@${CHAT_HOST}`;
  if (userId) jid += `/${userId}`;
  return jid;
};

export function sendComposingRequest(chatJid: string) {
  return xml(
    "message",
    { to: getJid(chatJid), type: "groupchat" },
    xml("composing", { xmlns: "http://jabber.org/protocol/chatstates" })
  );
}

export function sendPausedRequest(chatJid: string) {
  return xml(
    "message",
    { to: getJid(chatJid), type: "groupchat" },
    xml("paused", { xmlns: "http://jabber.org/protocol/chatstates" })
  );
}

export function joinChatRequest(chatJid: string, userId: string) {
  return xml(
    "presence",
    {
      to: getJid(chatJid, userId),
    },
    xml(
      "x",
      { xmlns: "http://jabber.org/protocol/muc" },
      xml("history", { maxstanzas: 0 })
    )
  );
}

export function sendDisplayedMarker(chatJid: string, stanzaId: number) {
  return xml(
    "message",
    { to: getJid(chatJid), type: "groupchat", id: uuid() },
    xml("store", { xmlns: "urn:xmpp:hints" }),
    xml("displayed", { xmlns: "urn:xmpp:chat-markers:0", id: stanzaId })
  );
}

export function sendOfflineIndicator(chatJid: string, userId: string) {
  return xml("presence", { to: getJid(chatJid, userId), type: "unavailable" });
}

export function getArchivedMessagesRequest(
  chatJid: string,
  queryId: string,
  { before, after }: { before?: Date; after?: Date }
) {
  return xml(
    "iq",
    { type: "set", to: getJid(chatJid) },
    xml(
      "query",
      { xmlns: "urn:xmpp:mam:2", queryid: queryId },
      xml(
        "x",
        { xmlns: "jabber:x:data", type: "submit" },
        xml(
          "field",
          { var: "FORM_TYPE", type: "hidden" },
          xml("value", {}, ["urn:xmpp:mam:2"])
        ),
        before
          ? xml(
            "field",
            { var: "end" },
            xml("value", {}, [before.toISOString()])
          )
          : undefined,
        after
          ? xml(
            "field",
            { var: "start" },
            xml("value", {}, [after.toISOString()])
          )
          : undefined
      ),
      !after &&
      xml(
        "set",
        { xmlns: "http://jabber.org/protocol/rsm" },
        xml("max", {}, [20]),
        xml("before")
      )
    )
  );
}

export function sendMessageRequest(id: string, chatJid: string, body: string) {
  return xml(
    "message",
    { to: getJid(chatJid), type: "groupchat", id },
    xml("body", {}, [body]),
    xml("markable", { xmlns: "urn:xmpp:chat-markers:0" })
  );
}
