import React, { useContext, ReactNode } from "react";
import RModal from "react-modal";
import styled, { ThemeContext } from "styled-components/macro";
import { convertHexToRGBA } from "helpers/hexOpacity";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  label?: string;
}

export const StyledModal = styled(RModal)`
  position: relative;
  overflow: auto;
  outline: none;
  margin: auto;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 8px;
`;

export default function Modal({ isOpen, onClose, label, children }: Props) {
  const theme = useContext(ThemeContext);
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={label}
      closeTimeoutMS={200}
      style={{
        content: {
          backgroundColor: theme.colors.panel,
          zIndex: 999,
        },
        overlay: {
          backgroundColor: convertHexToRGBA(theme.colors.modalOverlay, 85),
          display: "flex",
          alignItems: "center",
          zIndex: 998,
        },
      }}
    >
      {children}
    </StyledModal>
  );
}
