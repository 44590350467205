import React, { useState } from "react";
import { Flex, Text } from "rebass/styled-components";

import ChatCard, { ChatCardLoader } from "components/ChatCard";
import PendingRequest from "components/PendingRequest";
import ProfileBar from "components/ProfileBar";
import ScrollBar from "components/ScrollBar";
import SettingsModal from "components/SettingsModal";
import ActiveChat from "components/ActiveChat";

import { usePendingChatRequests } from "context/pendingRequests";
import { useUser } from "context/user";

import useGroupChat from "store/hooks/useGroupChat";
import useDedicatedChatsSelector from "store/hooks/useDedicatedChatsSelector";
import useActiveChats from "store/hooks/useActiveChatsSelector";
import useChats from "store/hooks/useChats";

import useSession from "hooks/useSesssion";

interface Props {
  id?: string;
  openChat: (id: string) => void;
  openGroupChat: (id: string) => void;
  openDedicatedChat: (id: string) => void;
}

export default function ChatsSidebar(props: Props) {
  const { data: pendingRequests } = usePendingChatRequests();

  const user = useUser();

  const { getActiveConversation } = useChats();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const { conversation: gc } = useGroupChat("listeners-group-chat");

  const { dedicatedConvos } = useDedicatedChatsSelector();

  const activeChats = useActiveChats();

  const { acceptRequest } = useSession();

  return (
    <>
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
      />
      <Flex
        flexDirection="column"
        alignItems="stretch"
        bg="overlay"
        width={310}
        minWidth={310}
        sx={{
          borderRight: "1px solid",
          borderColor: "divider",
        }}
      >
        <ScrollBar noScrollX>
          <Flex flexDirection="column" alignItems="stretch">
            <ListHeader>Active Chats</ListHeader>
            {
              activeChats.map((ac) => <ActiveChat key={`active-chat-${ac.id}`} activeConversation={ac} {...props} />)
            }
            {pendingRequests && pendingRequests.length > 0 && (
              <>
                {pendingRequests.map((pr) => (
                  <PendingRequest
                    onAccept={async () => {
                      await acceptRequest(pr.id);
                      getActiveConversation(pr.id, user.listener!.id);
                      props.openChat(pr.id);
                    }}
                    avatarUrl=""
                    name={pr.member.alias || "Anonymous"}
                    sentAt={pr.createdAt}
                    topic={pr.selectedTopic?.name}
                    key={pr.id}
                    isRecommended={pr.recommendedForYou}
                  />
                ))}
              </>
            )}
            {dedicatedConvos.length > 0 && (
              <ListHeader>Dedicated Chats</ListHeader>
            )}
            {dedicatedConvos.map((c) => {
              const isListener = c.listenerId === c.myId;
              const [, partner] = isListener
                ? [c.listener, c.member]
                : [c.member, c.listener];
              const lastMessage = c.messages[c.messages.length - 1];
              const isTyping = c.typingIds.includes(partner.id);
              if (!lastMessage) return <ChatCardLoader key={c.id} />;
              return (
                <ChatCard
                  onClick={() => props.openDedicatedChat(c.id)}
                  key={c.id}
                  imListener={isListener}
                  name={partner.name}
                  avatarUrl={partner.avatarUrl}
                  isOnline={partner.isOnline}
                  unreadCount={c.unreadCount}
                  isTyping={isTyping}
                  lastMessage={lastMessage.body}
                  lastMessageSentAt={new Date(lastMessage.createdAt)}
                  selected={c.id === props.id}
                />
              );
            })}
            {gc ? <ListHeader>Group Chats</ListHeader> : null}
            {gc
              ? (() => {
                  const lastMessage = gc.messages[gc.messages.length - 1];
                  if (!lastMessage) return <ChatCardLoader key={gc.id} />;
                  return (
                    <ChatCard
                      onClick={() => props.openGroupChat(gc.id)}
                      key={gc.id}
                      name={gc.name}
                      lastMessage={lastMessage.body}
                      lastMessageSentAt={new Date(lastMessage.createdAt)}
                      selected={gc.id === props.id}
                    />
                  );
                })()
              : null}
          </Flex>
        </ScrollBar>
        <ProfileBar
          name={user.listener?.firstName || user.alias!}
          onProfileClick={() => setIsSettingsOpen(true)}
          onSettingsClick={() => setIsSettingsOpen(true)}
          avatarUrl={user.listener?.avatarUrl || undefined}
        />
      </Flex>
    </>
  );
}

const ListHeader: React.FC = (props) => (
  <Text
    variant="sectionHeader"
    paddingTop={30}
    paddingBottom={15}
    paddingLeft={20}
  >
    {props.children}
  </Text>
);
