import {
  combineEpics,
  Epic,
  ActionsObservable,
  StateObservable,
} from "redux-observable";
import getConversationEpic from "./getConversationEpic";
import getGroupConversationEpic from "./getGroupConversationEpic";
import sendMessageEpic from "./sendMessageEpic";
import sendTypingIndicatorEpic from "./sendTypingIndicatorEpic";
import sendPresencesEpic from "./sendPresencesEpic";
import inAppNotificationEpic from "./inAppNotificationEpic";
import sendReadIndicatorEpic from "./sendReadIndicatorEpic";
import getParticipantEpic from "./getParticipantEpic";
import { RootState } from "../reducers";
import { Action } from "../types/actions";
import { IService } from "../service/xmppService";
import getActiveConversationEpic from "./getActiveConversationEpic";
import getConnectionStateEpic from "./getConnectionStateEpic";

export default combineEpics(
  getConnectionStateEpic,
  sendPresencesEpic,
  getActiveConversationEpic,
  getConversationEpic,
  getGroupConversationEpic,
  getParticipantEpic,
  sendMessageEpic,
  sendTypingIndicatorEpic,
  inAppNotificationEpic,
  sendReadIndicatorEpic,
);

export interface Dependencies {
  service: IService;
}

export type IEpic = Epic<Action, Action, RootState, Dependencies>;
export type Action$ = ActionsObservable<Action>;
export type State$ = StateObservable<RootState>;
