import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
   __typename?: 'Query';
  /** @deprecated doesn't do anything */
  _empty?: Maybe<Scalars['String']>;
  me: MeResult;
  isPhoneNumberInUse: Scalars['Boolean'];
  activeUsers: Array<User>;
  bannedUsers: Array<User>;
  getUser?: Maybe<User>;
  getUsersByAlias: Array<User>;
  getUsersByEmail: Array<User>;
  getUsersByPhone: Array<User>;
  getUsersByListenerEmail: Array<User>;
  getUserByListenerId?: Maybe<User>;
  getUsersByListenerName: Array<User>;
  listener?: Maybe<Listener>;
  listeners: Array<Listener>;
  getListenerByUserId?: Maybe<Listener>;
  getCurrentGroupChatTopic: GroupChatTopic;
  getUnapprovedListeners: ListenersResult;
  getListenersData: Array<Listener>;
  userAnnouncements: Array<Maybe<Announcement>>;
  getReflections: MaybeReflections;
  topics: Array<Topic>;
  languages: Array<Language>;
  listenerPreferences: Array<ListenerPreference>;
  room?: Maybe<Room>;
  activeRooms: MaybeRooms;
  scheduledRooms: MaybeRooms;
  pastSessions: PastSessionsResult;
  getPastSessionsByUserId: PastSessionsResult;
  currentRequest?: Maybe<ChatRequestResult>;
  currentRequests?: Maybe<Array<Maybe<ChatRequestResult>>>;
  getPendingChatRequests?: Maybe<PendingChatsResult>;
  getChatRequest?: Maybe<ChatRequest>;
  getDedicatedChat: DedicatedChat;
  getMyDedicatedChats: Array<DedicatedChat>;
  chat: ChatResult;
  chatToken: TokenResult;
  getReferralCodeByValue?: Maybe<ReferralCode>;
};


export type QueryIsPhoneNumberInUseArgs = {
  phone: Scalars['String'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetUsersByAliasArgs = {
  alias: Scalars['String'];
};


export type QueryGetUsersByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUsersByPhoneArgs = {
  phone: Scalars['String'];
};


export type QueryGetUsersByListenerEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserByListenerIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetUsersByListenerNameArgs = {
  name: Scalars['String'];
};


export type QueryListenerArgs = {
  id: Scalars['ID'];
};


export type QueryListenersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetListenerByUserIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetReflectionsArgs = {
  after?: Maybe<Scalars['Date']>;
  before?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRoomArgs = {
  id: Scalars['ID'];
};


export type QueryPastSessionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetPastSessionsByUserIdArgs = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetChatRequestArgs = {
  id: Scalars['ID'];
};


export type QueryGetDedicatedChatArgs = {
  id: Scalars['ID'];
};


export type QueryChatArgs = {
  id: Scalars['ID'];
};


export type QueryChatTokenArgs = {
  platform: Platform;
};


export type QueryGetReferralCodeByValueArgs = {
  value: Scalars['String'];
};

export type Mutation = {
   __typename?: 'Mutation';
  /** @deprecated doesn't do anything */
  _empty?: Maybe<Scalars['String']>;
  registerWithPhone: RegisterResult;
  register: RegisterResult;
  addDeviceToken: UpdateUserResult;
  removeDeviceToken: UpdateUserResult;
  configureNotificationOptions: UpdateUserResult;
  rateAndroid: UpdateUserResult;
  setUsageReasons: UpdateUserResult;
  giveFeedback: Scalars['Boolean'];
  setAlias: UpdateUserResult;
  setReferralCode: UpdateUserResult;
  setLanguages: User;
  addLanguage: User;
  toggleUserBan: UpdateUserResult;
  setListenerPreferences: UpdateUserResult;
  deleteMyAccount: Scalars['Boolean'];
  blockListener: BlockListenerResult;
  unblockListener: BlockListenerResult;
  createListener: UpsertListenersResult;
  addListenerPhoto: UpsertListenersResult;
  joinChat: ChatRequestResult;
  setListenerNotificationsEnabled: UpsertListenersResult;
  setListenerTopics: UpsertListenersResult;
  setBirthYear: UpsertListenersResult;
  editListenerInfo: UpsertListenersResult;
  setCurrentGroupChatTopic?: Maybe<GroupChatTopic>;
  approveListener?: Maybe<User>;
  removeMessageFromListenersGroupChat: Scalars['Boolean'];
  changeListenerName: UpsertListenersResult;
  updateListenerInfo: UpsertListenersResult;
  setListenerIsBanned: Listener;
  setListenerIsModerator: Listener;
  setListenerIsFacilitator: Listener;
  setListenerIsMultiChatQualified: Listener;
  createReflection: MaybeReflection;
  editReflection: MaybeReflection;
  createTopic: CreateTopicResult;
  joinRoom?: Maybe<Room>;
  setPostRoomMood?: Maybe<Scalars['Boolean']>;
  kickUserFromRoom?: Maybe<Scalars['Boolean']>;
  removeMessageFromRoom?: Maybe<Scalars['Boolean']>;
  createRoom?: Maybe<Room>;
  scheduleRoom?: Maybe<Room>;
  startScheduledRoom?: Maybe<Room>;
  registerForRoom?: Maybe<Scalars['Boolean']>;
  endRoom?: Maybe<Room>;
  addAdminNote: Scalars['Boolean'];
  createRequest: ChatRequestResult;
  addPreSessionMood: ChatRequestResult;
  cancelMyRequest: ChatRequestResult;
  addCancellationReasons: ChatRequestResult;
  joinChatAsMember: ChatRequestResult;
  leaveReport: ChatResult;
  setEndChatEarlyReasons: ChatResult;
  endMyChat: ChatResult;
  addPostSessionMood: ChatRequestResult;
  leaveReview: ChatResult;
  createDedicatedChat: DedicatedChatResult;
  endDedicatedChat: DedicatedChatResult;
  reportDedicatedChat: DedicatedChatResult;
  repairDedicatedChat: DedicatedChatResult;
};


export type MutationRegisterWithPhoneArgs = {
  input: PhoneRegisterInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationAddDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationRemoveDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationConfigureNotificationOptionsArgs = {
  isChatAcceptanceNotificationOn?: Maybe<Scalars['Boolean']>;
  isChatMessageNotificationOn?: Maybe<Scalars['Boolean']>;
};


export type MutationSetUsageReasonsArgs = {
  reasonIds: Array<Scalars['Int']>;
};


export type MutationGiveFeedbackArgs = {
  feedback: Scalars['String'];
  deviceInformation?: Maybe<Scalars['JSON']>;
};


export type MutationSetAliasArgs = {
  alias: Scalars['String'];
};


export type MutationSetReferralCodeArgs = {
  code: Scalars['String'];
};


export type MutationSetLanguagesArgs = {
  languageIds: Array<Scalars['Int']>;
};


export type MutationAddLanguageArgs = {
  languageId: Scalars['Int'];
};


export type MutationToggleUserBanArgs = {
  id: Scalars['ID'];
};


export type MutationSetListenerPreferencesArgs = {
  ageRangeIds: Array<Scalars['Int']>;
};


export type MutationBlockListenerArgs = {
  listenerId: Scalars['ID'];
};


export type MutationUnblockListenerArgs = {
  listenerId: Scalars['ID'];
};


export type MutationCreateListenerArgs = {
  input: CreateListenerInput;
};


export type MutationAddListenerPhotoArgs = {
  file: Scalars['Upload'];
};


export type MutationJoinChatArgs = {
  id: Scalars['ID'];
};


export type MutationSetListenerNotificationsEnabledArgs = {
  value: Scalars['Boolean'];
};


export type MutationSetListenerTopicsArgs = {
  topicIds: Array<Scalars['Int']>;
};


export type MutationSetBirthYearArgs = {
  birthYear: Scalars['Int'];
};


export type MutationEditListenerInfoArgs = {
  input: EditListenerInput;
};


export type MutationSetCurrentGroupChatTopicArgs = {
  topic: Scalars['String'];
  description: Scalars['String'];
};


export type MutationApproveListenerArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMessageFromListenersGroupChatArgs = {
  id: Scalars['ID'];
};


export type MutationChangeListenerNameArgs = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationUpdateListenerInfoArgs = {
  id: Scalars['ID'];
  input: UpdateListenerInfoInput;
};


export type MutationSetListenerIsBannedArgs = {
  id: Scalars['ID'];
  banned: Scalars['Boolean'];
};


export type MutationSetListenerIsModeratorArgs = {
  id: Scalars['ID'];
  moderator: Scalars['Boolean'];
};


export type MutationSetListenerIsFacilitatorArgs = {
  id: Scalars['ID'];
  isFacilitator: Scalars['Boolean'];
};


export type MutationSetListenerIsMultiChatQualifiedArgs = {
  id: Scalars['ID'];
  isQualified: Scalars['Boolean'];
};


export type MutationCreateReflectionArgs = {
  text: Scalars['String'];
  mood: Scalars['Int'];
  chatId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationEditReflectionArgs = {
  reflection: ReflectionInput;
};


export type MutationCreateTopicArgs = {
  input: CreateTopicInput;
};


export type MutationJoinRoomArgs = {
  id: Scalars['ID'];
  preSessionMood: Scalars['Int'];
};


export type MutationSetPostRoomMoodArgs = {
  id: Scalars['ID'];
  postRoomMood: Scalars['Int'];
};


export type MutationKickUserFromRoomArgs = {
  roomId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveMessageFromRoomArgs = {
  roomId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationCreateRoomArgs = {
  name: Scalars['String'];
  topicId: Scalars['Int'];
  description: Scalars['String'];
};


export type MutationScheduleRoomArgs = {
  name: Scalars['String'];
  topicId: Scalars['Int'];
  startAt: Scalars['Date'];
};


export type MutationStartScheduledRoomArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterForRoomArgs = {
  roomId: Scalars['ID'];
};


export type MutationEndRoomArgs = {
  id: Scalars['ID'];
};


export type MutationAddAdminNoteArgs = {
  note: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};


export type MutationAddPreSessionMoodArgs = {
  chatReqId: Scalars['ID'];
  value: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};


export type MutationAddCancellationReasonsArgs = {
  chatReqId: Scalars['ID'];
  reasons: Array<CancellationReason>;
};


export type MutationJoinChatAsMemberArgs = {
  id: Scalars['ID'];
};


export type MutationLeaveReportArgs = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<ReportCategory>>;
};


export type MutationSetEndChatEarlyReasonsArgs = {
  id: Scalars['ID'];
  reasons: Array<EndEarlyReason>;
};


export type MutationEndMyChatArgs = {
  id: Scalars['ID'];
};


export type MutationAddPostSessionMoodArgs = {
  chatReqId: Scalars['ID'];
  value: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};


export type MutationLeaveReviewArgs = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
};


export type MutationCreateDedicatedChatArgs = {
  memberId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationEndDedicatedChatArgs = {
  chatId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};


export type MutationReportDedicatedChatArgs = {
  chatId: Scalars['ID'];
  reason: Scalars['String'];
  category?: Maybe<ReportCategory>;
};


export type MutationRepairDedicatedChatArgs = {
  chatId: Scalars['ID'];
};

export type Subscription = {
   __typename?: 'Subscription';
  /** @deprecated doesn't do anything */
  _empty?: Maybe<Scalars['String']>;
  onlineStatusChanged: OnlineIndicator;
  topicWaitingSessionsChanged: Topic;
  myChatRequestAccepted: MyChatRequestAccepted;
  myChatEnded: MyChatEnded;
  chatRequestStateUpdated: ChatRequestStateUpdated;
  addedToDedicatedChat: AddedToDedicatedChat;
  removedFromDedicatedChat: RemovedFromDedicatedChat;
};

export type User = {
   __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  isAdmin: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  isChatAcceptanceNotificationOn: Scalars['Boolean'];
  isChatMessageNotificationOn: Scalars['Boolean'];
  hasRatedAndroid: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  isBetaTester?: Maybe<Scalars['Boolean']>;
  lastSeenAt?: Maybe<Scalars['Date']>;
  iaphubId?: Maybe<Scalars['String']>;
  isPremium: Scalars['Boolean'];
  isNewMember: Scalars['Boolean'];
  languages?: Maybe<Array<Language>>;
  listenerPreferences: Array<ListenerPreference>;
  hasReasons: Scalars['Boolean'];
  online?: Maybe<Scalars['Boolean']>;
  blockedListeners: Array<Listener>;
  premiumMembershipInfo?: Maybe<PremiumMembership>;
  rooms?: Maybe<Array<Room>>;
  reportedIn: Array<ChatRequestReport>;
  reporterIn: Array<ChatRequestReport>;
  adminNotes: Array<AdminNote>;
  referralCode?: Maybe<Scalars['String']>;
  listener?: Maybe<Listener>;
  reflectionCount: Scalars['Int'];
  chatRequests: Array<ChatRequest>;
  chatCount: Scalars['Int'];
  currentStreak: Scalars['Int'];
  dedicatedChats?: Maybe<Array<DedicatedChat>>;
};

export type RegisterInput = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type PhoneRegisterInput = {
  phone: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
};

export type DeviceInformation = {
  OS?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
};

export type RegisterResult = User | NotLoggedIn | AlreadyExists | DoesNotExist | InvalidRequestState | DoNotHavePermission;

export type MeResult = User | NotLoggedIn | DoesNotExist;

export type UpdateUserResult = User | NotLoggedIn | DoesNotExist;

export enum AgeRange {
  A18_24 = 'a18_24',
  A25_34 = 'a25_34',
  A35 = 'a35_'
}

export enum FeedbackCategory {
  Bug = 'BUG',
  Suggestion = 'SUGGESTION',
  Feedback = 'FEEDBACK'
}

export type OnlineIndicator = {
   __typename?: 'OnlineIndicator';
  userId: Scalars['ID'];
  online: Scalars['Boolean'];
};

export type Listener = {
   __typename?: 'Listener';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  isEnabled: Scalars['Boolean'];
  isBlocked: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullName: Scalars['String'];
  bio: Scalars['String'];
  gender?: Maybe<Gender>;
  location?: Maybe<OutputLocation>;
  birthYear?: Maybe<Scalars['Int']>;
  age?: Maybe<Scalars['Int']>;
  avatarUrl?: Maybe<Scalars['String']>;
  isModerator?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  topics: Array<Topic>;
  notificationsDisabled?: Maybe<Scalars['Boolean']>;
  sessionCount: Scalars['Int'];
  user: User;
  email?: Maybe<Scalars['String']>;
  avatarReported?: Maybe<Scalars['Boolean']>;
  isFacilitator?: Maybe<Scalars['Boolean']>;
  lastNotificationSent?: Maybe<Scalars['Date']>;
  isMultiChatQualified?: Maybe<Scalars['Boolean']>;
  dedicatedChats?: Maybe<Array<DedicatedChat>>;
};

export type Listeners = {
   __typename?: 'Listeners';
  listeners: Array<Listener>;
};

export type ListenersResult = Listeners | NotLoggedIn | DoNotHavePermission;

export type BlockListenerResult = User | NotLoggedIn;

export type UpsertListenersResult = Listener | NotLoggedIn | DoesNotExist | InvalidRequestState | DoNotHavePermission;

export type GroupChatTopic = {
   __typename?: 'GroupChatTopic';
  topic: Scalars['String'];
  description: Scalars['String'];
};

export type OutputLocation = {
   __typename?: 'OutputLocation';
  city: Scalars['String'];
  countryCode: Scalars['String'];
};

export enum Gender {
  Male = 'male',
  Female = 'female',
  Na = 'NA',
  Other = 'other'
}

export type Location = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
};

export type CreateListenerInput = {
  listenerDetails: ListenerDetailsInput;
};

export type ListenerDetailsInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  bio: Scalars['String'];
  gender: Gender;
  location: Location;
  email: Scalars['String'];
  birthYear: Scalars['Int'];
  topicIds: Array<Scalars['Int']>;
};

export type EditListenerInput = {
  listenerDetails: EditListenerDetailsInput;
};

export type EditListenerDetailsInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  birthYear?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
};

export type UpdateListenerInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  bio: Scalars['String'];
};

export type Announcement = {
   __typename?: 'Announcement';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  type: Scalars['String'];
  buttonLabel: Scalars['String'];
  buttonAction: Scalars['String'];
};

export type ReflectionInput = {
  id: Scalars['Int'];
  text: Scalars['String'];
  mood: Scalars['Int'];
  title: Scalars['String'];
};

export type Reflection = {
   __typename?: 'Reflection';
  id: Scalars['Int'];
  text: Scalars['String'];
  userId: Scalars['String'];
  mood?: Maybe<Scalars['Int']>;
  chatId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  title?: Maybe<Scalars['String']>;
};

export type Reflections = {
   __typename?: 'Reflections';
  reflections: Array<Reflection>;
  hasMore: Scalars['Boolean'];
};

export type MaybeReflections = Reflections | NotLoggedIn;

export type MaybeReflection = Reflection | NotLoggedIn;

export type CreateTopicResult = Topic | AlreadyExists | DoNotHavePermission | NotLoggedIn;

export type Topic = {
   __typename?: 'Topic';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sessionsToday: Scalars['Int'];
  numberOfListeners: Scalars['Int'];
  waitingSessions: Scalars['Int'];
};

export type CreateTopicInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type Language = {
   __typename?: 'Language';
  id: Scalars['Int'];
  name: Scalars['String'];
  label: Scalars['String'];
};

export type ListenerPreference = {
   __typename?: 'ListenerPreference';
  id: Scalars['Int'];
  ageRange: Scalars['String'];
};

export enum RoomRequestStatus {
  Requested = 'REQUESTED',
  TimedOut = 'TIMED_OUT',
  Accepted = 'ACCEPTED',
  Ended = 'ENDED',
  Cancelled = 'CANCELLED',
  Scheduled = 'SCHEDULED'
}

export type RoomParticipant = {
   __typename?: 'RoomParticipant';
  id: Scalars['String'];
  name: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  isLead: Scalars['Boolean'];
};

export type Room = {
   __typename?: 'Room';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: RoomRequestStatus;
  topic: Topic;
  lead?: Maybe<Listener>;
  createdAt?: Maybe<Scalars['Date']>;
  participants: Array<RoomParticipant>;
  isInRoom: Scalars['Boolean'];
  startAt?: Maybe<Scalars['Date']>;
};

export type Rooms = {
   __typename?: 'Rooms';
  items: Array<Room>;
};

export type MaybeRooms = Rooms | NotLoggedIn | DoNotHavePermission;

export type AdminNote = {
   __typename?: 'AdminNote';
  id: Scalars['Int'];
  note: Scalars['String'];
  userId: Scalars['String'];
  adminId: Scalars['String'];
  createdAt: Scalars['Date'];
};

export type PremiumMembership = {
   __typename?: 'PremiumMembership';
  id: Scalars['String'];
  state: Scalars['String'];
  productSku: Scalars['String'];
  subscriptionActiveFrom?: Maybe<Scalars['Date']>;
  subscriptionActiveTo?: Maybe<Scalars['Date']>;
  lastPaymentAt?: Maybe<Scalars['Date']>;
};

export type ChatRequestReportCategory = {
   __typename?: 'ChatRequestReportCategory';
  id: Scalars['Int'];
  category: Scalars['String'];
};

export type ChatRequestReport = {
   __typename?: 'ChatRequestReport';
  id: Scalars['Int'];
  chatRequestId: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  reporterId: Scalars['String'];
  reportedId: Scalars['String'];
  categories: Array<Maybe<ChatRequestReportCategory>>;
};

export type MyChatRequestAccepted = {
   __typename?: 'MyChatRequestAccepted';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  state: RequestState;
  selectedTopic?: Maybe<Topic>;
  listener?: Maybe<Listener>;
};

export type ChatRequestsResult = {
   __typename?: 'ChatRequestsResult';
  chatRequests: Array<ChatRequest>;
};

export type PendingChatsResult = ChatRequestsResult | NotLoggedIn | DoNotHavePermission;

export type PastSessionsResult = ChatRequestsResult | NotLoggedIn;

export type CreateRequestInput = {
  topicId: Scalars['Int'];
  languagePreference?: Maybe<Scalars['Int']>;
};

export enum RequestState {
  Requested = 'REQUESTED',
  TimedOut = 'TIMED_OUT',
  Accepted = 'ACCEPTED',
  Ended = 'ENDED',
  Cancelled = 'CANCELLED'
}

export enum CancellationReason {
  TooLong = 'TOO_LONG',
  Nervous = 'NERVOUS'
}

export type PreSessionInfo = {
   __typename?: 'PreSessionInfo';
  id: Scalars['ID'];
  value: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type ChatRequest = {
   __typename?: 'ChatRequest';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  member: User;
  state: RequestState;
  selectedTopic?: Maybe<Topic>;
  listener?: Maybe<Listener>;
  chat?: Maybe<Chat>;
  preSessionMood?: Maybe<PreSessionInfo>;
  recommendedForYou?: Maybe<Scalars['Boolean']>;
  dedicatedChat?: Maybe<ChatRequestDedicatedChatResult>;
  previousChatRequest?: Maybe<ChatRequest>;
  languagePreference?: Maybe<Language>;
};

export type ChatRequestStateUpdated = {
   __typename?: 'ChatRequestStateUpdated';
  id: Scalars['ID'];
  member: User;
  createdAt: Scalars['Date'];
  state: RequestState;
  selectedTopic?: Maybe<Topic>;
  recommendedForYou?: Maybe<Scalars['Boolean']>;
  languagePreference?: Maybe<Language>;
};

export type MyChatEnded = {
   __typename?: 'MyChatEnded';
  id: Scalars['ID'];
  state: RequestState;
};

export type ChatRequestResult = ChatRequest | NotLoggedIn | DoesNotExist | InvalidRequestState | DoNotHavePermission | RateLimit;

export type ChatRequestDedicatedChatResult = DedicatedChat | NotLoggedIn | DoNotHavePermission;

export type AddedToDedicatedChat = {
   __typename?: 'AddedToDedicatedChat';
  id: Scalars['ID'];
  user: User;
  listener: Listener;
};

export type RemovedFromDedicatedChat = {
   __typename?: 'RemovedFromDedicatedChat';
  id: Scalars['ID'];
};

export type DedicatedChatResult = DedicatedChat | NotLoggedIn | DoNotHavePermission | DoesNotExist | AlreadyExists;

export enum DedicatedChatState {
  Requested = 'REQUESTED',
  InProgress = 'IN_PROGRESS',
  Ended = 'ENDED'
}

export type DedicatedChat = {
   __typename?: 'DedicatedChat';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  member: User;
  listener: Listener;
  state: DedicatedChatState;
  isListener: Scalars['Boolean'];
};

export enum Platform {
  Ios = 'IOS',
  Android = 'ANDROID',
  Web = 'WEB'
}

export enum ReportCategory {
  Crisis = 'CRISIS',
  Inappropriate = 'INAPPROPRIATE',
  Underage = 'UNDERAGE',
  ContactInfo = 'CONTACT_INFO'
}

export enum EndEarlyReason {
  NothingToSay = 'nothing_to_say',
  NotRelatable = 'not_relatable',
  HadListenerBefore = 'had_listener_before'
}

export type TokenResult = Token | NotLoggedIn;

export type ChatResult = Chat | NotLoggedIn | DoNotHavePermission | DoesNotExist | InvalidRequestState;

export enum ChatState {
  Created = 'CREATED',
  Started = 'STARTED',
  Ended = 'ENDED'
}

export type Chat = {
   __typename?: 'Chat';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  startedAt?: Maybe<Scalars['Date']>;
  endedAt?: Maybe<Scalars['Date']>;
  state: ChatState;
  messages: Array<Message>;
  reports: Array<Report>;
  rating?: Maybe<Scalars['Int']>;
  reflection?: Maybe<Scalars['String']>;
  review?: Maybe<Review>;
  chatRequest: ChatRequest;
  chatRoomId?: Maybe<Scalars['ID']>;
  endEarlyReasons?: Maybe<Array<Maybe<EndEarlyReason>>>;
  memberJoined?: Maybe<Scalars['Boolean']>;
};

export type Review = {
   __typename?: 'Review';
  id: Scalars['ID'];
  rating: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
  approved: Scalars['Boolean'];
};

export type Message = {
   __typename?: 'Message';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  sender?: Maybe<User>;
  senderId: Scalars['String'];
  text: Scalars['String'];
  attributes?: Maybe<Scalars['JSON']>;
};

export type Report = {
   __typename?: 'Report';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  reporter: User;
  reason: Scalars['String'];
  categories?: Maybe<Array<ReportCategory>>;
};

export type Token = {
   __typename?: 'Token';
  jwt: Scalars['String'];
  xmppToken: Scalars['String'];
};

export type ReferralCode = {
   __typename?: 'ReferralCode';
  id: Scalars['Int'];
  value: Scalars['String'];
  isActive: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
};


export type RateLimit = {
   __typename?: 'RateLimit';
  message?: Maybe<Scalars['String']>;
};

export type NotLoggedIn = {
   __typename?: 'NotLoggedIn';
  message?: Maybe<Scalars['String']>;
};

export type DoNotHavePermission = {
   __typename?: 'DoNotHavePermission';
  message?: Maybe<Scalars['String']>;
};

export type AlreadyExists = {
   __typename?: 'AlreadyExists';
  message?: Maybe<Scalars['String']>;
};

export type DoesNotExist = {
   __typename?: 'DoesNotExist';
  message?: Maybe<Scalars['String']>;
};

export type InvalidRequestState = {
   __typename?: 'InvalidRequestState';
  message?: Maybe<Scalars['String']>;
};

export type ReceiptError = {
   __typename?: 'ReceiptError';
  message?: Maybe<Scalars['String']>;
};


export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type MeUserDetailsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'alias' | 'createdAt' | 'hasReasons' | 'isChatAcceptanceNotificationOn' | 'isChatMessageNotificationOn' | 'hasRatedAndroid' | 'isBetaTester' | 'isSuspended' | 'isAdmin'>
  & { listenerPreferences: Array<(
    { __typename?: 'ListenerPreference' }
    & Pick<ListenerPreference, 'id' | 'ageRange'>
  )>, languages?: Maybe<Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'id' | 'name' | 'label'>
  )>>, listener?: Maybe<(
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'firstName' | 'lastName' | 'notificationsDisabled' | 'isEnabled' | 'isModerator' | 'avatarUrl' | 'bio' | 'isBanned' | 'age' | 'gender' | 'avatarReported'>
    & { topics: Array<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id'>
    )> }
  )> }
);

export type MeCurrentRequestDetailsFragment = (
  { __typename?: 'ChatRequest' }
  & Pick<ChatRequest, 'id' | 'state'>
  & { member: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'alias'>
  ), listener?: Maybe<(
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'firstName' | 'avatarUrl'>
  )> }
);

export type MeAnnouncementsDetailsFragment = (
  { __typename?: 'Announcement' }
  & Pick<Announcement, 'id' | 'title' | 'description' | 'imageUrl' | 'startDate' | 'endDate' | 'buttonUrl' | 'type' | 'buttonLabel' | 'buttonAction'>
);

export type AddCancellationReasonsMutationVariables = {
  chatReqId: Scalars['ID'];
  reasons: Array<CancellationReason>;
};


export type AddCancellationReasonsMutation = (
  { __typename?: 'Mutation' }
  & { addCancellationReasons: (
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id' | 'state' | 'createdAt'>
    & { selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | { __typename?: 'RateLimit' } }
);

export type AddDeviceTokenMutationVariables = {
  token: Scalars['String'];
};


export type AddDeviceTokenMutation = (
  { __typename?: 'Mutation' }
  & { addDeviceToken: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type AddLanguageMutationVariables = {
  language: Scalars['Int'];
};


export type AddLanguageMutation = (
  { __typename?: 'Mutation' }
  & { addLanguage: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { languages?: Maybe<Array<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'label'>
    )>> }
  ) }
);

export type AddListenerPhotoMutationVariables = {
  file: Scalars['Upload'];
};


export type AddListenerPhotoMutation = (
  { __typename?: 'Mutation' }
  & { addListenerPhoto: (
    { __typename?: 'Listener' }
    & Pick<Listener, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | { __typename?: 'DoNotHavePermission' } }
);

export type AddPostSessionMoodMutationVariables = {
  chatReqId: Scalars['ID'];
  value: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};


export type AddPostSessionMoodMutation = (
  { __typename?: 'Mutation' }
  & { addPostSessionMood: (
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id' | 'state' | 'createdAt'>
    & { selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | { __typename?: 'RateLimit' } }
);

export type AddPreSessionMoodMutationVariables = {
  chatReqId: Scalars['ID'];
  value: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};


export type AddPreSessionMoodMutation = (
  { __typename?: 'Mutation' }
  & { addPreSessionMood: (
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id' | 'state' | 'createdAt'>
    & { selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | { __typename?: 'RateLimit' } }
);

export type BlockListenerMutationVariables = {
  listenerId: Scalars['ID'];
};


export type BlockListenerMutation = (
  { __typename?: 'Mutation' }
  & { blockListener: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { blockedListeners: Array<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'isBlocked'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) }
);

export type CancelMyRequestMutationVariables = {};


export type CancelMyRequestMutation = (
  { __typename?: 'Mutation' }
  & { cancelMyRequest: (
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id' | 'state' | 'createdAt'>
    & { selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | { __typename?: 'RateLimit' } }
);

export type ChangeListenerNameMutationVariables = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type ChangeListenerNameMutation = (
  { __typename?: 'Mutation' }
  & { changeListenerName: (
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'firstName' | 'lastName'>
  ) | { __typename?: 'NotLoggedIn' } | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } | { __typename?: 'DoNotHavePermission' } }
);

export type ConfigureNotificationOptionsMutationVariables = {
  isChatAcceptanceNotificationOn: Scalars['Boolean'];
  isChatMessageNotificationOn: Scalars['Boolean'];
};


export type ConfigureNotificationOptionsMutation = (
  { __typename?: 'Mutation' }
  & { configureNotificationOptions: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type CreateDedicatedChatMutationVariables = {
  memberId: Scalars['ID'];
  message: Scalars['String'];
};


export type CreateDedicatedChatMutation = (
  { __typename?: 'Mutation' }
  & { createDedicatedChat: (
    { __typename?: 'DedicatedChat' }
    & Pick<DedicatedChat, 'id' | 'state'>
    & { listener: (
      { __typename?: 'Listener' }
      & Pick<Listener, 'id'>
    ), member: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alias'>
    ) }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | { __typename?: 'AlreadyExists' } }
);

export type CreateListenerMutationVariables = {
  input: CreateListenerInput;
};


export type CreateListenerMutation = (
  { __typename?: 'Mutation' }
  & { createListener: (
    { __typename?: 'Listener' }
    & Pick<Listener, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | { __typename?: 'DoNotHavePermission' } }
);

export type CreateReflectionMutationVariables = {
  text: Scalars['String'];
  mood: Scalars['Int'];
  chatId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type CreateReflectionMutation = (
  { __typename?: 'Mutation' }
  & { createReflection: (
    { __typename?: 'Reflection' }
    & Pick<Reflection, 'id' | 'text' | 'userId' | 'mood' | 'chatId'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) }
);

export type CreateRequestMutationVariables = {
  input: CreateRequestInput;
};


export type CreateRequestMutation = (
  { __typename?: 'Mutation' }
  & { createRequest: (
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id' | 'state'>
    & { selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id'>
    )>, member: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | (
    { __typename?: 'RateLimit' }
    & Pick<RateLimit, 'message'>
  ) }
);

export type DeleteMyAccountMutationVariables = {};


export type DeleteMyAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMyAccount'>
);

export type EditListenerInfoMutationVariables = {
  input: EditListenerInput;
};


export type EditListenerInfoMutation = (
  { __typename?: 'Mutation' }
  & { editListenerInfo: (
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'bio' | 'firstName' | 'lastName' | 'fullName' | 'age' | 'gender'>
  ) | { __typename?: 'NotLoggedIn' } | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } | { __typename?: 'DoNotHavePermission' } }
);

export type EndDedicatedChatMutationVariables = {
  chatId: Scalars['ID'];
  reason: Scalars['String'];
};


export type EndDedicatedChatMutation = (
  { __typename?: 'Mutation' }
  & { endDedicatedChat: (
    { __typename?: 'DedicatedChat' }
    & Pick<DedicatedChat, 'id' | 'state'>
  ) | { __typename?: 'NotLoggedIn' } | { __typename?: 'DoNotHavePermission' } | { __typename?: 'DoesNotExist' } | { __typename?: 'AlreadyExists' } }
);

export type EndMyChatMutationVariables = {
  id: Scalars['ID'];
};


export type EndMyChatMutation = (
  { __typename?: 'Mutation' }
  & { endMyChat: (
    { __typename?: 'Chat' }
    & Pick<Chat, 'id' | 'state' | 'startedAt' | 'endedAt'>
    & { chatRequest: (
      { __typename?: 'ChatRequest' }
      & Pick<ChatRequest, 'id' | 'state'>
      & { selectedTopic?: Maybe<(
        { __typename?: 'Topic' }
        & Pick<Topic, 'id'>
      )> }
    ) }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) }
);

export type GiveFeedbackMutationVariables = {
  feedback: Scalars['String'];
  deviceInformation?: Maybe<Scalars['JSON']>;
};


export type GiveFeedbackMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'giveFeedback'>
);

export type JoinChatMutationVariables = {
  id: Scalars['ID'];
};


export type JoinChatMutation = (
  { __typename?: 'Mutation' }
  & { joinChat: (
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id' | 'state'>
    & { selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id' | 'name'>
    )>, chat?: Maybe<(
      { __typename?: 'Chat' }
      & Pick<Chat, 'id' | 'state'>
    )>, member: (
      { __typename?: 'User' }
      & Pick<User, 'alias' | 'id'>
    ) }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | { __typename?: 'RateLimit' } }
);

export type JoinChatAsMemberMutationVariables = {
  id: Scalars['ID'];
};


export type JoinChatAsMemberMutation = (
  { __typename?: 'Mutation' }
  & { joinChatAsMember: (
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | (
    { __typename?: 'InvalidRequestState' }
    & Pick<InvalidRequestState, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | { __typename?: 'RateLimit' } }
);

export type LeaveReportMutationVariables = {
  id: Scalars['ID'];
  reason: Scalars['String'];
  categories?: Maybe<Array<ReportCategory>>;
};


export type LeaveReportMutation = (
  { __typename?: 'Mutation' }
  & { leaveReport: (
    { __typename?: 'Chat' }
    & Pick<Chat, 'id' | 'rating'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | { __typename?: 'InvalidRequestState' } }
);

export type LeaveReviewMutationVariables = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
};


export type LeaveReviewMutation = (
  { __typename?: 'Mutation' }
  & { leaveReview: (
    { __typename?: 'Chat' }
    & Pick<Chat, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) | { __typename?: 'InvalidRequestState' } }
);

export type RateAndroidMutationVariables = {};


export type RateAndroidMutation = (
  { __typename?: 'Mutation' }
  & { rateAndroid: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type RegisterMutationVariables = {
  input: RegisterInput;
};


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | { __typename?: 'NotLoggedIn' } | (
    { __typename?: 'AlreadyExists' }
    & Pick<AlreadyExists, 'message'>
  ) | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } | { __typename?: 'DoNotHavePermission' } }
);

export type RemoveDeviceTokenMutationVariables = {
  token: Scalars['String'];
};


export type RemoveDeviceTokenMutation = (
  { __typename?: 'Mutation' }
  & { removeDeviceToken: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type RemoveMessageFromListenersGroupChatMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveMessageFromListenersGroupChatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeMessageFromListenersGroupChat'>
);

export type ReportDedicatedChatMutationVariables = {
  chatId: Scalars['ID'];
  reason: Scalars['String'];
  category?: Maybe<ReportCategory>;
};


export type ReportDedicatedChatMutation = (
  { __typename?: 'Mutation' }
  & { reportDedicatedChat: (
    { __typename?: 'DedicatedChat' }
    & Pick<DedicatedChat, 'id' | 'state'>
  ) | { __typename?: 'NotLoggedIn' } | { __typename?: 'DoNotHavePermission' } | { __typename?: 'DoesNotExist' } | { __typename?: 'AlreadyExists' } }
);

export type SetAliasMutationVariables = {
  alias: Scalars['String'];
};


export type SetAliasMutation = (
  { __typename?: 'Mutation' }
  & { setAlias: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'alias'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) }
);

export type SetCurrentGroupChatTopicMutationVariables = {
  topic: Scalars['String'];
  description: Scalars['String'];
};


export type SetCurrentGroupChatTopicMutation = (
  { __typename?: 'Mutation' }
  & { setCurrentGroupChatTopic?: Maybe<(
    { __typename?: 'GroupChatTopic' }
    & Pick<GroupChatTopic, 'topic' | 'description'>
  )> }
);

export type SetEndChatEarlyReasonsMutationVariables = {
  id: Scalars['ID'];
  reasons: Array<EndEarlyReason>;
};


export type SetEndChatEarlyReasonsMutation = (
  { __typename?: 'Mutation' }
  & { setEndChatEarlyReasons: (
    { __typename?: 'Chat' }
    & Pick<Chat, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoNotHavePermission' } | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } }
);

export type SetLanguagesMutationVariables = {
  languages: Array<Scalars['Int']>;
};


export type SetLanguagesMutation = (
  { __typename?: 'Mutation' }
  & { setLanguages: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { languages?: Maybe<Array<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'label'>
    )>> }
  ) }
);

export type SetListenerNotificationsEnabledMutationVariables = {
  value: Scalars['Boolean'];
};


export type SetListenerNotificationsEnabledMutation = (
  { __typename?: 'Mutation' }
  & { setListenerNotificationsEnabled: (
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'notificationsDisabled'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } | { __typename?: 'DoNotHavePermission' } }
);

export type SetListenerPreferencesMutationVariables = {
  ageRange: Array<Scalars['Int']>;
};


export type SetListenerPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { setListenerPreferences: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { listenerPreferences: Array<(
      { __typename?: 'ListenerPreference' }
      & Pick<ListenerPreference, 'id' | 'ageRange'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type SetListenerTopicsMutationVariables = {
  topicIds: Array<Scalars['Int']>;
};


export type SetListenerTopicsMutation = (
  { __typename?: 'Mutation' }
  & { setListenerTopics: (
    { __typename?: 'Listener' }
    & Pick<Listener, 'id'>
    & { topics: Array<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } | { __typename?: 'DoNotHavePermission' } }
);

export type SetUsageReasonsMutationVariables = {
  reasons: Array<Scalars['Int']>;
};


export type SetUsageReasonsMutation = (
  { __typename?: 'Mutation' }
  & { setUsageReasons: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type ToggleUserBanMutationVariables = {
  id: Scalars['ID'];
};


export type ToggleUserBanMutation = (
  { __typename?: 'Mutation' }
  & { toggleUserBan: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isSuspended'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ) }
);

export type UnblockListenerMutationVariables = {
  listenerId: Scalars['ID'];
};


export type UnblockListenerMutation = (
  { __typename?: 'Mutation' }
  & { unblockListener: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { blockedListeners: Array<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'isBlocked'>
    )> }
  ) | { __typename?: 'NotLoggedIn' } }
);

export type GetChatQueryVariables = {
  id: Scalars['ID'];
};


export type GetChatQuery = (
  { __typename?: 'Query' }
  & { getChatRequest?: Maybe<(
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'createdAt' | 'id' | 'state'>
    & { member: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alias' | 'isNewMember'>
    ), listener?: Maybe<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'firstName' | 'avatarUrl' | 'bio' | 'createdAt' | 'gender' | 'age' | 'sessionCount'>
      & { topics: Array<(
        { __typename?: 'Topic' }
        & Pick<Topic, 'name' | 'id'>
      )> }
    )>, chat?: Maybe<(
      { __typename?: 'Chat' }
      & Pick<Chat, 'id' | 'startedAt' | 'memberJoined'>
    )>, selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id' | 'name' | 'description'>
    )> }
  )> }
);

export type GetChatRequestQueryVariables = {
  id: Scalars['ID'];
};


export type GetChatRequestQuery = (
  { __typename?: 'Query' }
  & { getChatRequest?: Maybe<(
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'createdAt' | 'id' | 'state'>
    & { member: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alias' | 'isNewMember'>
    ), preSessionMood?: Maybe<(
      { __typename?: 'PreSessionInfo' }
      & Pick<PreSessionInfo, 'id' | 'value' | 'description'>
    )>, listener?: Maybe<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'firstName' | 'avatarUrl' | 'bio' | 'createdAt' | 'gender' | 'age' | 'sessionCount'>
      & { topics: Array<(
        { __typename?: 'Topic' }
        & Pick<Topic, 'name' | 'id'>
      )> }
    )>, chat?: Maybe<(
      { __typename?: 'Chat' }
      & Pick<Chat, 'id' | 'chatRoomId' | 'reflection' | 'startedAt'>
      & { review?: Maybe<(
        { __typename?: 'Review' }
        & Pick<Review, 'rating' | 'reason'>
      )>, messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'senderId' | 'createdAt' | 'attributes' | 'text'>
      )> }
    )>, selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id' | 'name' | 'description'>
    )>, dedicatedChat?: Maybe<(
      { __typename?: 'DedicatedChat' }
      & Pick<DedicatedChat, 'id'>
    ) | { __typename?: 'NotLoggedIn' } | { __typename?: 'DoNotHavePermission' }>, previousChatRequest?: Maybe<(
      { __typename?: 'ChatRequest' }
      & Pick<ChatRequest, 'id'>
    )> }
  )> }
);

export type GetCurrentChatStateQueryVariables = {
  id: Scalars['ID'];
};


export type GetCurrentChatStateQuery = (
  { __typename?: 'Query' }
  & { getChatRequest?: Maybe<(
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id' | 'state'>
  )> }
);

export type GetCurrentGroupChatTopicQueryVariables = {};


export type GetCurrentGroupChatTopicQuery = (
  { __typename?: 'Query' }
  & { getCurrentGroupChatTopic: (
    { __typename?: 'GroupChatTopic' }
    & Pick<GroupChatTopic, 'topic' | 'description'>
  ) }
);

export type GetLanguagesQueryVariables = {};


export type GetLanguagesQuery = (
  { __typename?: 'Query' }
  & { languages: Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'id' | 'name' | 'label'>
  )> }
);

export type GetListenerQueryVariables = {
  id: Scalars['ID'];
};


export type GetListenerQuery = (
  { __typename?: 'Query' }
  & { listener?: Maybe<(
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'email' | 'firstName' | 'avatarUrl' | 'createdAt' | 'bio' | 'gender' | 'age' | 'sessionCount' | 'isModerator' | 'isBanned'>
    & { location?: Maybe<(
      { __typename?: 'OutputLocation' }
      & Pick<OutputLocation, 'city' | 'countryCode'>
    )>, topics: Array<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'name' | 'id'>
    )> }
  )> }
);

export type GetListenerByUserIdQueryVariables = {
  id: Scalars['ID'];
};


export type GetListenerByUserIdQuery = (
  { __typename?: 'Query' }
  & { getListenerByUserId?: Maybe<(
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'firstName' | 'avatarUrl' | 'createdAt' | 'bio' | 'gender' | 'age' | 'isModerator' | 'isBanned'>
    & { location?: Maybe<(
      { __typename?: 'OutputLocation' }
      & Pick<OutputLocation, 'city' | 'countryCode'>
    )>, topics: Array<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'name' | 'id'>
    )> }
  )> }
);

export type GetListenerPostSessionInfoQueryVariables = {
  id: Scalars['ID'];
};


export type GetListenerPostSessionInfoQuery = (
  { __typename?: 'Query' }
  & { getChatRequest?: Maybe<(
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id'>
    & { member: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alias'>
    ) }
  )> }
);

export type GetListenerStatusQueryVariables = {};


export type GetListenerStatusQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isSuspended'>
    & { listener?: Maybe<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'isEnabled' | 'isBanned'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type GetListenersDataQueryVariables = {};


export type GetListenersDataQuery = (
  { __typename?: 'Query' }
  & { getListenersData: Array<(
    { __typename?: 'Listener' }
    & Pick<Listener, 'id' | 'email' | 'avatarUrl' | 'firstName' | 'isModerator'>
  )> }
);

export type GetMemberStatsQueryVariables = {};


export type GetMemberStatsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'chatCount' | 'currentStreak' | 'reflectionCount'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type GetMyBlockedListenersQueryVariables = {};


export type GetMyBlockedListenersQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { blockedListeners: Array<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'firstName' | 'avatarUrl' | 'isBlocked'>
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type GetMyDedicatedChatsQueryVariables = {};


export type GetMyDedicatedChatsQuery = (
  { __typename?: 'Query' }
  & { getMyDedicatedChats: Array<(
    { __typename?: 'DedicatedChat' }
    & Pick<DedicatedChat, 'id' | 'createdAt' | 'isListener' | 'state'>
    & { listener: (
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'firstName' | 'avatarUrl'>
    ), member: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alias'>
    ) }
  )> }
);

export type GetPastSessionsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type GetPastSessionsQuery = (
  { __typename?: 'Query' }
  & { pastSessions: (
    { __typename?: 'ChatRequestsResult' }
    & { chatRequests: Array<(
      { __typename?: 'ChatRequest' }
      & Pick<ChatRequest, 'id' | 'createdAt'>
      & { member: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'alias'>
      ), selectedTopic?: Maybe<(
        { __typename?: 'Topic' }
        & Pick<Topic, 'id' | 'name'>
      )>, listener?: Maybe<(
        { __typename?: 'Listener' }
        & Pick<Listener, 'id' | 'firstName' | 'avatarUrl'>
      )>, chat?: Maybe<(
        { __typename?: 'Chat' }
        & Pick<Chat, 'id'>
      )> }
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) }
);

export type GetPastSessionsByUserIdQueryVariables = {
  id: Scalars['ID'];
};


export type GetPastSessionsByUserIdQuery = (
  { __typename?: 'Query' }
  & { getPastSessionsByUserId: (
    { __typename?: 'ChatRequestsResult' }
    & { chatRequests: Array<(
      { __typename?: 'ChatRequest' }
      & Pick<ChatRequest, 'id' | 'createdAt'>
      & { member: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'alias'>
      ), selectedTopic?: Maybe<(
        { __typename?: 'Topic' }
        & Pick<Topic, 'id' | 'name'>
      )>, listener?: Maybe<(
        { __typename?: 'Listener' }
        & Pick<Listener, 'id' | 'firstName' | 'avatarUrl'>
      )>, chat?: Maybe<(
        { __typename?: 'Chat' }
        & Pick<Chat, 'id'>
      )> }
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) }
);

export type GetPendingChatRequestsQueryVariables = {};


export type GetPendingChatRequestsQuery = (
  { __typename?: 'Query' }
  & { getPendingChatRequests?: Maybe<(
    { __typename?: 'ChatRequestsResult' }
    & { chatRequests: Array<(
      { __typename?: 'ChatRequest' }
      & Pick<ChatRequest, 'id' | 'recommendedForYou' | 'createdAt'>
      & { selectedTopic?: Maybe<(
        { __typename?: 'Topic' }
        & Pick<Topic, 'id' | 'name'>
      )>, member: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'alias'>
      ), languagePreference?: Maybe<(
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name' | 'label'>
      )> }
    )> }
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoNotHavePermission' }
    & Pick<DoNotHavePermission, 'message'>
  )> }
);

export type GetPostSessionInfoQueryVariables = {
  id: Scalars['ID'];
};


export type GetPostSessionInfoQuery = (
  { __typename?: 'Query' }
  & { getChatRequest?: Maybe<(
    { __typename?: 'ChatRequest' }
    & Pick<ChatRequest, 'id'>
    & { listener?: Maybe<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'firstName' | 'avatarUrl'>
    )>, selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id' | 'name' | 'sessionsToday' | 'description'>
    )> }
  )> }
);

export type GetRecentTopicsQueryVariables = {};


export type GetRecentTopicsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } }
);

export type GetReflectionsQueryVariables = {
  after?: Maybe<Scalars['Date']>;
  before?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
};


export type GetReflectionsQuery = (
  { __typename?: 'Query' }
  & { getReflections: (
    { __typename?: 'Reflections' }
    & Pick<Reflections, 'hasMore'>
    & { reflections: Array<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'text' | 'userId' | 'mood' | 'chatId' | 'createdAt'>
    )> }
  ) | { __typename?: 'NotLoggedIn' } }
);

export type GetTopicSessionCountsQueryVariables = {};


export type GetTopicSessionCountsQuery = (
  { __typename?: 'Query' }
  & { topics: Array<(
    { __typename?: 'Topic' }
    & Pick<Topic, 'id' | 'name' | 'waitingSessions'>
  )> }
);

export type GetUnapprovedListenersQueryVariables = {};


export type GetUnapprovedListenersQuery = (
  { __typename?: 'Query' }
  & { getUnapprovedListeners: (
    { __typename?: 'Listeners' }
    & { listeners: Array<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'email' | 'avatarUrl' | 'firstName' | 'lastName'>
    )> }
  ) | { __typename?: 'NotLoggedIn' } | { __typename?: 'DoNotHavePermission' } }
);

export type GetUsersByAliasQueryVariables = {
  alias: Scalars['String'];
};


export type GetUsersByAliasQuery = (
  { __typename?: 'Query' }
  & { getUsersByAlias: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isSuspended' | 'alias' | 'phone'>
  )> }
);

export type GlobalsQueryVariables = {};


export type GlobalsQuery = (
  { __typename?: 'Query' }
  & { listenerPreferences: Array<(
    { __typename?: 'ListenerPreference' }
    & Pick<ListenerPreference, 'id' | 'ageRange'>
  )>, languages: Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'id' | 'name' | 'label'>
  )>, topics: Array<(
    { __typename?: 'Topic' }
    & Pick<Topic, 'id' | 'name' | 'description' | 'sessionsToday' | 'numberOfListeners' | 'waitingSessions'>
  )> }
);

export type IsPhoneNumberInUseQueryVariables = {
  phone: Scalars['String'];
};


export type IsPhoneNumberInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isPhoneNumberInUse'>
);

export type MeQueryVariables = {
  platform: Platform;
};


export type MeQuery = (
  { __typename?: 'Query' }
  & { chatToken: (
    { __typename?: 'Token' }
    & Pick<Token, 'xmppToken'>
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ), currentRequest?: Maybe<(
    { __typename?: 'ChatRequest' }
    & MeCurrentRequestDetailsFragment
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } | { __typename?: 'DoNotHavePermission' } | { __typename?: 'RateLimit' }>, currentRequests?: Maybe<Array<Maybe<(
    { __typename?: 'ChatRequest' }
    & MeCurrentRequestDetailsFragment
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | { __typename?: 'DoesNotExist' } | { __typename?: 'InvalidRequestState' } | { __typename?: 'DoNotHavePermission' } | { __typename?: 'RateLimit' }>>>, me: (
    { __typename?: 'User' }
    & MeUserDetailsFragment
  ) | (
    { __typename?: 'NotLoggedIn' }
    & Pick<NotLoggedIn, 'message'>
  ) | (
    { __typename?: 'DoesNotExist' }
    & Pick<DoesNotExist, 'message'>
  ), announcements: Array<Maybe<(
    { __typename?: 'Announcement' }
    & MeAnnouncementsDetailsFragment
  )>> }
);

export type AddedToDedicatedChatSubscriptionVariables = {};


export type AddedToDedicatedChatSubscription = (
  { __typename?: 'Subscription' }
  & { addedToDedicatedChat: (
    { __typename?: 'AddedToDedicatedChat' }
    & Pick<AddedToDedicatedChat, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alias'>
    ), listener: (
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'firstName' | 'avatarUrl'>
    ) }
  ) }
);

export type ChatRequestStateUpdatedSubscriptionVariables = {};


export type ChatRequestStateUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { chatRequestStateUpdated: (
    { __typename?: 'ChatRequestStateUpdated' }
    & Pick<ChatRequestStateUpdated, 'id' | 'state' | 'recommendedForYou' | 'createdAt'>
    & { languagePreference?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'label'>
    )>, member: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alias'>
    ), selectedTopic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'id' | 'name'>
    )> }
  ) }
);

export type MyChatEndedSubscriptionVariables = {};


export type MyChatEndedSubscription = (
  { __typename?: 'Subscription' }
  & { myChatEnded: (
    { __typename?: 'MyChatEnded' }
    & Pick<MyChatEnded, 'id' | 'state'>
  ) }
);

export type MyChatRequestAcceptedSubscriptionVariables = {};


export type MyChatRequestAcceptedSubscription = (
  { __typename?: 'Subscription' }
  & { myChatRequestAccepted: (
    { __typename?: 'MyChatRequestAccepted' }
    & Pick<MyChatRequestAccepted, 'id' | 'state'>
    & { listener?: Maybe<(
      { __typename?: 'Listener' }
      & Pick<Listener, 'id' | 'firstName' | 'avatarUrl' | 'bio' | 'createdAt' | 'gender' | 'age' | 'sessionCount'>
      & { topics: Array<(
        { __typename?: 'Topic' }
        & Pick<Topic, 'name' | 'id'>
      )> }
    )> }
  ) }
);

export type OnlineStatusChangedSubscriptionVariables = {};


export type OnlineStatusChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onlineStatusChanged: (
    { __typename?: 'OnlineIndicator' }
    & Pick<OnlineIndicator, 'userId' | 'online'>
  ) }
);

export type RemovedFromDedicatedChatSubscriptionVariables = {};


export type RemovedFromDedicatedChatSubscription = (
  { __typename?: 'Subscription' }
  & { removedFromDedicatedChat: (
    { __typename?: 'RemovedFromDedicatedChat' }
    & Pick<RemovedFromDedicatedChat, 'id'>
  ) }
);

export const MeUserDetailsFragmentDoc = gql`
    fragment MeUserDetails on User {
  id
  alias
  createdAt
  hasReasons
  isChatAcceptanceNotificationOn
  isChatMessageNotificationOn
  hasRatedAndroid
  isBetaTester
  listenerPreferences {
    id
    ageRange
  }
  languages {
    id
    name
    label
  }
  isSuspended
  isAdmin
  listener {
    id
    firstName
    lastName
    notificationsDisabled
    isEnabled
    isModerator
    topics {
      id
    }
    avatarUrl
    bio
    isBanned
    age
    gender
    avatarReported
  }
}
    `;
export const MeCurrentRequestDetailsFragmentDoc = gql`
    fragment MeCurrentRequestDetails on ChatRequest {
  id
  state
  member {
    id
    alias
  }
  listener {
    id
    firstName
    avatarUrl
  }
}
    `;
export const MeAnnouncementsDetailsFragmentDoc = gql`
    fragment MeAnnouncementsDetails on Announcement {
  id
  title
  description
  imageUrl
  startDate
  endDate
  buttonUrl
  type
  buttonLabel
  buttonAction
}
    `;
export const AddCancellationReasonsDocument = gql`
    mutation addCancellationReasons($chatReqId: ID!, $reasons: [CancellationReason!]!) {
  addCancellationReasons(chatReqId: $chatReqId, reasons: $reasons) {
    ... on ChatRequest {
      id
      state
      createdAt
      selectedTopic {
        id
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;
export type AddCancellationReasonsMutationFn = ApolloReactCommon.MutationFunction<AddCancellationReasonsMutation, AddCancellationReasonsMutationVariables>;

/**
 * __useAddCancellationReasonsMutation__
 *
 * To run a mutation, you first call `useAddCancellationReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCancellationReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCancellationReasonsMutation, { data, loading, error }] = useAddCancellationReasonsMutation({
 *   variables: {
 *      chatReqId: // value for 'chatReqId'
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useAddCancellationReasonsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCancellationReasonsMutation, AddCancellationReasonsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCancellationReasonsMutation, AddCancellationReasonsMutationVariables>(AddCancellationReasonsDocument, baseOptions);
      }
export type AddCancellationReasonsMutationHookResult = ReturnType<typeof useAddCancellationReasonsMutation>;
export type AddCancellationReasonsMutationResult = ApolloReactCommon.MutationResult<AddCancellationReasonsMutation>;
export type AddCancellationReasonsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCancellationReasonsMutation, AddCancellationReasonsMutationVariables>;
export const AddDeviceTokenDocument = gql`
    mutation addDeviceToken($token: String!) {
  addDeviceToken(token: $token) {
    ... on User {
      id
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type AddDeviceTokenMutationFn = ApolloReactCommon.MutationFunction<AddDeviceTokenMutation, AddDeviceTokenMutationVariables>;

/**
 * __useAddDeviceTokenMutation__
 *
 * To run a mutation, you first call `useAddDeviceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceTokenMutation, { data, loading, error }] = useAddDeviceTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddDeviceTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDeviceTokenMutation, AddDeviceTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDeviceTokenMutation, AddDeviceTokenMutationVariables>(AddDeviceTokenDocument, baseOptions);
      }
export type AddDeviceTokenMutationHookResult = ReturnType<typeof useAddDeviceTokenMutation>;
export type AddDeviceTokenMutationResult = ApolloReactCommon.MutationResult<AddDeviceTokenMutation>;
export type AddDeviceTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDeviceTokenMutation, AddDeviceTokenMutationVariables>;
export const AddLanguageDocument = gql`
    mutation addLanguage($language: Int!) {
  addLanguage(languageId: $language) {
    id
    languages {
      id
      name
      label
    }
  }
}
    `;
export type AddLanguageMutationFn = ApolloReactCommon.MutationFunction<AddLanguageMutation, AddLanguageMutationVariables>;

/**
 * __useAddLanguageMutation__
 *
 * To run a mutation, you first call `useAddLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLanguageMutation, { data, loading, error }] = useAddLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAddLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLanguageMutation, AddLanguageMutationVariables>) {
        return ApolloReactHooks.useMutation<AddLanguageMutation, AddLanguageMutationVariables>(AddLanguageDocument, baseOptions);
      }
export type AddLanguageMutationHookResult = ReturnType<typeof useAddLanguageMutation>;
export type AddLanguageMutationResult = ApolloReactCommon.MutationResult<AddLanguageMutation>;
export type AddLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLanguageMutation, AddLanguageMutationVariables>;
export const AddListenerPhotoDocument = gql`
    mutation addListenerPhoto($file: Upload!) {
  addListenerPhoto(file: $file) {
    ... on Listener {
      id
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
  }
}
    `;
export type AddListenerPhotoMutationFn = ApolloReactCommon.MutationFunction<AddListenerPhotoMutation, AddListenerPhotoMutationVariables>;

/**
 * __useAddListenerPhotoMutation__
 *
 * To run a mutation, you first call `useAddListenerPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddListenerPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addListenerPhotoMutation, { data, loading, error }] = useAddListenerPhotoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddListenerPhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddListenerPhotoMutation, AddListenerPhotoMutationVariables>) {
        return ApolloReactHooks.useMutation<AddListenerPhotoMutation, AddListenerPhotoMutationVariables>(AddListenerPhotoDocument, baseOptions);
      }
export type AddListenerPhotoMutationHookResult = ReturnType<typeof useAddListenerPhotoMutation>;
export type AddListenerPhotoMutationResult = ApolloReactCommon.MutationResult<AddListenerPhotoMutation>;
export type AddListenerPhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<AddListenerPhotoMutation, AddListenerPhotoMutationVariables>;
export const AddPostSessionMoodDocument = gql`
    mutation addPostSessionMood($chatReqId: ID!, $value: Int!, $description: String) {
  addPostSessionMood(chatReqId: $chatReqId, value: $value, description: $description) {
    ... on ChatRequest {
      id
      state
      createdAt
      selectedTopic {
        id
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;
export type AddPostSessionMoodMutationFn = ApolloReactCommon.MutationFunction<AddPostSessionMoodMutation, AddPostSessionMoodMutationVariables>;

/**
 * __useAddPostSessionMoodMutation__
 *
 * To run a mutation, you first call `useAddPostSessionMoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostSessionMoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostSessionMoodMutation, { data, loading, error }] = useAddPostSessionMoodMutation({
 *   variables: {
 *      chatReqId: // value for 'chatReqId'
 *      value: // value for 'value'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddPostSessionMoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPostSessionMoodMutation, AddPostSessionMoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPostSessionMoodMutation, AddPostSessionMoodMutationVariables>(AddPostSessionMoodDocument, baseOptions);
      }
export type AddPostSessionMoodMutationHookResult = ReturnType<typeof useAddPostSessionMoodMutation>;
export type AddPostSessionMoodMutationResult = ApolloReactCommon.MutationResult<AddPostSessionMoodMutation>;
export type AddPostSessionMoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPostSessionMoodMutation, AddPostSessionMoodMutationVariables>;
export const AddPreSessionMoodDocument = gql`
    mutation addPreSessionMood($chatReqId: ID!, $value: Int!, $description: String) {
  addPreSessionMood(chatReqId: $chatReqId, value: $value, description: $description) {
    ... on ChatRequest {
      id
      state
      createdAt
      selectedTopic {
        id
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;
export type AddPreSessionMoodMutationFn = ApolloReactCommon.MutationFunction<AddPreSessionMoodMutation, AddPreSessionMoodMutationVariables>;

/**
 * __useAddPreSessionMoodMutation__
 *
 * To run a mutation, you first call `useAddPreSessionMoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPreSessionMoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPreSessionMoodMutation, { data, loading, error }] = useAddPreSessionMoodMutation({
 *   variables: {
 *      chatReqId: // value for 'chatReqId'
 *      value: // value for 'value'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddPreSessionMoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPreSessionMoodMutation, AddPreSessionMoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPreSessionMoodMutation, AddPreSessionMoodMutationVariables>(AddPreSessionMoodDocument, baseOptions);
      }
export type AddPreSessionMoodMutationHookResult = ReturnType<typeof useAddPreSessionMoodMutation>;
export type AddPreSessionMoodMutationResult = ApolloReactCommon.MutationResult<AddPreSessionMoodMutation>;
export type AddPreSessionMoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPreSessionMoodMutation, AddPreSessionMoodMutationVariables>;
export const BlockListenerDocument = gql`
    mutation blockListener($listenerId: ID!) {
  blockListener(listenerId: $listenerId) {
    ... on User {
      id
      blockedListeners {
        id
        isBlocked
      }
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type BlockListenerMutationFn = ApolloReactCommon.MutationFunction<BlockListenerMutation, BlockListenerMutationVariables>;

/**
 * __useBlockListenerMutation__
 *
 * To run a mutation, you first call `useBlockListenerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockListenerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockListenerMutation, { data, loading, error }] = useBlockListenerMutation({
 *   variables: {
 *      listenerId: // value for 'listenerId'
 *   },
 * });
 */
export function useBlockListenerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockListenerMutation, BlockListenerMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockListenerMutation, BlockListenerMutationVariables>(BlockListenerDocument, baseOptions);
      }
export type BlockListenerMutationHookResult = ReturnType<typeof useBlockListenerMutation>;
export type BlockListenerMutationResult = ApolloReactCommon.MutationResult<BlockListenerMutation>;
export type BlockListenerMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockListenerMutation, BlockListenerMutationVariables>;
export const CancelMyRequestDocument = gql`
    mutation cancelMyRequest {
  cancelMyRequest {
    ... on ChatRequest {
      id
      state
      createdAt
      selectedTopic {
        id
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;
export type CancelMyRequestMutationFn = ApolloReactCommon.MutationFunction<CancelMyRequestMutation, CancelMyRequestMutationVariables>;

/**
 * __useCancelMyRequestMutation__
 *
 * To run a mutation, you first call `useCancelMyRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMyRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMyRequestMutation, { data, loading, error }] = useCancelMyRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelMyRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelMyRequestMutation, CancelMyRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelMyRequestMutation, CancelMyRequestMutationVariables>(CancelMyRequestDocument, baseOptions);
      }
export type CancelMyRequestMutationHookResult = ReturnType<typeof useCancelMyRequestMutation>;
export type CancelMyRequestMutationResult = ApolloReactCommon.MutationResult<CancelMyRequestMutation>;
export type CancelMyRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelMyRequestMutation, CancelMyRequestMutationVariables>;
export const ChangeListenerNameDocument = gql`
    mutation changeListenerName($id: ID!, $firstName: String!, $lastName: String!) {
  changeListenerName(id: $id, firstName: $firstName, lastName: $lastName) {
    ... on Listener {
      id
      firstName
      lastName
    }
  }
}
    `;
export type ChangeListenerNameMutationFn = ApolloReactCommon.MutationFunction<ChangeListenerNameMutation, ChangeListenerNameMutationVariables>;

/**
 * __useChangeListenerNameMutation__
 *
 * To run a mutation, you first call `useChangeListenerNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeListenerNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeListenerNameMutation, { data, loading, error }] = useChangeListenerNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useChangeListenerNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeListenerNameMutation, ChangeListenerNameMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeListenerNameMutation, ChangeListenerNameMutationVariables>(ChangeListenerNameDocument, baseOptions);
      }
export type ChangeListenerNameMutationHookResult = ReturnType<typeof useChangeListenerNameMutation>;
export type ChangeListenerNameMutationResult = ApolloReactCommon.MutationResult<ChangeListenerNameMutation>;
export type ChangeListenerNameMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeListenerNameMutation, ChangeListenerNameMutationVariables>;
export const ConfigureNotificationOptionsDocument = gql`
    mutation configureNotificationOptions($isChatAcceptanceNotificationOn: Boolean!, $isChatMessageNotificationOn: Boolean!) {
  configureNotificationOptions(isChatAcceptanceNotificationOn: $isChatAcceptanceNotificationOn, isChatMessageNotificationOn: $isChatMessageNotificationOn) {
    ... on User {
      id
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type ConfigureNotificationOptionsMutationFn = ApolloReactCommon.MutationFunction<ConfigureNotificationOptionsMutation, ConfigureNotificationOptionsMutationVariables>;

/**
 * __useConfigureNotificationOptionsMutation__
 *
 * To run a mutation, you first call `useConfigureNotificationOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureNotificationOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureNotificationOptionsMutation, { data, loading, error }] = useConfigureNotificationOptionsMutation({
 *   variables: {
 *      isChatAcceptanceNotificationOn: // value for 'isChatAcceptanceNotificationOn'
 *      isChatMessageNotificationOn: // value for 'isChatMessageNotificationOn'
 *   },
 * });
 */
export function useConfigureNotificationOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfigureNotificationOptionsMutation, ConfigureNotificationOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfigureNotificationOptionsMutation, ConfigureNotificationOptionsMutationVariables>(ConfigureNotificationOptionsDocument, baseOptions);
      }
export type ConfigureNotificationOptionsMutationHookResult = ReturnType<typeof useConfigureNotificationOptionsMutation>;
export type ConfigureNotificationOptionsMutationResult = ApolloReactCommon.MutationResult<ConfigureNotificationOptionsMutation>;
export type ConfigureNotificationOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfigureNotificationOptionsMutation, ConfigureNotificationOptionsMutationVariables>;
export const CreateDedicatedChatDocument = gql`
    mutation createDedicatedChat($memberId: ID!, $message: String!) {
  createDedicatedChat(memberId: $memberId, message: $message) {
    ... on DedicatedChat {
      id
      state
      listener {
        id
      }
      member {
        id
        alias
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;
export type CreateDedicatedChatMutationFn = ApolloReactCommon.MutationFunction<CreateDedicatedChatMutation, CreateDedicatedChatMutationVariables>;

/**
 * __useCreateDedicatedChatMutation__
 *
 * To run a mutation, you first call `useCreateDedicatedChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDedicatedChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDedicatedChatMutation, { data, loading, error }] = useCreateDedicatedChatMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateDedicatedChatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDedicatedChatMutation, CreateDedicatedChatMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDedicatedChatMutation, CreateDedicatedChatMutationVariables>(CreateDedicatedChatDocument, baseOptions);
      }
export type CreateDedicatedChatMutationHookResult = ReturnType<typeof useCreateDedicatedChatMutation>;
export type CreateDedicatedChatMutationResult = ApolloReactCommon.MutationResult<CreateDedicatedChatMutation>;
export type CreateDedicatedChatMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDedicatedChatMutation, CreateDedicatedChatMutationVariables>;
export const CreateListenerDocument = gql`
    mutation createListener($input: CreateListenerInput!) {
  createListener(input: $input) {
    ... on Listener {
      id
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
  }
}
    `;
export type CreateListenerMutationFn = ApolloReactCommon.MutationFunction<CreateListenerMutation, CreateListenerMutationVariables>;

/**
 * __useCreateListenerMutation__
 *
 * To run a mutation, you first call `useCreateListenerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListenerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListenerMutation, { data, loading, error }] = useCreateListenerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListenerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateListenerMutation, CreateListenerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateListenerMutation, CreateListenerMutationVariables>(CreateListenerDocument, baseOptions);
      }
export type CreateListenerMutationHookResult = ReturnType<typeof useCreateListenerMutation>;
export type CreateListenerMutationResult = ApolloReactCommon.MutationResult<CreateListenerMutation>;
export type CreateListenerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateListenerMutation, CreateListenerMutationVariables>;
export const CreateReflectionDocument = gql`
    mutation createReflection($text: String!, $mood: Int!, $chatId: String, $title: String!) {
  createReflection(text: $text, mood: $mood, chatId: $chatId, title: $title) {
    ... on Reflection {
      id
      text
      userId
      mood
      chatId
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type CreateReflectionMutationFn = ApolloReactCommon.MutationFunction<CreateReflectionMutation, CreateReflectionMutationVariables>;

/**
 * __useCreateReflectionMutation__
 *
 * To run a mutation, you first call `useCreateReflectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReflectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReflectionMutation, { data, loading, error }] = useCreateReflectionMutation({
 *   variables: {
 *      text: // value for 'text'
 *      mood: // value for 'mood'
 *      chatId: // value for 'chatId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateReflectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReflectionMutation, CreateReflectionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReflectionMutation, CreateReflectionMutationVariables>(CreateReflectionDocument, baseOptions);
      }
export type CreateReflectionMutationHookResult = ReturnType<typeof useCreateReflectionMutation>;
export type CreateReflectionMutationResult = ApolloReactCommon.MutationResult<CreateReflectionMutation>;
export type CreateReflectionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReflectionMutation, CreateReflectionMutationVariables>;
export const CreateRequestDocument = gql`
    mutation createRequest($input: CreateRequestInput!) {
  createRequest(input: $input) {
    ... on ChatRequest {
      id
      state
      selectedTopic {
        id
      }
      member {
        id
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
    ... on DoNotHavePermission {
      message
    }
    ... on RateLimit {
      message
    }
  }
}
    `;
export type CreateRequestMutationFn = ApolloReactCommon.MutationFunction<CreateRequestMutation, CreateRequestMutationVariables>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRequestMutation, CreateRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRequestMutation, CreateRequestMutationVariables>(CreateRequestDocument, baseOptions);
      }
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = ApolloReactCommon.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const DeleteMyAccountDocument = gql`
    mutation deleteMyAccount {
  deleteMyAccount
}
    `;
export type DeleteMyAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteMyAccountMutation, DeleteMyAccountMutationVariables>;

/**
 * __useDeleteMyAccountMutation__
 *
 * To run a mutation, you first call `useDeleteMyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyAccountMutation, { data, loading, error }] = useDeleteMyAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMyAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMyAccountMutation, DeleteMyAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMyAccountMutation, DeleteMyAccountMutationVariables>(DeleteMyAccountDocument, baseOptions);
      }
export type DeleteMyAccountMutationHookResult = ReturnType<typeof useDeleteMyAccountMutation>;
export type DeleteMyAccountMutationResult = ApolloReactCommon.MutationResult<DeleteMyAccountMutation>;
export type DeleteMyAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMyAccountMutation, DeleteMyAccountMutationVariables>;
export const EditListenerInfoDocument = gql`
    mutation editListenerInfo($input: EditListenerInput!) {
  editListenerInfo(input: $input) {
    ... on Listener {
      id
      bio
      firstName
      lastName
      fullName
      age
      gender
    }
  }
}
    `;
export type EditListenerInfoMutationFn = ApolloReactCommon.MutationFunction<EditListenerInfoMutation, EditListenerInfoMutationVariables>;

/**
 * __useEditListenerInfoMutation__
 *
 * To run a mutation, you first call `useEditListenerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditListenerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editListenerInfoMutation, { data, loading, error }] = useEditListenerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditListenerInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditListenerInfoMutation, EditListenerInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<EditListenerInfoMutation, EditListenerInfoMutationVariables>(EditListenerInfoDocument, baseOptions);
      }
export type EditListenerInfoMutationHookResult = ReturnType<typeof useEditListenerInfoMutation>;
export type EditListenerInfoMutationResult = ApolloReactCommon.MutationResult<EditListenerInfoMutation>;
export type EditListenerInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<EditListenerInfoMutation, EditListenerInfoMutationVariables>;
export const EndDedicatedChatDocument = gql`
    mutation endDedicatedChat($chatId: ID!, $reason: String!) {
  endDedicatedChat(chatId: $chatId, reason: $reason) {
    ... on DedicatedChat {
      id
      state
    }
  }
}
    `;
export type EndDedicatedChatMutationFn = ApolloReactCommon.MutationFunction<EndDedicatedChatMutation, EndDedicatedChatMutationVariables>;

/**
 * __useEndDedicatedChatMutation__
 *
 * To run a mutation, you first call `useEndDedicatedChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndDedicatedChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endDedicatedChatMutation, { data, loading, error }] = useEndDedicatedChatMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useEndDedicatedChatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EndDedicatedChatMutation, EndDedicatedChatMutationVariables>) {
        return ApolloReactHooks.useMutation<EndDedicatedChatMutation, EndDedicatedChatMutationVariables>(EndDedicatedChatDocument, baseOptions);
      }
export type EndDedicatedChatMutationHookResult = ReturnType<typeof useEndDedicatedChatMutation>;
export type EndDedicatedChatMutationResult = ApolloReactCommon.MutationResult<EndDedicatedChatMutation>;
export type EndDedicatedChatMutationOptions = ApolloReactCommon.BaseMutationOptions<EndDedicatedChatMutation, EndDedicatedChatMutationVariables>;
export const EndMyChatDocument = gql`
    mutation endMyChat($id: ID!) {
  endMyChat(id: $id) {
    ... on Chat {
      id
      state
      startedAt
      endedAt
      chatRequest {
        id
        state
        selectedTopic {
          id
        }
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on DoNotHavePermission {
      message
    }
    ... on InvalidRequestState {
      message
    }
  }
}
    `;
export type EndMyChatMutationFn = ApolloReactCommon.MutationFunction<EndMyChatMutation, EndMyChatMutationVariables>;

/**
 * __useEndMyChatMutation__
 *
 * To run a mutation, you first call `useEndMyChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndMyChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endMyChatMutation, { data, loading, error }] = useEndMyChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEndMyChatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EndMyChatMutation, EndMyChatMutationVariables>) {
        return ApolloReactHooks.useMutation<EndMyChatMutation, EndMyChatMutationVariables>(EndMyChatDocument, baseOptions);
      }
export type EndMyChatMutationHookResult = ReturnType<typeof useEndMyChatMutation>;
export type EndMyChatMutationResult = ApolloReactCommon.MutationResult<EndMyChatMutation>;
export type EndMyChatMutationOptions = ApolloReactCommon.BaseMutationOptions<EndMyChatMutation, EndMyChatMutationVariables>;
export const GiveFeedbackDocument = gql`
    mutation giveFeedback($feedback: String!, $deviceInformation: JSON) {
  giveFeedback(feedback: $feedback, deviceInformation: $deviceInformation)
}
    `;
export type GiveFeedbackMutationFn = ApolloReactCommon.MutationFunction<GiveFeedbackMutation, GiveFeedbackMutationVariables>;

/**
 * __useGiveFeedbackMutation__
 *
 * To run a mutation, you first call `useGiveFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveFeedbackMutation, { data, loading, error }] = useGiveFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *      deviceInformation: // value for 'deviceInformation'
 *   },
 * });
 */
export function useGiveFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GiveFeedbackMutation, GiveFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<GiveFeedbackMutation, GiveFeedbackMutationVariables>(GiveFeedbackDocument, baseOptions);
      }
export type GiveFeedbackMutationHookResult = ReturnType<typeof useGiveFeedbackMutation>;
export type GiveFeedbackMutationResult = ApolloReactCommon.MutationResult<GiveFeedbackMutation>;
export type GiveFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<GiveFeedbackMutation, GiveFeedbackMutationVariables>;
export const JoinChatDocument = gql`
    mutation joinChat($id: ID!) {
  joinChat(id: $id) {
    ... on ChatRequest {
      id
      selectedTopic {
        id
        name
      }
      chat {
        id
        state
      }
      state
      member {
        alias
        id
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;
export type JoinChatMutationFn = ApolloReactCommon.MutationFunction<JoinChatMutation, JoinChatMutationVariables>;

/**
 * __useJoinChatMutation__
 *
 * To run a mutation, you first call `useJoinChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinChatMutation, { data, loading, error }] = useJoinChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinChatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinChatMutation, JoinChatMutationVariables>) {
        return ApolloReactHooks.useMutation<JoinChatMutation, JoinChatMutationVariables>(JoinChatDocument, baseOptions);
      }
export type JoinChatMutationHookResult = ReturnType<typeof useJoinChatMutation>;
export type JoinChatMutationResult = ApolloReactCommon.MutationResult<JoinChatMutation>;
export type JoinChatMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinChatMutation, JoinChatMutationVariables>;
export const JoinChatAsMemberDocument = gql`
    mutation joinChatAsMember($id: ID!) {
  joinChatAsMember(id: $id) {
    ... on ChatRequest {
      id
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
    ... on InvalidRequestState {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;
export type JoinChatAsMemberMutationFn = ApolloReactCommon.MutationFunction<JoinChatAsMemberMutation, JoinChatAsMemberMutationVariables>;

/**
 * __useJoinChatAsMemberMutation__
 *
 * To run a mutation, you first call `useJoinChatAsMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinChatAsMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinChatAsMemberMutation, { data, loading, error }] = useJoinChatAsMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinChatAsMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinChatAsMemberMutation, JoinChatAsMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<JoinChatAsMemberMutation, JoinChatAsMemberMutationVariables>(JoinChatAsMemberDocument, baseOptions);
      }
export type JoinChatAsMemberMutationHookResult = ReturnType<typeof useJoinChatAsMemberMutation>;
export type JoinChatAsMemberMutationResult = ApolloReactCommon.MutationResult<JoinChatAsMemberMutation>;
export type JoinChatAsMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinChatAsMemberMutation, JoinChatAsMemberMutationVariables>;
export const LeaveReportDocument = gql`
    mutation leaveReport($id: ID!, $reason: String!, $categories: [ReportCategory!]) {
  leaveReport(id: $id, reason: $reason, categories: $categories) {
    ... on Chat {
      id
      rating
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoNotHavePermission {
      message
    }
    ... on DoesNotExist {
      message
    }
  }
}
    `;
export type LeaveReportMutationFn = ApolloReactCommon.MutationFunction<LeaveReportMutation, LeaveReportMutationVariables>;

/**
 * __useLeaveReportMutation__
 *
 * To run a mutation, you first call `useLeaveReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveReportMutation, { data, loading, error }] = useLeaveReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useLeaveReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveReportMutation, LeaveReportMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveReportMutation, LeaveReportMutationVariables>(LeaveReportDocument, baseOptions);
      }
export type LeaveReportMutationHookResult = ReturnType<typeof useLeaveReportMutation>;
export type LeaveReportMutationResult = ApolloReactCommon.MutationResult<LeaveReportMutation>;
export type LeaveReportMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveReportMutation, LeaveReportMutationVariables>;
export const LeaveReviewDocument = gql`
    mutation leaveReview($id: ID!, $rating: Int!, $reason: String) {
  leaveReview(id: $id, rating: $rating, reason: $reason) {
    ... on Chat {
      id
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoNotHavePermission {
      message
    }
    ... on DoesNotExist {
      message
    }
  }
}
    `;
export type LeaveReviewMutationFn = ApolloReactCommon.MutationFunction<LeaveReviewMutation, LeaveReviewMutationVariables>;

/**
 * __useLeaveReviewMutation__
 *
 * To run a mutation, you first call `useLeaveReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveReviewMutation, { data, loading, error }] = useLeaveReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rating: // value for 'rating'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useLeaveReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveReviewMutation, LeaveReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveReviewMutation, LeaveReviewMutationVariables>(LeaveReviewDocument, baseOptions);
      }
export type LeaveReviewMutationHookResult = ReturnType<typeof useLeaveReviewMutation>;
export type LeaveReviewMutationResult = ApolloReactCommon.MutationResult<LeaveReviewMutation>;
export type LeaveReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveReviewMutation, LeaveReviewMutationVariables>;
export const RateAndroidDocument = gql`
    mutation rateAndroid {
  rateAndroid {
    ... on User {
      id
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type RateAndroidMutationFn = ApolloReactCommon.MutationFunction<RateAndroidMutation, RateAndroidMutationVariables>;

/**
 * __useRateAndroidMutation__
 *
 * To run a mutation, you first call `useRateAndroidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateAndroidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateAndroidMutation, { data, loading, error }] = useRateAndroidMutation({
 *   variables: {
 *   },
 * });
 */
export function useRateAndroidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateAndroidMutation, RateAndroidMutationVariables>) {
        return ApolloReactHooks.useMutation<RateAndroidMutation, RateAndroidMutationVariables>(RateAndroidDocument, baseOptions);
      }
export type RateAndroidMutationHookResult = ReturnType<typeof useRateAndroidMutation>;
export type RateAndroidMutationResult = ApolloReactCommon.MutationResult<RateAndroidMutation>;
export type RateAndroidMutationOptions = ApolloReactCommon.BaseMutationOptions<RateAndroidMutation, RateAndroidMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    ... on User {
      id
    }
    ... on AlreadyExists {
      message
    }
  }
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RemoveDeviceTokenDocument = gql`
    mutation removeDeviceToken($token: String!) {
  removeDeviceToken(token: $token) {
    ... on User {
      id
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type RemoveDeviceTokenMutationFn = ApolloReactCommon.MutationFunction<RemoveDeviceTokenMutation, RemoveDeviceTokenMutationVariables>;

/**
 * __useRemoveDeviceTokenMutation__
 *
 * To run a mutation, you first call `useRemoveDeviceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeviceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeviceTokenMutation, { data, loading, error }] = useRemoveDeviceTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRemoveDeviceTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDeviceTokenMutation, RemoveDeviceTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveDeviceTokenMutation, RemoveDeviceTokenMutationVariables>(RemoveDeviceTokenDocument, baseOptions);
      }
export type RemoveDeviceTokenMutationHookResult = ReturnType<typeof useRemoveDeviceTokenMutation>;
export type RemoveDeviceTokenMutationResult = ApolloReactCommon.MutationResult<RemoveDeviceTokenMutation>;
export type RemoveDeviceTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDeviceTokenMutation, RemoveDeviceTokenMutationVariables>;
export const RemoveMessageFromListenersGroupChatDocument = gql`
    mutation removeMessageFromListenersGroupChat($id: ID!) {
  removeMessageFromListenersGroupChat(id: $id)
}
    `;
export type RemoveMessageFromListenersGroupChatMutationFn = ApolloReactCommon.MutationFunction<RemoveMessageFromListenersGroupChatMutation, RemoveMessageFromListenersGroupChatMutationVariables>;

/**
 * __useRemoveMessageFromListenersGroupChatMutation__
 *
 * To run a mutation, you first call `useRemoveMessageFromListenersGroupChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMessageFromListenersGroupChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMessageFromListenersGroupChatMutation, { data, loading, error }] = useRemoveMessageFromListenersGroupChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMessageFromListenersGroupChatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMessageFromListenersGroupChatMutation, RemoveMessageFromListenersGroupChatMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMessageFromListenersGroupChatMutation, RemoveMessageFromListenersGroupChatMutationVariables>(RemoveMessageFromListenersGroupChatDocument, baseOptions);
      }
export type RemoveMessageFromListenersGroupChatMutationHookResult = ReturnType<typeof useRemoveMessageFromListenersGroupChatMutation>;
export type RemoveMessageFromListenersGroupChatMutationResult = ApolloReactCommon.MutationResult<RemoveMessageFromListenersGroupChatMutation>;
export type RemoveMessageFromListenersGroupChatMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMessageFromListenersGroupChatMutation, RemoveMessageFromListenersGroupChatMutationVariables>;
export const ReportDedicatedChatDocument = gql`
    mutation reportDedicatedChat($chatId: ID!, $reason: String!, $category: ReportCategory) {
  reportDedicatedChat(chatId: $chatId, reason: $reason, category: $category) {
    ... on DedicatedChat {
      id
      state
    }
  }
}
    `;
export type ReportDedicatedChatMutationFn = ApolloReactCommon.MutationFunction<ReportDedicatedChatMutation, ReportDedicatedChatMutationVariables>;

/**
 * __useReportDedicatedChatMutation__
 *
 * To run a mutation, you first call `useReportDedicatedChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportDedicatedChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportDedicatedChatMutation, { data, loading, error }] = useReportDedicatedChatMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      reason: // value for 'reason'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useReportDedicatedChatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportDedicatedChatMutation, ReportDedicatedChatMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportDedicatedChatMutation, ReportDedicatedChatMutationVariables>(ReportDedicatedChatDocument, baseOptions);
      }
export type ReportDedicatedChatMutationHookResult = ReturnType<typeof useReportDedicatedChatMutation>;
export type ReportDedicatedChatMutationResult = ApolloReactCommon.MutationResult<ReportDedicatedChatMutation>;
export type ReportDedicatedChatMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportDedicatedChatMutation, ReportDedicatedChatMutationVariables>;
export const SetAliasDocument = gql`
    mutation setAlias($alias: String!) {
  setAlias(alias: $alias) {
    ... on User {
      id
      alias
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
  }
}
    `;
export type SetAliasMutationFn = ApolloReactCommon.MutationFunction<SetAliasMutation, SetAliasMutationVariables>;

/**
 * __useSetAliasMutation__
 *
 * To run a mutation, you first call `useSetAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAliasMutation, { data, loading, error }] = useSetAliasMutation({
 *   variables: {
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useSetAliasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetAliasMutation, SetAliasMutationVariables>) {
        return ApolloReactHooks.useMutation<SetAliasMutation, SetAliasMutationVariables>(SetAliasDocument, baseOptions);
      }
export type SetAliasMutationHookResult = ReturnType<typeof useSetAliasMutation>;
export type SetAliasMutationResult = ApolloReactCommon.MutationResult<SetAliasMutation>;
export type SetAliasMutationOptions = ApolloReactCommon.BaseMutationOptions<SetAliasMutation, SetAliasMutationVariables>;
export const SetCurrentGroupChatTopicDocument = gql`
    mutation setCurrentGroupChatTopic($topic: String!, $description: String!) {
  setCurrentGroupChatTopic(topic: $topic, description: $description) {
    topic
    description
  }
}
    `;
export type SetCurrentGroupChatTopicMutationFn = ApolloReactCommon.MutationFunction<SetCurrentGroupChatTopicMutation, SetCurrentGroupChatTopicMutationVariables>;

/**
 * __useSetCurrentGroupChatTopicMutation__
 *
 * To run a mutation, you first call `useSetCurrentGroupChatTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentGroupChatTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentGroupChatTopicMutation, { data, loading, error }] = useSetCurrentGroupChatTopicMutation({
 *   variables: {
 *      topic: // value for 'topic'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSetCurrentGroupChatTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCurrentGroupChatTopicMutation, SetCurrentGroupChatTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCurrentGroupChatTopicMutation, SetCurrentGroupChatTopicMutationVariables>(SetCurrentGroupChatTopicDocument, baseOptions);
      }
export type SetCurrentGroupChatTopicMutationHookResult = ReturnType<typeof useSetCurrentGroupChatTopicMutation>;
export type SetCurrentGroupChatTopicMutationResult = ApolloReactCommon.MutationResult<SetCurrentGroupChatTopicMutation>;
export type SetCurrentGroupChatTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCurrentGroupChatTopicMutation, SetCurrentGroupChatTopicMutationVariables>;
export const SetEndChatEarlyReasonsDocument = gql`
    mutation setEndChatEarlyReasons($id: ID!, $reasons: [EndEarlyReason!]!) {
  setEndChatEarlyReasons(id: $id, reasons: $reasons) {
    ... on Chat {
      id
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type SetEndChatEarlyReasonsMutationFn = ApolloReactCommon.MutationFunction<SetEndChatEarlyReasonsMutation, SetEndChatEarlyReasonsMutationVariables>;

/**
 * __useSetEndChatEarlyReasonsMutation__
 *
 * To run a mutation, you first call `useSetEndChatEarlyReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEndChatEarlyReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEndChatEarlyReasonsMutation, { data, loading, error }] = useSetEndChatEarlyReasonsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useSetEndChatEarlyReasonsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetEndChatEarlyReasonsMutation, SetEndChatEarlyReasonsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetEndChatEarlyReasonsMutation, SetEndChatEarlyReasonsMutationVariables>(SetEndChatEarlyReasonsDocument, baseOptions);
      }
export type SetEndChatEarlyReasonsMutationHookResult = ReturnType<typeof useSetEndChatEarlyReasonsMutation>;
export type SetEndChatEarlyReasonsMutationResult = ApolloReactCommon.MutationResult<SetEndChatEarlyReasonsMutation>;
export type SetEndChatEarlyReasonsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetEndChatEarlyReasonsMutation, SetEndChatEarlyReasonsMutationVariables>;
export const SetLanguagesDocument = gql`
    mutation setLanguages($languages: [Int!]!) {
  setLanguages(languageIds: $languages) {
    id
    languages {
      id
      name
      label
    }
  }
}
    `;
export type SetLanguagesMutationFn = ApolloReactCommon.MutationFunction<SetLanguagesMutation, SetLanguagesMutationVariables>;

/**
 * __useSetLanguagesMutation__
 *
 * To run a mutation, you first call `useSetLanguagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLanguagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLanguagesMutation, { data, loading, error }] = useSetLanguagesMutation({
 *   variables: {
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useSetLanguagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetLanguagesMutation, SetLanguagesMutationVariables>) {
        return ApolloReactHooks.useMutation<SetLanguagesMutation, SetLanguagesMutationVariables>(SetLanguagesDocument, baseOptions);
      }
export type SetLanguagesMutationHookResult = ReturnType<typeof useSetLanguagesMutation>;
export type SetLanguagesMutationResult = ApolloReactCommon.MutationResult<SetLanguagesMutation>;
export type SetLanguagesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetLanguagesMutation, SetLanguagesMutationVariables>;
export const SetListenerNotificationsEnabledDocument = gql`
    mutation setListenerNotificationsEnabled($value: Boolean!) {
  setListenerNotificationsEnabled(value: $value) {
    ... on Listener {
      id
      notificationsDisabled
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type SetListenerNotificationsEnabledMutationFn = ApolloReactCommon.MutationFunction<SetListenerNotificationsEnabledMutation, SetListenerNotificationsEnabledMutationVariables>;

/**
 * __useSetListenerNotificationsEnabledMutation__
 *
 * To run a mutation, you first call `useSetListenerNotificationsEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetListenerNotificationsEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setListenerNotificationsEnabledMutation, { data, loading, error }] = useSetListenerNotificationsEnabledMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetListenerNotificationsEnabledMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetListenerNotificationsEnabledMutation, SetListenerNotificationsEnabledMutationVariables>) {
        return ApolloReactHooks.useMutation<SetListenerNotificationsEnabledMutation, SetListenerNotificationsEnabledMutationVariables>(SetListenerNotificationsEnabledDocument, baseOptions);
      }
export type SetListenerNotificationsEnabledMutationHookResult = ReturnType<typeof useSetListenerNotificationsEnabledMutation>;
export type SetListenerNotificationsEnabledMutationResult = ApolloReactCommon.MutationResult<SetListenerNotificationsEnabledMutation>;
export type SetListenerNotificationsEnabledMutationOptions = ApolloReactCommon.BaseMutationOptions<SetListenerNotificationsEnabledMutation, SetListenerNotificationsEnabledMutationVariables>;
export const SetListenerPreferencesDocument = gql`
    mutation setListenerPreferences($ageRange: [Int!]!) {
  setListenerPreferences(ageRangeIds: $ageRange) {
    ... on User {
      id
      listenerPreferences {
        id
        ageRange
      }
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type SetListenerPreferencesMutationFn = ApolloReactCommon.MutationFunction<SetListenerPreferencesMutation, SetListenerPreferencesMutationVariables>;

/**
 * __useSetListenerPreferencesMutation__
 *
 * To run a mutation, you first call `useSetListenerPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetListenerPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setListenerPreferencesMutation, { data, loading, error }] = useSetListenerPreferencesMutation({
 *   variables: {
 *      ageRange: // value for 'ageRange'
 *   },
 * });
 */
export function useSetListenerPreferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetListenerPreferencesMutation, SetListenerPreferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<SetListenerPreferencesMutation, SetListenerPreferencesMutationVariables>(SetListenerPreferencesDocument, baseOptions);
      }
export type SetListenerPreferencesMutationHookResult = ReturnType<typeof useSetListenerPreferencesMutation>;
export type SetListenerPreferencesMutationResult = ApolloReactCommon.MutationResult<SetListenerPreferencesMutation>;
export type SetListenerPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetListenerPreferencesMutation, SetListenerPreferencesMutationVariables>;
export const SetListenerTopicsDocument = gql`
    mutation setListenerTopics($topicIds: [Int!]!) {
  setListenerTopics(topicIds: $topicIds) {
    ... on Listener {
      id
      topics {
        id
      }
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type SetListenerTopicsMutationFn = ApolloReactCommon.MutationFunction<SetListenerTopicsMutation, SetListenerTopicsMutationVariables>;

/**
 * __useSetListenerTopicsMutation__
 *
 * To run a mutation, you first call `useSetListenerTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetListenerTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setListenerTopicsMutation, { data, loading, error }] = useSetListenerTopicsMutation({
 *   variables: {
 *      topicIds: // value for 'topicIds'
 *   },
 * });
 */
export function useSetListenerTopicsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetListenerTopicsMutation, SetListenerTopicsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetListenerTopicsMutation, SetListenerTopicsMutationVariables>(SetListenerTopicsDocument, baseOptions);
      }
export type SetListenerTopicsMutationHookResult = ReturnType<typeof useSetListenerTopicsMutation>;
export type SetListenerTopicsMutationResult = ApolloReactCommon.MutationResult<SetListenerTopicsMutation>;
export type SetListenerTopicsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetListenerTopicsMutation, SetListenerTopicsMutationVariables>;
export const SetUsageReasonsDocument = gql`
    mutation setUsageReasons($reasons: [Int!]!) {
  setUsageReasons(reasonIds: $reasons) {
    ... on User {
      id
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;
export type SetUsageReasonsMutationFn = ApolloReactCommon.MutationFunction<SetUsageReasonsMutation, SetUsageReasonsMutationVariables>;

/**
 * __useSetUsageReasonsMutation__
 *
 * To run a mutation, you first call `useSetUsageReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUsageReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUsageReasonsMutation, { data, loading, error }] = useSetUsageReasonsMutation({
 *   variables: {
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useSetUsageReasonsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetUsageReasonsMutation, SetUsageReasonsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetUsageReasonsMutation, SetUsageReasonsMutationVariables>(SetUsageReasonsDocument, baseOptions);
      }
export type SetUsageReasonsMutationHookResult = ReturnType<typeof useSetUsageReasonsMutation>;
export type SetUsageReasonsMutationResult = ApolloReactCommon.MutationResult<SetUsageReasonsMutation>;
export type SetUsageReasonsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetUsageReasonsMutation, SetUsageReasonsMutationVariables>;
export const ToggleUserBanDocument = gql`
    mutation toggleUserBan($id: ID!) {
  toggleUserBan(id: $id) {
    ... on User {
      id
      isSuspended
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
  }
}
    `;
export type ToggleUserBanMutationFn = ApolloReactCommon.MutationFunction<ToggleUserBanMutation, ToggleUserBanMutationVariables>;

/**
 * __useToggleUserBanMutation__
 *
 * To run a mutation, you first call `useToggleUserBanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserBanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserBanMutation, { data, loading, error }] = useToggleUserBanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleUserBanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleUserBanMutation, ToggleUserBanMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleUserBanMutation, ToggleUserBanMutationVariables>(ToggleUserBanDocument, baseOptions);
      }
export type ToggleUserBanMutationHookResult = ReturnType<typeof useToggleUserBanMutation>;
export type ToggleUserBanMutationResult = ApolloReactCommon.MutationResult<ToggleUserBanMutation>;
export type ToggleUserBanMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleUserBanMutation, ToggleUserBanMutationVariables>;
export const UnblockListenerDocument = gql`
    mutation unblockListener($listenerId: ID!) {
  unblockListener(listenerId: $listenerId) {
    ... on User {
      id
      blockedListeners {
        id
        isBlocked
      }
    }
  }
}
    `;
export type UnblockListenerMutationFn = ApolloReactCommon.MutationFunction<UnblockListenerMutation, UnblockListenerMutationVariables>;

/**
 * __useUnblockListenerMutation__
 *
 * To run a mutation, you first call `useUnblockListenerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockListenerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockListenerMutation, { data, loading, error }] = useUnblockListenerMutation({
 *   variables: {
 *      listenerId: // value for 'listenerId'
 *   },
 * });
 */
export function useUnblockListenerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnblockListenerMutation, UnblockListenerMutationVariables>) {
        return ApolloReactHooks.useMutation<UnblockListenerMutation, UnblockListenerMutationVariables>(UnblockListenerDocument, baseOptions);
      }
export type UnblockListenerMutationHookResult = ReturnType<typeof useUnblockListenerMutation>;
export type UnblockListenerMutationResult = ApolloReactCommon.MutationResult<UnblockListenerMutation>;
export type UnblockListenerMutationOptions = ApolloReactCommon.BaseMutationOptions<UnblockListenerMutation, UnblockListenerMutationVariables>;
export const GetChatDocument = gql`
    query getChat($id: ID!) {
  getChatRequest(id: $id) {
    createdAt
    id
    state
    member {
      id
      alias
      isNewMember
    }
    listener {
      id
      firstName
      avatarUrl
      bio
      createdAt
      gender
      age
      sessionCount
      topics {
        name
        id
      }
    }
    chat {
      id
      startedAt
      memberJoined
    }
    selectedTopic {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, baseOptions);
      }
export function useGetChatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, baseOptions);
        }
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>;
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>;
export type GetChatQueryResult = ApolloReactCommon.QueryResult<GetChatQuery, GetChatQueryVariables>;
export const GetChatRequestDocument = gql`
    query getChatRequest($id: ID!) {
  getChatRequest(id: $id) {
    createdAt
    id
    state
    member {
      id
      alias
      isNewMember
    }
    preSessionMood {
      id
      value
      description
    }
    listener {
      id
      firstName
      avatarUrl
      bio
      createdAt
      gender
      age
      sessionCount
      topics {
        name
        id
      }
    }
    chat {
      id
      chatRoomId
      reflection
      startedAt
      review {
        rating
        reason
      }
      messages {
        id
        senderId
        createdAt
        attributes
        text
      }
    }
    selectedTopic {
      id
      name
      description
    }
    dedicatedChat {
      ... on DedicatedChat {
        id
      }
    }
    previousChatRequest {
      id
    }
  }
}
    `;

/**
 * __useGetChatRequestQuery__
 *
 * To run a query within a React component, call `useGetChatRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChatRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChatRequestQuery, GetChatRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChatRequestQuery, GetChatRequestQueryVariables>(GetChatRequestDocument, baseOptions);
      }
export function useGetChatRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChatRequestQuery, GetChatRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChatRequestQuery, GetChatRequestQueryVariables>(GetChatRequestDocument, baseOptions);
        }
export type GetChatRequestQueryHookResult = ReturnType<typeof useGetChatRequestQuery>;
export type GetChatRequestLazyQueryHookResult = ReturnType<typeof useGetChatRequestLazyQuery>;
export type GetChatRequestQueryResult = ApolloReactCommon.QueryResult<GetChatRequestQuery, GetChatRequestQueryVariables>;
export const GetCurrentChatStateDocument = gql`
    query getCurrentChatState($id: ID!) {
  getChatRequest(id: $id) {
    id
    state
  }
}
    `;

/**
 * __useGetCurrentChatStateQuery__
 *
 * To run a query within a React component, call `useGetCurrentChatStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentChatStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentChatStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentChatStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentChatStateQuery, GetCurrentChatStateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentChatStateQuery, GetCurrentChatStateQueryVariables>(GetCurrentChatStateDocument, baseOptions);
      }
export function useGetCurrentChatStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentChatStateQuery, GetCurrentChatStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentChatStateQuery, GetCurrentChatStateQueryVariables>(GetCurrentChatStateDocument, baseOptions);
        }
export type GetCurrentChatStateQueryHookResult = ReturnType<typeof useGetCurrentChatStateQuery>;
export type GetCurrentChatStateLazyQueryHookResult = ReturnType<typeof useGetCurrentChatStateLazyQuery>;
export type GetCurrentChatStateQueryResult = ApolloReactCommon.QueryResult<GetCurrentChatStateQuery, GetCurrentChatStateQueryVariables>;
export const GetCurrentGroupChatTopicDocument = gql`
    query getCurrentGroupChatTopic {
  getCurrentGroupChatTopic {
    topic
    description
  }
}
    `;

/**
 * __useGetCurrentGroupChatTopicQuery__
 *
 * To run a query within a React component, call `useGetCurrentGroupChatTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentGroupChatTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentGroupChatTopicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentGroupChatTopicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentGroupChatTopicQuery, GetCurrentGroupChatTopicQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentGroupChatTopicQuery, GetCurrentGroupChatTopicQueryVariables>(GetCurrentGroupChatTopicDocument, baseOptions);
      }
export function useGetCurrentGroupChatTopicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentGroupChatTopicQuery, GetCurrentGroupChatTopicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentGroupChatTopicQuery, GetCurrentGroupChatTopicQueryVariables>(GetCurrentGroupChatTopicDocument, baseOptions);
        }
export type GetCurrentGroupChatTopicQueryHookResult = ReturnType<typeof useGetCurrentGroupChatTopicQuery>;
export type GetCurrentGroupChatTopicLazyQueryHookResult = ReturnType<typeof useGetCurrentGroupChatTopicLazyQuery>;
export type GetCurrentGroupChatTopicQueryResult = ApolloReactCommon.QueryResult<GetCurrentGroupChatTopicQuery, GetCurrentGroupChatTopicQueryVariables>;
export const GetLanguagesDocument = gql`
    query getLanguages {
  languages {
    id
    name
    label
  }
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, baseOptions);
      }
export function useGetLanguagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, baseOptions);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesQueryResult = ApolloReactCommon.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetListenerDocument = gql`
    query getListener($id: ID!) {
  listener(id: $id) {
    id
    email
    firstName
    avatarUrl
    createdAt
    bio
    gender
    age
    sessionCount
    location {
      city
      countryCode
    }
    topics {
      name
      id
    }
    isModerator
    isBanned
  }
}
    `;

/**
 * __useGetListenerQuery__
 *
 * To run a query within a React component, call `useGetListenerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListenerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListenerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListenerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListenerQuery, GetListenerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListenerQuery, GetListenerQueryVariables>(GetListenerDocument, baseOptions);
      }
export function useGetListenerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListenerQuery, GetListenerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListenerQuery, GetListenerQueryVariables>(GetListenerDocument, baseOptions);
        }
export type GetListenerQueryHookResult = ReturnType<typeof useGetListenerQuery>;
export type GetListenerLazyQueryHookResult = ReturnType<typeof useGetListenerLazyQuery>;
export type GetListenerQueryResult = ApolloReactCommon.QueryResult<GetListenerQuery, GetListenerQueryVariables>;
export const GetListenerByUserIdDocument = gql`
    query getListenerByUserId($id: ID!) {
  getListenerByUserId(id: $id) {
    id
    firstName
    avatarUrl
    createdAt
    bio
    gender
    age
    location {
      city
      countryCode
    }
    topics {
      name
      id
    }
    isModerator
    isBanned
  }
}
    `;

/**
 * __useGetListenerByUserIdQuery__
 *
 * To run a query within a React component, call `useGetListenerByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListenerByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListenerByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListenerByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListenerByUserIdQuery, GetListenerByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListenerByUserIdQuery, GetListenerByUserIdQueryVariables>(GetListenerByUserIdDocument, baseOptions);
      }
export function useGetListenerByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListenerByUserIdQuery, GetListenerByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListenerByUserIdQuery, GetListenerByUserIdQueryVariables>(GetListenerByUserIdDocument, baseOptions);
        }
export type GetListenerByUserIdQueryHookResult = ReturnType<typeof useGetListenerByUserIdQuery>;
export type GetListenerByUserIdLazyQueryHookResult = ReturnType<typeof useGetListenerByUserIdLazyQuery>;
export type GetListenerByUserIdQueryResult = ApolloReactCommon.QueryResult<GetListenerByUserIdQuery, GetListenerByUserIdQueryVariables>;
export const GetListenerPostSessionInfoDocument = gql`
    query getListenerPostSessionInfo($id: ID!) {
  getChatRequest(id: $id) {
    id
    member {
      id
      alias
    }
  }
}
    `;

/**
 * __useGetListenerPostSessionInfoQuery__
 *
 * To run a query within a React component, call `useGetListenerPostSessionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListenerPostSessionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListenerPostSessionInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetListenerPostSessionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListenerPostSessionInfoQuery, GetListenerPostSessionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListenerPostSessionInfoQuery, GetListenerPostSessionInfoQueryVariables>(GetListenerPostSessionInfoDocument, baseOptions);
      }
export function useGetListenerPostSessionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListenerPostSessionInfoQuery, GetListenerPostSessionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListenerPostSessionInfoQuery, GetListenerPostSessionInfoQueryVariables>(GetListenerPostSessionInfoDocument, baseOptions);
        }
export type GetListenerPostSessionInfoQueryHookResult = ReturnType<typeof useGetListenerPostSessionInfoQuery>;
export type GetListenerPostSessionInfoLazyQueryHookResult = ReturnType<typeof useGetListenerPostSessionInfoLazyQuery>;
export type GetListenerPostSessionInfoQueryResult = ApolloReactCommon.QueryResult<GetListenerPostSessionInfoQuery, GetListenerPostSessionInfoQueryVariables>;
export const GetListenerStatusDocument = gql`
    query getListenerStatus {
  me {
    ... on User {
      id
      isSuspended
      listener {
        id
        isEnabled
        isBanned
      }
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;

/**
 * __useGetListenerStatusQuery__
 *
 * To run a query within a React component, call `useGetListenerStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListenerStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListenerStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListenerStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListenerStatusQuery, GetListenerStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListenerStatusQuery, GetListenerStatusQueryVariables>(GetListenerStatusDocument, baseOptions);
      }
export function useGetListenerStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListenerStatusQuery, GetListenerStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListenerStatusQuery, GetListenerStatusQueryVariables>(GetListenerStatusDocument, baseOptions);
        }
export type GetListenerStatusQueryHookResult = ReturnType<typeof useGetListenerStatusQuery>;
export type GetListenerStatusLazyQueryHookResult = ReturnType<typeof useGetListenerStatusLazyQuery>;
export type GetListenerStatusQueryResult = ApolloReactCommon.QueryResult<GetListenerStatusQuery, GetListenerStatusQueryVariables>;
export const GetListenersDataDocument = gql`
    query getListenersData {
  getListenersData {
    id
    email
    avatarUrl
    firstName
    isModerator
  }
}
    `;

/**
 * __useGetListenersDataQuery__
 *
 * To run a query within a React component, call `useGetListenersDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListenersDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListenersDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListenersDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListenersDataQuery, GetListenersDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListenersDataQuery, GetListenersDataQueryVariables>(GetListenersDataDocument, baseOptions);
      }
export function useGetListenersDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListenersDataQuery, GetListenersDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListenersDataQuery, GetListenersDataQueryVariables>(GetListenersDataDocument, baseOptions);
        }
export type GetListenersDataQueryHookResult = ReturnType<typeof useGetListenersDataQuery>;
export type GetListenersDataLazyQueryHookResult = ReturnType<typeof useGetListenersDataLazyQuery>;
export type GetListenersDataQueryResult = ApolloReactCommon.QueryResult<GetListenersDataQuery, GetListenersDataQueryVariables>;
export const GetMemberStatsDocument = gql`
    query getMemberStats {
  me {
    ... on User {
      id
      chatCount
      currentStreak
      reflectionCount
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;

/**
 * __useGetMemberStatsQuery__
 *
 * To run a query within a React component, call `useGetMemberStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMemberStatsQuery, GetMemberStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMemberStatsQuery, GetMemberStatsQueryVariables>(GetMemberStatsDocument, baseOptions);
      }
export function useGetMemberStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMemberStatsQuery, GetMemberStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMemberStatsQuery, GetMemberStatsQueryVariables>(GetMemberStatsDocument, baseOptions);
        }
export type GetMemberStatsQueryHookResult = ReturnType<typeof useGetMemberStatsQuery>;
export type GetMemberStatsLazyQueryHookResult = ReturnType<typeof useGetMemberStatsLazyQuery>;
export type GetMemberStatsQueryResult = ApolloReactCommon.QueryResult<GetMemberStatsQuery, GetMemberStatsQueryVariables>;
export const GetMyBlockedListenersDocument = gql`
    query getMyBlockedListeners {
  me {
    ... on User {
      id
      blockedListeners {
        id
        firstName
        avatarUrl
        isBlocked
      }
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;

/**
 * __useGetMyBlockedListenersQuery__
 *
 * To run a query within a React component, call `useGetMyBlockedListenersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBlockedListenersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBlockedListenersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyBlockedListenersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyBlockedListenersQuery, GetMyBlockedListenersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyBlockedListenersQuery, GetMyBlockedListenersQueryVariables>(GetMyBlockedListenersDocument, baseOptions);
      }
export function useGetMyBlockedListenersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyBlockedListenersQuery, GetMyBlockedListenersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyBlockedListenersQuery, GetMyBlockedListenersQueryVariables>(GetMyBlockedListenersDocument, baseOptions);
        }
export type GetMyBlockedListenersQueryHookResult = ReturnType<typeof useGetMyBlockedListenersQuery>;
export type GetMyBlockedListenersLazyQueryHookResult = ReturnType<typeof useGetMyBlockedListenersLazyQuery>;
export type GetMyBlockedListenersQueryResult = ApolloReactCommon.QueryResult<GetMyBlockedListenersQuery, GetMyBlockedListenersQueryVariables>;
export const GetMyDedicatedChatsDocument = gql`
    query getMyDedicatedChats {
  getMyDedicatedChats {
    id
    createdAt
    isListener
    state
    listener {
      id
      firstName
      avatarUrl
    }
    member {
      id
      alias
    }
  }
}
    `;

/**
 * __useGetMyDedicatedChatsQuery__
 *
 * To run a query within a React component, call `useGetMyDedicatedChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDedicatedChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDedicatedChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDedicatedChatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyDedicatedChatsQuery, GetMyDedicatedChatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyDedicatedChatsQuery, GetMyDedicatedChatsQueryVariables>(GetMyDedicatedChatsDocument, baseOptions);
      }
export function useGetMyDedicatedChatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyDedicatedChatsQuery, GetMyDedicatedChatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyDedicatedChatsQuery, GetMyDedicatedChatsQueryVariables>(GetMyDedicatedChatsDocument, baseOptions);
        }
export type GetMyDedicatedChatsQueryHookResult = ReturnType<typeof useGetMyDedicatedChatsQuery>;
export type GetMyDedicatedChatsLazyQueryHookResult = ReturnType<typeof useGetMyDedicatedChatsLazyQuery>;
export type GetMyDedicatedChatsQueryResult = ApolloReactCommon.QueryResult<GetMyDedicatedChatsQuery, GetMyDedicatedChatsQueryVariables>;
export const GetPastSessionsDocument = gql`
    query getPastSessions($limit: Int, $skip: Int) {
  pastSessions(limit: $limit, skip: $skip) @connection(key: "pastSessions") {
    ... on ChatRequestsResult {
      chatRequests {
        id
        createdAt
        member {
          id
          alias
        }
        selectedTopic {
          id
          name
        }
        listener {
          id
          firstName
          avatarUrl
        }
        chat {
          id
        }
      }
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;

/**
 * __useGetPastSessionsQuery__
 *
 * To run a query within a React component, call `useGetPastSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastSessionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPastSessionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPastSessionsQuery, GetPastSessionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPastSessionsQuery, GetPastSessionsQueryVariables>(GetPastSessionsDocument, baseOptions);
      }
export function useGetPastSessionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPastSessionsQuery, GetPastSessionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPastSessionsQuery, GetPastSessionsQueryVariables>(GetPastSessionsDocument, baseOptions);
        }
export type GetPastSessionsQueryHookResult = ReturnType<typeof useGetPastSessionsQuery>;
export type GetPastSessionsLazyQueryHookResult = ReturnType<typeof useGetPastSessionsLazyQuery>;
export type GetPastSessionsQueryResult = ApolloReactCommon.QueryResult<GetPastSessionsQuery, GetPastSessionsQueryVariables>;
export const GetPastSessionsByUserIdDocument = gql`
    query getPastSessionsByUserId($id: ID!) {
  getPastSessionsByUserId(id: $id) {
    ... on ChatRequestsResult {
      chatRequests {
        id
        createdAt
        member {
          id
          alias
        }
        selectedTopic {
          id
          name
        }
        listener {
          id
          firstName
          avatarUrl
        }
        chat {
          id
        }
      }
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;

/**
 * __useGetPastSessionsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetPastSessionsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastSessionsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastSessionsByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPastSessionsByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPastSessionsByUserIdQuery, GetPastSessionsByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPastSessionsByUserIdQuery, GetPastSessionsByUserIdQueryVariables>(GetPastSessionsByUserIdDocument, baseOptions);
      }
export function useGetPastSessionsByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPastSessionsByUserIdQuery, GetPastSessionsByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPastSessionsByUserIdQuery, GetPastSessionsByUserIdQueryVariables>(GetPastSessionsByUserIdDocument, baseOptions);
        }
export type GetPastSessionsByUserIdQueryHookResult = ReturnType<typeof useGetPastSessionsByUserIdQuery>;
export type GetPastSessionsByUserIdLazyQueryHookResult = ReturnType<typeof useGetPastSessionsByUserIdLazyQuery>;
export type GetPastSessionsByUserIdQueryResult = ApolloReactCommon.QueryResult<GetPastSessionsByUserIdQuery, GetPastSessionsByUserIdQueryVariables>;
export const GetPendingChatRequestsDocument = gql`
    query getPendingChatRequests {
  getPendingChatRequests {
    ... on ChatRequestsResult {
      chatRequests {
        id
        recommendedForYou
        selectedTopic {
          id
          name
        }
        member {
          id
          alias
        }
        createdAt
        languagePreference {
          id
          name
          label
        }
      }
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoNotHavePermission {
      message
    }
  }
}
    `;

/**
 * __useGetPendingChatRequestsQuery__
 *
 * To run a query within a React component, call `useGetPendingChatRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingChatRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingChatRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingChatRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPendingChatRequestsQuery, GetPendingChatRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPendingChatRequestsQuery, GetPendingChatRequestsQueryVariables>(GetPendingChatRequestsDocument, baseOptions);
      }
export function useGetPendingChatRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPendingChatRequestsQuery, GetPendingChatRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPendingChatRequestsQuery, GetPendingChatRequestsQueryVariables>(GetPendingChatRequestsDocument, baseOptions);
        }
export type GetPendingChatRequestsQueryHookResult = ReturnType<typeof useGetPendingChatRequestsQuery>;
export type GetPendingChatRequestsLazyQueryHookResult = ReturnType<typeof useGetPendingChatRequestsLazyQuery>;
export type GetPendingChatRequestsQueryResult = ApolloReactCommon.QueryResult<GetPendingChatRequestsQuery, GetPendingChatRequestsQueryVariables>;
export const GetPostSessionInfoDocument = gql`
    query getPostSessionInfo($id: ID!) {
  getChatRequest(id: $id) {
    id
    listener {
      id
      firstName
      avatarUrl
    }
    selectedTopic {
      id
      name
      sessionsToday
      description
    }
  }
}
    `;

/**
 * __useGetPostSessionInfoQuery__
 *
 * To run a query within a React component, call `useGetPostSessionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostSessionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostSessionInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostSessionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPostSessionInfoQuery, GetPostSessionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPostSessionInfoQuery, GetPostSessionInfoQueryVariables>(GetPostSessionInfoDocument, baseOptions);
      }
export function useGetPostSessionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPostSessionInfoQuery, GetPostSessionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPostSessionInfoQuery, GetPostSessionInfoQueryVariables>(GetPostSessionInfoDocument, baseOptions);
        }
export type GetPostSessionInfoQueryHookResult = ReturnType<typeof useGetPostSessionInfoQuery>;
export type GetPostSessionInfoLazyQueryHookResult = ReturnType<typeof useGetPostSessionInfoLazyQuery>;
export type GetPostSessionInfoQueryResult = ApolloReactCommon.QueryResult<GetPostSessionInfoQuery, GetPostSessionInfoQueryVariables>;
export const GetRecentTopicsDocument = gql`
    query getRecentTopics {
  me {
    ... on User {
      id
    }
    ... on NotLoggedIn {
      message
    }
  }
}
    `;

/**
 * __useGetRecentTopicsQuery__
 *
 * To run a query within a React component, call `useGetRecentTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentTopicsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecentTopicsQuery, GetRecentTopicsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecentTopicsQuery, GetRecentTopicsQueryVariables>(GetRecentTopicsDocument, baseOptions);
      }
export function useGetRecentTopicsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecentTopicsQuery, GetRecentTopicsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecentTopicsQuery, GetRecentTopicsQueryVariables>(GetRecentTopicsDocument, baseOptions);
        }
export type GetRecentTopicsQueryHookResult = ReturnType<typeof useGetRecentTopicsQuery>;
export type GetRecentTopicsLazyQueryHookResult = ReturnType<typeof useGetRecentTopicsLazyQuery>;
export type GetRecentTopicsQueryResult = ApolloReactCommon.QueryResult<GetRecentTopicsQuery, GetRecentTopicsQueryVariables>;
export const GetReflectionsDocument = gql`
    query getReflections($after: Date, $before: Date, $limit: Int) {
  getReflections(after: $after, before: $before, limit: $limit) {
    ... on Reflections {
      reflections {
        id
        text
        userId
        mood
        chatId
        createdAt
      }
      hasMore
    }
  }
}
    `;

/**
 * __useGetReflectionsQuery__
 *
 * To run a query within a React component, call `useGetReflectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReflectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReflectionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetReflectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReflectionsQuery, GetReflectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReflectionsQuery, GetReflectionsQueryVariables>(GetReflectionsDocument, baseOptions);
      }
export function useGetReflectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReflectionsQuery, GetReflectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReflectionsQuery, GetReflectionsQueryVariables>(GetReflectionsDocument, baseOptions);
        }
export type GetReflectionsQueryHookResult = ReturnType<typeof useGetReflectionsQuery>;
export type GetReflectionsLazyQueryHookResult = ReturnType<typeof useGetReflectionsLazyQuery>;
export type GetReflectionsQueryResult = ApolloReactCommon.QueryResult<GetReflectionsQuery, GetReflectionsQueryVariables>;
export const GetTopicSessionCountsDocument = gql`
    query getTopicSessionCounts {
  topics {
    id
    name
    waitingSessions
  }
}
    `;

/**
 * __useGetTopicSessionCountsQuery__
 *
 * To run a query within a React component, call `useGetTopicSessionCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicSessionCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicSessionCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopicSessionCountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTopicSessionCountsQuery, GetTopicSessionCountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTopicSessionCountsQuery, GetTopicSessionCountsQueryVariables>(GetTopicSessionCountsDocument, baseOptions);
      }
export function useGetTopicSessionCountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTopicSessionCountsQuery, GetTopicSessionCountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTopicSessionCountsQuery, GetTopicSessionCountsQueryVariables>(GetTopicSessionCountsDocument, baseOptions);
        }
export type GetTopicSessionCountsQueryHookResult = ReturnType<typeof useGetTopicSessionCountsQuery>;
export type GetTopicSessionCountsLazyQueryHookResult = ReturnType<typeof useGetTopicSessionCountsLazyQuery>;
export type GetTopicSessionCountsQueryResult = ApolloReactCommon.QueryResult<GetTopicSessionCountsQuery, GetTopicSessionCountsQueryVariables>;
export const GetUnapprovedListenersDocument = gql`
    query getUnapprovedListeners {
  getUnapprovedListeners {
    ... on Listeners {
      listeners {
        id
        email
        avatarUrl
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetUnapprovedListenersQuery__
 *
 * To run a query within a React component, call `useGetUnapprovedListenersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnapprovedListenersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnapprovedListenersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnapprovedListenersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnapprovedListenersQuery, GetUnapprovedListenersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnapprovedListenersQuery, GetUnapprovedListenersQueryVariables>(GetUnapprovedListenersDocument, baseOptions);
      }
export function useGetUnapprovedListenersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnapprovedListenersQuery, GetUnapprovedListenersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnapprovedListenersQuery, GetUnapprovedListenersQueryVariables>(GetUnapprovedListenersDocument, baseOptions);
        }
export type GetUnapprovedListenersQueryHookResult = ReturnType<typeof useGetUnapprovedListenersQuery>;
export type GetUnapprovedListenersLazyQueryHookResult = ReturnType<typeof useGetUnapprovedListenersLazyQuery>;
export type GetUnapprovedListenersQueryResult = ApolloReactCommon.QueryResult<GetUnapprovedListenersQuery, GetUnapprovedListenersQueryVariables>;
export const GetUsersByAliasDocument = gql`
    query getUsersByAlias($alias: String!) {
  getUsersByAlias(alias: $alias) {
    id
    isSuspended
    alias
    phone
  }
}
    `;

/**
 * __useGetUsersByAliasQuery__
 *
 * To run a query within a React component, call `useGetUsersByAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAliasQuery({
 *   variables: {
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useGetUsersByAliasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAliasQuery, GetUsersByAliasQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAliasQuery, GetUsersByAliasQueryVariables>(GetUsersByAliasDocument, baseOptions);
      }
export function useGetUsersByAliasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAliasQuery, GetUsersByAliasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAliasQuery, GetUsersByAliasQueryVariables>(GetUsersByAliasDocument, baseOptions);
        }
export type GetUsersByAliasQueryHookResult = ReturnType<typeof useGetUsersByAliasQuery>;
export type GetUsersByAliasLazyQueryHookResult = ReturnType<typeof useGetUsersByAliasLazyQuery>;
export type GetUsersByAliasQueryResult = ApolloReactCommon.QueryResult<GetUsersByAliasQuery, GetUsersByAliasQueryVariables>;
export const GlobalsDocument = gql`
    query globals {
  listenerPreferences {
    id
    ageRange
  }
  languages {
    id
    name
    label
  }
  topics {
    id
    name
    description
    sessionsToday
    numberOfListeners
    waitingSessions
  }
}
    `;

/**
 * __useGlobalsQuery__
 *
 * To run a query within a React component, call `useGlobalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlobalsQuery, GlobalsQueryVariables>) {
        return ApolloReactHooks.useQuery<GlobalsQuery, GlobalsQueryVariables>(GlobalsDocument, baseOptions);
      }
export function useGlobalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlobalsQuery, GlobalsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlobalsQuery, GlobalsQueryVariables>(GlobalsDocument, baseOptions);
        }
export type GlobalsQueryHookResult = ReturnType<typeof useGlobalsQuery>;
export type GlobalsLazyQueryHookResult = ReturnType<typeof useGlobalsLazyQuery>;
export type GlobalsQueryResult = ApolloReactCommon.QueryResult<GlobalsQuery, GlobalsQueryVariables>;
export const IsPhoneNumberInUseDocument = gql`
    query isPhoneNumberInUse($phone: String!) {
  isPhoneNumberInUse(phone: $phone)
}
    `;

/**
 * __useIsPhoneNumberInUseQuery__
 *
 * To run a query within a React component, call `useIsPhoneNumberInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPhoneNumberInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPhoneNumberInUseQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useIsPhoneNumberInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsPhoneNumberInUseQuery, IsPhoneNumberInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsPhoneNumberInUseQuery, IsPhoneNumberInUseQueryVariables>(IsPhoneNumberInUseDocument, baseOptions);
      }
export function useIsPhoneNumberInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPhoneNumberInUseQuery, IsPhoneNumberInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsPhoneNumberInUseQuery, IsPhoneNumberInUseQueryVariables>(IsPhoneNumberInUseDocument, baseOptions);
        }
export type IsPhoneNumberInUseQueryHookResult = ReturnType<typeof useIsPhoneNumberInUseQuery>;
export type IsPhoneNumberInUseLazyQueryHookResult = ReturnType<typeof useIsPhoneNumberInUseLazyQuery>;
export type IsPhoneNumberInUseQueryResult = ApolloReactCommon.QueryResult<IsPhoneNumberInUseQuery, IsPhoneNumberInUseQueryVariables>;
export const MeDocument = gql`
    query me($platform: Platform!) {
  chatToken(platform: $platform) {
    ... on Token {
      xmppToken
    }
    ... on NotLoggedIn {
      message
    }
  }
  currentRequest {
    ... on ChatRequest {
      ...MeCurrentRequestDetails
    }
    ... on NotLoggedIn {
      message
    }
  }
  currentRequests {
    ... on ChatRequest {
      ...MeCurrentRequestDetails
    }
    ... on NotLoggedIn {
      message
    }
  }
  me {
    ... on User {
      ...MeUserDetails
    }
    ... on NotLoggedIn {
      message
    }
    ... on DoesNotExist {
      message
    }
  }
  announcements: userAnnouncements {
    ...MeAnnouncementsDetails
  }
}
    ${MeCurrentRequestDetailsFragmentDoc}
${MeUserDetailsFragmentDoc}
${MeAnnouncementsDetailsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const AddedToDedicatedChatDocument = gql`
    subscription addedToDedicatedChat {
  addedToDedicatedChat {
    id
    user {
      id
      alias
    }
    listener {
      id
      firstName
      avatarUrl
    }
  }
}
    `;

/**
 * __useAddedToDedicatedChatSubscription__
 *
 * To run a query within a React component, call `useAddedToDedicatedChatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAddedToDedicatedChatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddedToDedicatedChatSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAddedToDedicatedChatSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AddedToDedicatedChatSubscription, AddedToDedicatedChatSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AddedToDedicatedChatSubscription, AddedToDedicatedChatSubscriptionVariables>(AddedToDedicatedChatDocument, baseOptions);
      }
export type AddedToDedicatedChatSubscriptionHookResult = ReturnType<typeof useAddedToDedicatedChatSubscription>;
export type AddedToDedicatedChatSubscriptionResult = ApolloReactCommon.SubscriptionResult<AddedToDedicatedChatSubscription>;
export const ChatRequestStateUpdatedDocument = gql`
    subscription chatRequestStateUpdated {
  chatRequestStateUpdated {
    id
    state
    recommendedForYou
    languagePreference {
      id
      name
      label
    }
    member {
      id
      alias
    }
    selectedTopic {
      id
      name
    }
    createdAt
  }
}
    `;

/**
 * __useChatRequestStateUpdatedSubscription__
 *
 * To run a query within a React component, call `useChatRequestStateUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatRequestStateUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRequestStateUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatRequestStateUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ChatRequestStateUpdatedSubscription, ChatRequestStateUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ChatRequestStateUpdatedSubscription, ChatRequestStateUpdatedSubscriptionVariables>(ChatRequestStateUpdatedDocument, baseOptions);
      }
export type ChatRequestStateUpdatedSubscriptionHookResult = ReturnType<typeof useChatRequestStateUpdatedSubscription>;
export type ChatRequestStateUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<ChatRequestStateUpdatedSubscription>;
export const MyChatEndedDocument = gql`
    subscription myChatEnded {
  myChatEnded {
    id
    state
  }
}
    `;

/**
 * __useMyChatEndedSubscription__
 *
 * To run a query within a React component, call `useMyChatEndedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyChatEndedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyChatEndedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMyChatEndedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<MyChatEndedSubscription, MyChatEndedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<MyChatEndedSubscription, MyChatEndedSubscriptionVariables>(MyChatEndedDocument, baseOptions);
      }
export type MyChatEndedSubscriptionHookResult = ReturnType<typeof useMyChatEndedSubscription>;
export type MyChatEndedSubscriptionResult = ApolloReactCommon.SubscriptionResult<MyChatEndedSubscription>;
export const MyChatRequestAcceptedDocument = gql`
    subscription myChatRequestAccepted {
  myChatRequestAccepted {
    id
    state
    listener {
      id
      firstName
      avatarUrl
      bio
      createdAt
      gender
      age
      sessionCount
      topics {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useMyChatRequestAcceptedSubscription__
 *
 * To run a query within a React component, call `useMyChatRequestAcceptedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyChatRequestAcceptedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyChatRequestAcceptedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMyChatRequestAcceptedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<MyChatRequestAcceptedSubscription, MyChatRequestAcceptedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<MyChatRequestAcceptedSubscription, MyChatRequestAcceptedSubscriptionVariables>(MyChatRequestAcceptedDocument, baseOptions);
      }
export type MyChatRequestAcceptedSubscriptionHookResult = ReturnType<typeof useMyChatRequestAcceptedSubscription>;
export type MyChatRequestAcceptedSubscriptionResult = ApolloReactCommon.SubscriptionResult<MyChatRequestAcceptedSubscription>;
export const OnlineStatusChangedDocument = gql`
    subscription onlineStatusChanged {
  onlineStatusChanged {
    userId
    online
  }
}
    `;

/**
 * __useOnlineStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOnlineStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnlineStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineStatusChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnlineStatusChangedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnlineStatusChangedSubscription, OnlineStatusChangedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnlineStatusChangedSubscription, OnlineStatusChangedSubscriptionVariables>(OnlineStatusChangedDocument, baseOptions);
      }
export type OnlineStatusChangedSubscriptionHookResult = ReturnType<typeof useOnlineStatusChangedSubscription>;
export type OnlineStatusChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnlineStatusChangedSubscription>;
export const RemovedFromDedicatedChatDocument = gql`
    subscription removedFromDedicatedChat {
  removedFromDedicatedChat {
    id
  }
}
    `;

/**
 * __useRemovedFromDedicatedChatSubscription__
 *
 * To run a query within a React component, call `useRemovedFromDedicatedChatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRemovedFromDedicatedChatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemovedFromDedicatedChatSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRemovedFromDedicatedChatSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RemovedFromDedicatedChatSubscription, RemovedFromDedicatedChatSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RemovedFromDedicatedChatSubscription, RemovedFromDedicatedChatSubscriptionVariables>(RemovedFromDedicatedChatDocument, baseOptions);
      }
export type RemovedFromDedicatedChatSubscriptionHookResult = ReturnType<typeof useRemovedFromDedicatedChatSubscription>;
export type RemovedFromDedicatedChatSubscriptionResult = ApolloReactCommon.SubscriptionResult<RemovedFromDedicatedChatSubscription>;