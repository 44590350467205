import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import {
  sendMessageAction,
  getPreviousMessagesAction,
  sendTypingAction,
  useDispatch,
  useSelector,
} from "../types/actions";
import { GroupConversation, Participant } from "../types/entities";

interface IUseGroupChat {
  sendMessage: (body: string) => void;
  conversation?: GroupConversation;
  participants: Record<Participant["id"], Participant>;
  getPreviousMessages: () => void;
  sendTypingIndicator: () => void;
}

export default function useGroupChat(conversationId: string): IUseGroupChat {
  const dispatch = useDispatch();

  const conversation = useSelector(
    s => s.conversations[conversationId] as GroupConversation,
    shallowEqual
  );

  const allParticipants = useSelector(s => s.participants, shallowEqual);

  const participants = useMemo(() => {
    let participants: Record<Participant["id"], Participant> = {};
    if (conversation) {
      conversation.participantIds.forEach(id => {
        participants[id] = allParticipants[id];
      });
    }
    return participants;
  }, [allParticipants, conversation]);

  const getPreviousMessages = () =>
    dispatch(getPreviousMessagesAction(conversationId));

  const sendMessage = (body: string) =>
    dispatch(sendMessageAction(body, conversationId));

  const sendTypingIndicator = () => dispatch(sendTypingAction(conversationId));

  return {
    sendMessage,
    getPreviousMessages,
    conversation,
    participants,
    sendTypingIndicator,
  };
}
