import React, { useContext, useState, FormEvent } from "react";
import Modal from "components/Modal";
import { Box, Flex, Text } from "rebass/styled-components";
import { ThemeContext } from "styled-components/macro";
import Button from "components/Button";
import ToggleButton from "components/ToggleButton";
import { List } from "immutable";
import {
  FeedbackCategory,
  useGiveFeedbackMutation,
} from "graphql/__generated__/types";
import IconButton from "components/IconButton";
import TextArea from "components/TextArea";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const feedbackTypes: FeedbackCategory[] = [
  FeedbackCategory.Bug,
  FeedbackCategory.Suggestion,
  FeedbackCategory.Feedback,
];

export default function FeedbackModal({ isOpen, onClose }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [selectedTypes, setSelectedTypes] = useState<List<FeedbackCategory>>(
    List()
  );
  const [error, setError] = useState("");
  const theme = useContext(ThemeContext);
  const [giveFeedback] = useGiveFeedbackMutation();

  const togglefType = (fType: FeedbackCategory) => {
    selectedTypes.contains(fType)
      ? setSelectedTypes(selectedTypes.filter((t) => t !== fType))
      : setSelectedTypes(selectedTypes.push(fType));
  };

  const onSubmitClick = async (e?: FormEvent<HTMLDivElement>) => {
    e && e.preventDefault();
    setError("");
    setIsSubmitting(true);
    if (!feedback) {
      setError("Please enter text");
      setIsSubmitting(false);
      return;
    }
    try {
      await giveFeedback({
        variables: {
          feedback,
          // categories: selectedTypes.toArray(),
        },
      });
      onClose();
      setFeedback("");
      setSelectedTypes(List());
    } catch (e) {
      setError(
        "An error occurred. If this keeps happening, contact support@hearme.app"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} label="Give us Feedback">
      <Box p={4} bg="panel" maxWidth={710} as="form">
        <Flex justifyContent="space-between">
          <Text variant="header">Give us Feedback</Text>
          <IconButton
            name="close-outline"
            fill={theme.colors.text.primary}
            onClick={onClose}
          />
        </Flex>
        <Flex marginTop={2} marginBottom={4}>
          <Text variant="main" maxWidth="90%">
            Experienced a bug, or have an idea for a new feature? Let us know
            here!
          </Text>
        </Flex>
        <Flex>
          <TextArea
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setFeedback(e.target.value);
            }}
            placeholder="Your feedback..."
          />
        </Flex>
        <Flex flexWrap="wrap" marginTop={2}>
          {feedbackTypes.map((fType) => (
            <ToggleButton
              key={fType}
              isSelected={selectedTypes.contains(fType)}
              onToggle={() => togglefType(fType)}
              label={getLabelOfFType(fType)}
            />
          ))}
        </Flex>
        {error && (
          <Text
            variant="body"
            color="error"
            marginTop={2}
            marginBottom={0}
            ml={1}
          >
            {error}
          </Text>
        )}
        <Flex justifyContent="space-between" marginTop={4}>
          <Text
            variant="body"
            maxWidth="90%"
            color="text.secondary"
            alignSelf="center"
          >
            Thanks for your feedback and suggestions!
          </Text>
          <Button
            label={"SUBMIT FEEDBACK"}
            disabled={isSubmitting}
            onClick={onSubmitClick}
            type="submit"
          />
        </Flex>
      </Box>
    </Modal>
  );
}

function getLabelOfFType(fType: FeedbackCategory) {
  switch (fType) {
    case "BUG":
      return "Bug";
    case "SUGGESTION":
      return "Suggestion";
    case "FEEDBACK":
      return "Feedback";
    default:
      return "Unknown";
  }
}
