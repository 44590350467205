import React from "react";
import { Flex } from "rebass/styled-components";
import MessagesContainer, {
  RenderableMessage,
} from "components/MessagesContainer";
import RequestDetails from "components/RequestDetails";
import useChat from "store/hooks/useChat";
import { useCreateDedicatedChatMutation, useEndMyChatMutation } from "graphql/__generated__/types";

interface Props {
  id: string;
}

export default function ChatContainer(props: Props) {
  const {
    conversation,
    getPreviousMessages,
    sendMessage,
    sendTypingIndicator,
  } = useChat(props.id);

  const [endMyChatMutationMutation] = useEndMyChatMutation({
    variables: {
      id: props.id
    }
  });

  const [createDedicatedChat] = useCreateDedicatedChatMutation();

  if (conversation === undefined) return null;

  const isListener = conversation.listenerId === conversation.myId;
  const [me, partner] = isListener
    ? [conversation.listener, conversation.member]
    : [conversation.member, conversation.listener];
  const usersTyping = conversation.typingIds.includes(partner.id)
    ? [partner.name]
    : undefined;

  const messages: RenderableMessage[] = conversation.messages
    .filter((m) => m.subject !== "preSessionData")
    .map((m) => {
      if (m.authorId === "system") return { ...m, author: undefined };
      const author = m.authorId === me.id ? me : partner;
      return { ...m, author };
    });

  return (
    <Flex flexDirection="row" sx={{ flex: 1 }}>
      <MessagesContainer
        messages={messages}
        usersTyping={usersTyping}
        conversationId={conversation.id}
        isEnded={conversation.isEnded}
        getOlderMessages={getPreviousMessages}
        myId={conversation.myId}
        sendMessage={sendMessage}
        sendTypingIndicator={sendTypingIndicator}
        gettingOlderMessages={conversation.loading}
        fullyLoaded={conversation.fullyLoaded}
      />
      {conversation.type === "active" && conversation.details && (
        <RequestDetails
          conversationId={conversation.id}
          joinedOn={new Date()}
          mood={conversation.details.mood!}
          reason={conversation.details.reason!}
          name={conversation.member.name}
          isOnline={conversation.member.isOnline}
          // isDedicated={conversation.type}
          sx={{
            justifySelf: "end",
          }}
          isEnded={conversation.isEnded}
          onEndClick={endMyChatMutationMutation}
          onCreateDedicatedChatClick={() =>
            createDedicatedChat({
              variables: {
                memberId: conversation.memberId,
                message: "Hi! We can keep in touch here.",
              },
            })
          }
        />
      )}
    </Flex>
  );
}
