import { combineEpics } from "redux-observable";
import { fromEvent } from "rxjs";
import { filter, map } from "rxjs/operators";
import { IEpic } from ".";
import {
  sendPresenceIndicatorAction,
  setConnectionStatusAction,
} from "../types/actions";

const getConnectionStateEpic: IEpic = (_action$, _state$, { service }) =>
  fromEvent<any>(service.xmpp, "status").pipe(
    map(status => {
      if (Array.isArray(status)) {
        return setConnectionStatusAction(status[0]);
      }
      return setConnectionStatusAction(status);
    }),
  );

const reconnectionEpic: IEpic = action$ =>
  action$.pipe(
    filter(setConnectionStatusAction.match),
    filter(a => a.payload.status === "open"),
    map(a => sendPresenceIndicatorAction(true)),
  );

const disconnectionEpic: IEpic = action$ =>
  action$.pipe(
    filter(setConnectionStatusAction.match),
    filter(a => a.payload.status === "disconnect"),
    map(a => sendPresenceIndicatorAction(false)),
  );

export default combineEpics(
  reconnectionEpic,
  disconnectionEpic,
  getConnectionStateEpic,
);
