import { filter, map, debounceTime } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import {
  addMessageAction,
  addInAppNotificationAction,
  removeInAppNotificationAction,
} from "../types/actions";
import { IEpic } from "./";

const addInAppNotificationEpic: IEpic = (action$, state$) =>
  action$.pipe(
    filter(addMessageAction.match),
    filter(action => {
      const convo = state$.value.conversations[action.payload.conversationId];
      return (
        convo.type !== "group" &&
        convo.myId !== action.payload.authorId &&
        action.payload.authorId !== "system"
      );
    }),
    map(action => {
      const author = state$.value.participants[action.payload.authorId];
      const convo = state$.value.conversations[action.payload.conversationId];
      return addInAppNotificationAction({
        text: action.payload.body,
        conversationId: action.payload.conversationId,
        avatarUrl: author.avatarUrl,
        name: author.name,
        type: convo.type === "group" ? "NewGroupMessage" : "NewMessage",
      });
    }),
  );

const removeInAppNotificationEpic: IEpic = action$ =>
  action$.pipe(
    filter(addInAppNotificationAction.match),
    debounceTime(2000),
    map(removeInAppNotificationAction),
  );

export default combineEpics(
  addInAppNotificationEpic,
  removeInAppNotificationEpic,
);
