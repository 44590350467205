import React from "react";
import { Flex, Box, Text, BoxProps } from "rebass/styled-components";
import Button from "components/Button";
import Heading from "components/Heading";

interface Props extends BoxProps {
  header: string;
  subheader: React.ReactNode;
  input: React.ReactNode;
  helperLabel?: string;
  helper?: React.ReactNode;
  mainButton: string;
  disabled?: boolean;
}

export default function AuthCard(props: Props) {
  return (
    <Flex>
      <Box
        sx={{ borderRadius: 10, border: "1px solid", borderColor: "divider" }}
        width={450}
        p={4}
        bg="overlay"
        {...props}
      >
        <Heading mb={3}>{props.header}</Heading>
        <Text variant="body" mb={5}>
          {props.subheader}
        </Text>
        {props.input}
        <Flex mt={3} mb={5} justifyContent="space-between">
          <Text variant="body" color="text.secondary">
            {props.helperLabel}
          </Text>
          <Text variant="body" color="text.secondary">
            {props.helper}
          </Text>
        </Flex>
        <Flex justifyContent="center">
          <Button
            disabled={props.disabled}
            type="submit"
            variant="large"
            label={props.mainButton}
          />
        </Flex>
      </Box>
    </Flex>
  );
}
