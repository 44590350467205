import gql from "graphql-tag";

export const GET_LISTENER = gql`
  query getListener($id: ID!) {
    listener(id: $id) {
      id
      email
      firstName
      avatarUrl
      createdAt
      bio
      gender
      age
      sessionCount
      location {
        city
        countryCode
      }
      topics {
        name
        id
      }
      isModerator
      isBanned
    }
  }
`;
