import {
  filter,
  tap,
  mergeMap,
  debounceTime,
  ignoreElements,
  groupBy,
} from "rxjs/operators";
import { sendTypingAction } from "../types/actions";
import { sendComposingRequest, sendPausedRequest } from "../service";
import { IEpic } from "./";
import { xmppSend } from "./helpers";

const sendTypingIndicatorEpic: IEpic = (action$, state$, { service }) =>
  action$.pipe(
    filter(sendTypingAction.match),
    filter(
      action =>
        state$.value.participants[
          state$.value.conversations[action.payload.conversationId].myId
        ].isOnline && service.xmpp.status === "online",
    ),
    groupBy(action => action.payload.conversationId),
    mergeMap(group$ => {
      let sent = false;
      return group$.pipe(
        tap(action => {
          if (!sent) {
            xmppSend(
              service.xmpp,
              sendComposingRequest(action.payload.conversationId),
            );
            sent = true;
          }
        }),
        debounceTime(5000),
        filter(
          action =>
            state$.value.participants[
              state$.value.conversations[action.payload.conversationId].myId
            ].isOnline && service.xmpp.status === "online",
        ),
        tap(action => {
          xmppSend(
            service.xmpp,
            sendPausedRequest(action.payload.conversationId),
          ).catch(() => {});
          sent = false;
        }),
      );
    }),
    ignoreElements(),
  );

export default sendTypingIndicatorEpic;
