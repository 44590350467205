import React, { useState } from "react";
import { Flex } from "rebass/styled-components";
import { ReactComponent as Logo } from "assets/logo.svg";
import TextButton from "components/TextButton";
// import { useChats } from "context/chat";
import FeedbackModal from "components/FeedbackModal";

interface Props {
  children?: React.ReactNode;
}

export default function ChatHeader(props: Props) {
  const { children, ...rest } = props;
  // const { headerChildren } = useChats();
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);

  return (
    <>
      <FeedbackModal
        isOpen={isFeedbackVisible}
        onClose={() => setIsFeedbackVisible(false)}
      />
      <Flex
        px={3}
        py={"15px"}
        height={70}
        color="white"
        bg="overlay"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: "divider",
        }}
        {...rest}
      >
        <Logo />
        {/* {headerChildren ? headerChildren : null} */}
        <TextButton
          label="Give us feedback"
          onClick={() => setIsFeedbackVisible(true)}
        />
      </Flex>
    </>
  );
}
