import { createReducer } from "@reduxjs/toolkit";
import { ConnectionStatus } from "../types/entities";
import { setConnectionStatusAction } from "../types/actions";

const inAppNotificationReducer = createReducer<ConnectionStatus>(
  "disconnect",
  builder =>
    builder.addCase(setConnectionStatusAction, (state, action) => {
      return action.payload.status;
    }),
);

export default inAppNotificationReducer;
