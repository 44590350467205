import React, { ReactNode } from "react";
import { ThemeProvider } from "context/theme";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { client } from "./apollo";
import { FirebaseProvider } from "context/firebase";
import { AuthProvider } from "context/auth";
import { UserProvider } from "context/user";
import { FullscreenLoaderProvider } from "context/fullscreenLoader";

export default function AppProviders({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider>
      <FullscreenLoaderProvider>
        <FirebaseProvider>
          <ApolloProvider client={client}>
            <AuthProvider>
              <BrowserRouter>
                <UserProvider>{children}</UserProvider>
              </BrowserRouter>
            </AuthProvider>
          </ApolloProvider>
        </FirebaseProvider>
      </FullscreenLoaderProvider>
    </ThemeProvider>
  );
}
