import { OperationDefinitionNode } from "graphql";
import {
  ApolloLink,
  split,
  InMemoryCache,
  ApolloClient,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import { WebSocketLink } from "@apollo/link-ws";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "@apollo/link-context";
import { RetryLink } from "@apollo/link-retry";
import introspectionQueryResultData from "../../graphql/__generated__/fragmentTypes.json";
import * as firebase from "firebase/app";
import "firebase/auth";

/** logs all API errors to console */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

/** attach Firebase auth token to every request */
const authLink = setContext(async () => {
  const currentUser = firebase.auth().currentUser;
  return {
    headers: {
      authorization: currentUser ? await currentUser.getIdToken() : null,
    },
  };
});

/* Add support for union and intersection types */
let possibleTypes: Record<string, any> = {};
introspectionQueryResultData.__schema.types.forEach((supertype) => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      (subtype) => subtype.name
    );
  }
});

const cache = new InMemoryCache({ possibleTypes });

const retryLink = new RetryLink();

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_ENDPOINT!,
  options: {
    reconnect: true,
    timeout: 30000,
  },
});

// Add subscription authentication
const subscriptionMiddleware = {
  async applyMiddleware(options: any, next: any) {
    const currentUser = firebase.auth().currentUser;
    options.authToken = currentUser ? await currentUser.getIdToken() : null;
    next();
  },
};
// @ts-ignore (subscriptionClient is private, this is a hack)
wsLink.subscriptionClient.use([subscriptionMiddleware]);

const endpointLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(
      query
    ) as OperationDefinitionNode;
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT!,
  })
);

const link = ApolloLink.from([errorLink, retryLink, authLink, endpointLink]);

export const client = new ApolloClient({
  link,
  cache,
  name: `hearme-web`,
});
