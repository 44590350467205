import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "components/App";
import AppProviders from "components/App/providers";
import Maintenance from "components/Maintenance";
import { ThemeProvider } from "context/theme";
import "react-virtualized/styles.css";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

const IS_MAINTENANCE = false;

if (IS_MAINTENANCE) {
  ReactDOM.render(
    <ThemeProvider>
      <Maintenance />
    </ThemeProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <AppProviders>
      <App />
    </AppProviders>,
    document.getElementById("root")
  );
}
