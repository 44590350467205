import { from } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import {
  getActiveConversationAction,
  getConversationAction,
} from "../types/actions";
import { IEpic } from ".";
import { GetChatQuery, GetChatQueryVariables } from "graphql/__generated__/types";
import { GET_CHAT } from "graphql/queries/getChat";

const getActiveConversationEpic: IEpic = (action$, _, { service }) =>
  action$.pipe(
    filter(getActiveConversationAction.match),
    mergeMap((a) =>
      from(
        service.apolloClient.query<GetChatQuery, GetChatQueryVariables>({
          query: GET_CHAT,
          variables: { id: a.payload.id },
          fetchPolicy: "network-only",
        })
      ).pipe(
        map(({ data }) => {
          const chat = data!.getChatRequest!;
          return getConversationAction({
            id: chat.id,
            member: {
              id: chat.member.id,
              name: chat.member.alias!,
            },
            listener: {
              id: chat.listener!.id,
              name: chat.listener!.firstName,
              avatarUrl: chat.listener!.avatarUrl || undefined,
            },
            myId:
              a.payload.myUserId === chat.member.id
                ? chat.member.id
                : chat.listener!.id,
            type: "active",
            startedAt: chat.chat!.startedAt,
            memberJoined: chat.chat!.memberJoined || undefined,
          });
        })
      )
    )
  );

export default getActiveConversationEpic;
