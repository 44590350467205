import { addInAppNotificationAction, useDispatch } from "../types/actions";
import { InAppNotification } from "../types/entities";

type AddInAppNotification = (notification: InAppNotification) => void;

export default function useInAppNotificationHandler(): AddInAppNotification {
  const dispatch = useDispatch();

  return (notification: InAppNotification) => {
    dispatch(addInAppNotificationAction(notification));
  };
}
