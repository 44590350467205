import {
  useCancelMyRequestMutation,
  useJoinChatMutation,
  useEndMyChatMutation,
} from "graphql/__generated__/types";

export default function useSession() {
  const [cancelMyRequestMutation] = useCancelMyRequestMutation();
  const [acceptRequestMutation] = useJoinChatMutation();
  const [endMyChatMutationMutation] = useEndMyChatMutation();

  function acceptRequest(id: string) {
    return acceptRequestMutation({
      variables: {
        id,
      },
    });
  }

  return {
    cancelMyRequest: cancelMyRequestMutation,
    acceptRequest,
    endMyChat: endMyChatMutationMutation,
  };
}
