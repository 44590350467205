import { merge, from } from "rxjs";
import { mergeMap, filter, takeUntil, startWith, mapTo } from "rxjs/operators";
import {
  addGroupConversationAction,
  getGroupConversationAction,
  removeConversationAction,
  setOnlineStatusesAction,
  addPreviousMessagesBatchedAction,
  addPreviousMessagesAction,
  setOnlineStatusAction,
} from "../types/actions";
import { IEpic } from ".";
import { joinChatRequest } from "../service";
import {
  getRealTimeEventsHandler,
  getPrevMessagesHandler,
} from "./getConversationHandlers";
import { retryIfOffline, batchActions, xmppSend } from "./helpers";

const getGroupConversationEpic: IEpic = (action$, state$, { service }) =>
  action$.pipe(
    filter(getGroupConversationAction.match),
    retryIfOffline(service, undefined, "getGroupConversationEpic"),
    mergeMap(action =>
      from(
        xmppSend(
          service.xmpp,
          joinChatRequest(action.payload.id, action.payload.myId),
        ),
      ).pipe(mapTo(action)),
    ),
    mergeMap(action =>
      merge(
        /** Order is important, getPrevMessagesHandler needs to be first as getRealTimeEventsHandler uses it */
        getPrevMessagesHandler(action$, state$, action.payload.id, service),
        getRealTimeEventsHandler(action$, state$, action.payload.id, service),
      ).pipe(
        takeUntil(
          action$.pipe(
            filter(removeConversationAction.match),
            filter(remove => action.payload.id === remove.payload.id),
          ),
        ),
        startWith(addGroupConversationAction(action.payload)),
      ),
    ),
    batchActions(
      "AddPreviousMessages",
      addPreviousMessagesBatchedAction,
      (items: ReturnType<typeof addPreviousMessagesAction>[]) =>
        items.map(i => i.payload),
    ),
    batchActions(
      "SetUserOnline",
      setOnlineStatusesAction,
      (items: ReturnType<typeof setOnlineStatusAction>[]) =>
        items.map(i => i.payload),
    ),
  );

export default getGroupConversationEpic;
