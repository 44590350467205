import React from "react";
import MessagesContainer, {
  RenderableMessage,
} from "components/MessagesContainer";
import OnlineStatusSidebar from "./OnlineStatusSidebar";
import useGroupChat from "store/hooks/useGroupChat";

interface Props {
  id: string;
}

export default function GroupChatContainer(props: Props) {
  const {
    conversation,
    participants,
    getPreviousMessages,
    sendMessage,
    sendTypingIndicator,
  } = useGroupChat("listeners-group-chat");

  if (conversation === undefined) return null;

  // const onlineCount = Object.values(participants).filter((p) => p.isOnline)
  //   .length;

  const messages: RenderableMessage[] = conversation.messages.map((m) => {
    const author =
      m.authorId === "system"
        ? undefined
        : {
            name: participants[m.authorId]?.name || "",
            avatarUrl: participants[m.authorId]?.avatarUrl || "",
          };
    return { ...m, author };
  });

  const usersTyping = conversation.typingIds
    .filter((id) => id !== conversation.myId)
    .map((id) => participants[id].name);

  return (
    <>
      <MessagesContainer
        messages={messages}
        usersTyping={usersTyping}
        conversationId={conversation.id}
        getOlderMessages={getPreviousMessages}
        myId={conversation.myId}
        sendMessage={sendMessage}
        sendTypingIndicator={sendTypingIndicator}
        gettingOlderMessages={conversation.loading}
        fullyLoaded={conversation.fullyLoaded}
        isGroup
      />
      <OnlineStatusSidebar participants={Object.values(participants)} />
    </>
  );
}
