import React, { useContext } from "react";
import { Link as RLink, LinkProps } from "react-router-dom";
import { ThemeContext } from "styled-components";

export type { LinkProps };

export default function Link(props: LinkProps) {
  const theme = useContext(ThemeContext);
  return <RLink style={{ color: theme.colors.primary }} {...props} />;
}
