const DESCRIPTIONS = [
  "Terrible",
  "Not great",
  "Ok",
  "Pretty good",
  "Very good! :)",
];

export default function getTextFromMood(mood: number) {
  if (mood <= 20) {
    return DESCRIPTIONS[0];
  } else if (mood > 20 && mood <= 40) {
    return DESCRIPTIONS[1];
  } else if (mood > 40 && mood <= 60) {
    return DESCRIPTIONS[2];
  } else if (mood > 60 && mood <= 80) {
    return DESCRIPTIONS[3];
  } else {
    return DESCRIPTIONS[4];
  }
}
