import React, { useContext } from "react";
import { Button as RebassButton, ButtonProps } from "rebass/styled-components";
import { ThemeContext } from "styled-components";
import { adjustColorBrightness } from "helpers/adjustColorBrightness";

interface Props extends ButtonProps {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

export default function Button(props: Props) {
  const { sx, ...rest } = props;
  const theme = useContext(ThemeContext);
  return (
    <RebassButton
      bg={
        props.disabled
          ? theme.colors.disabled
          : props.bg || theme.colors.secondary
      }
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        flexShrink: 0,
        fontSize: 14,
        letterSpacing: 1.5,
        fontFamily: "canada-type-gibson, sans-serif",
        fontWeight: 600,
        ":hover": {
          backgroundColor: props.disabled
            ? theme.colors.disabled
            : adjustColorBrightness(props.bg || theme.colors.secondary, 10),
          cursor: props.disabled ? "default" : "pointer",
        },
        ":focus": {
          outline: "none",
          backgroundColor: adjustColorBrightness(
            props.bg || theme.colors.secondary,
            20
          ),
        },
        ...sx,
      }}
      {...rest}
    >
      {props.label.toUpperCase()}
    </RebassButton>
  );
}
