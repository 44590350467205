import React, { useEffect, useRef } from "react";
import {
  RequestState,
  ChatRequestStateUpdatedSubscription,
  ChatRequestStateUpdatedSubscriptionVariables,
  useGetPendingChatRequestsLazyQuery,
  ChatRequest,
} from "graphql/__generated__/types";
import { CHAT_REQUEST_STATE_UPDATED } from "graphql/subscriptions/chatRequestStateUpdated";
import { ApolloError, NetworkStatus } from "@apollo/client";
import { useUser } from "./user";

interface ContextProps {
  loading: boolean;
  data: ChatRequest[] | null;
  error: ApolloError | undefined;
  networkStatus: NetworkStatus;
}

const PendingChatsContext = React.createContext<ContextProps | null>(null);

function PendingChatRequestsProvider(props: any) {
  const user = useUser();
  const unsubRef = useRef<() => void>(() => {});
  const [
    getPendingChatRequests,
    { loading, data, error, subscribeToMore, networkStatus },
  ] = useGetPendingChatRequestsLazyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000,
    onCompleted() {
      unsubRef.current(); // Unsubscribe
      unsubRef.current = subscribe(); // Rebsubscribe
    },
  });

  useEffect(() => {
    return unsubRef.current;
  }, []);

  useEffect(() => {
    if (user.listener?.isEnabled) {
      getPendingChatRequests();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const subscribe = () => {
    if (!subscribeToMore) return () => {};
    return subscribeToMore<
      ChatRequestStateUpdatedSubscription,
      ChatRequestStateUpdatedSubscriptionVariables
    >({
      document: CHAT_REQUEST_STATE_UPDATED,
      // @ts-ignore
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        const request = subscriptionData.data.chatRequestStateUpdated;

        let prevResult: any = [];
        if (prev.getPendingChatRequests?.__typename === "ChatRequestsResult") {
          prevResult = prev.getPendingChatRequests.chatRequests;
        }

        switch (request.state) {
          case RequestState.Requested:
            return {
              ...prev,
              getPendingChatRequests: {
                ...prev.getPendingChatRequests,
                chatRequests: [
                  ...prevResult,
                  { ...request, __typename: "ChatRequest" },
                ],
              },
            };
          case RequestState.Accepted: // was accepted by someone
          case RequestState.Cancelled: // was cancelled by user
            // we need to remove it from the list
            return {
              ...prev,
              getPendingChatRequests: {
                ...prev.getPendingChatRequests,
                chatRequests: prevResult.filter(
                  (r: any) => r.id !== request.id
                ),
              },
            };
          default:
            return prev;
        }
      },
    });
  };

  const pendingRequests =
    data?.getPendingChatRequests?.__typename === "ChatRequestsResult"
      ? data.getPendingChatRequests.chatRequests
      : null;

  return (
    <PendingChatsContext.Provider
      value={{
        loading,
        error,
        data: pendingRequests,
        networkStatus,
      }}
      {...props}
    />
  );
}

const usePendingChatRequests = () =>
  React.useContext(PendingChatsContext) as ContextProps;

export { PendingChatRequestsProvider, usePendingChatRequests };
