import React, { ReactNode, FunctionComponent } from "react";
import Scrollbars, { ScrollbarProps } from "react-scrollbars-custom";
import styled from "styled-components/macro";

interface Props extends ScrollbarProps {
  children: ReactNode;
  hideVerticalScrollBar?: boolean;
}

const ScrollBar = React.forwardRef((props: Props, ref) => {
  const { children, hideVerticalScrollBar, ...rest } = props;

  return (
    <StyledScrollbar
      {...rest}
      ref={ref}
      wrapperProps={{ className: "scroller-wrapper" }}
      thumbYProps={{
        className: "thumb-vertical",
      }}
      trackYProps={{
        className: props.hideVerticalScrollBar
          ? "track-vertical-hidden"
          : "track-vertical",
      }}
      scrollerProps={{ className: "scroller" }}
    >
      {children}
    </StyledScrollbar>
  );
});
// @ts-ignore
const StyledScrollbar = styled<FunctionComponent<Props>>(Scrollbars)`
  .scroller-wrapper {
    right: 0 !important;
  }
  .track-vertical {
    position: absolute !important;
    right: 0 !important;
    width: 3px !important;
    opacity: 0.3 !important;
    background: ${(props: any) => props.theme.colors.overlay} !important;
  }
  .track-vertical-hidden {
    display: none;
  }
  .thumb-vertical {
    background: ${(props: any) => props.theme.colors.disabled} !important;
    opacity: 5 !important;
  }
`;

export default ScrollBar;
