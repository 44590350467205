import { empty, of } from "rxjs";
import { filter, concatMap } from "rxjs/operators";
import {
  sendPresenceIndicatorAction,
  getAllPreviousMessagesAction,
} from "../types/actions";
import { IEpic } from "./";
import {
  sendOfflineIndicator,
  joinChatRequest,
} from "../service/xmlGenerators";
import { retryIfOffline, xmppSend } from "./helpers";

const sendPresencesEpic: IEpic = (action$, state$, { service }) =>
  action$.pipe(
    filter(sendPresenceIndicatorAction.match),
    retryIfOffline(service, undefined, "sendPresencesEpic"),
    concatMap(a => {
      if (a.payload.online) {
        Object.values(state$.value.conversations).forEach(convo => {
          if (convo.type === "group" || !convo.isEnded) {
            xmppSend(service.xmpp, joinChatRequest(convo.id, convo.myId));
          }
        });
        return of(getAllPreviousMessagesAction(true));
      } else {
        Object.values(state$.value.conversations).forEach(convo => {
          if (convo.type === "group" || !convo.isEnded) {
            xmppSend(service.xmpp, sendOfflineIndicator(convo.id, convo.myId));
          }
        });
        return empty();
      }
    }),
  );

export default sendPresencesEpic;
