import React, { useState, useContext } from "react";
import { Flex, Text } from "rebass/styled-components";
import FadeIn from "react-fade-in";
import ReactLoading from "react-loading";
import { ThemeContext } from "styled-components";
import HeaderBar from "components/HeaderBar";

interface ContextProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}

// @ts-ignore
const FullscreenContext = React.createContext<ContextProps>();

function FullscreenLoaderProvider(props: any) {
  const [isActive, setIsActive] = useState<boolean>(true);
  const theme = useContext(ThemeContext);

  return (
    <FullscreenContext.Provider
      value={{
        isActive,
        setIsActive,
      }}
    >
      <>
        {props.children}
        {isActive && (
          <Flex height="100%" flexDirection="column">
            <HeaderBar />
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor={theme.colors.background}
              sx={{ flex: 1 }}
            >
              <FadeIn>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <ReactLoading
                    type={"bubbles"}
                    color={theme.colors.primary}
                    height={100}
                    width={100}
                  />
                  <Text variant="sectionHeader" sx={{ marginTop: 30 }}>
                    Loading...
                  </Text>
                </Flex>
              </FadeIn>
            </Flex>
          </Flex>
        )}
      </>
    </FullscreenContext.Provider>
  );
}

const useFullscreenLoader = (): ContextProps =>
  React.useContext(FullscreenContext);

export { FullscreenLoaderProvider, useFullscreenLoader };
