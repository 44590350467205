import { createReducer } from "@reduxjs/toolkit";
import uniq from "lodash.uniq";
import groupBy from "lodash.groupby";
import { GenericConversation, OneToOneConversation } from "../types/entities";
import {
  addConversationAction,
  addGroupConversationAction,
  addMessageAction,
  removeConversationAction,
  addOptimisticMessageAction,
  endConversationAction,
  getPreviousMessagesAction,
  setTypingAction,
  setConversationLoaded,
  addMessagesAction,
  setMessageErrorAction,
  setDisplayedIndicatorAction,
  addParticipantAction,
  setOptimisticDisplayedIndicatorAction,
  addParticipantsAction,
  setOnlineStatusAction,
  getAllPreviousMessagesAction,
  addPreviousMessagesBatchedAction,
  setMessageRemovedAction,
} from "../types/actions";
import { addPreviousMessages } from "./helpers";

export type ConversationsState = Record<
  GenericConversation["id"],
  GenericConversation
>;
const conversationsReducer = createReducer<ConversationsState>({}, (builder) =>
  builder
    .addCase(addConversationAction, (state, action) => {
      state[action.payload.id] = {
        type: action.payload.type,
        id: action.payload.id,
        typingIds: [],
        memberId: action.payload.member.id,
        listenerId: action.payload.listener.id,
        isEnded: false,
        messages: [],
        myId: action.payload.myId,
        loading: false,
        fullyLoaded: false,
        startedAt: action.payload.startedAt,
        memberJoined: action.payload.memberJoined,
        details: action.payload.details,
      };
    })
    .addCase(addGroupConversationAction, (state, action) => {
      state[action.payload.id] = {
        type: "group",
        id: action.payload.id,
        name: action.payload.name,
        typingIds: [],
        messages: [],
        myId: action.payload.myId,
        loading: false,
        fullyLoaded: false,
        participantIds: action.payload.participants.map((p) => p.id),
      };
    })
    .addCase(addMessageAction, (state, action) => {
      const conversation = state[action.payload.conversationId];
      if (
        action.payload.subject === "preSessionData" &&
        conversation.type === "active"
      ) {
        const moodObj = JSON.parse(action.payload.body);
        conversation.details = {
          mood: moodObj.mood.value,
          reason: moodObj.mood.description,
        };
      }
      let existingMessageIndex = conversation.messages.findIndex(
        (m) => m.id === action.payload.id
      );
      if (existingMessageIndex !== -1) {
        conversation.messages[existingMessageIndex] = action.payload;
      } else {
        conversation.messages.push(action.payload);
      }
      conversation.typingIds = conversation.typingIds.filter(
        (id) => id !== action.payload.authorId
      );
    })
    .addCase(setMessageRemovedAction, (state, action) => {
      const convo = state[action.payload.conversationId];
      if (convo) {
        convo.messages = convo.messages.filter(
          (m) => m.id !== action.payload.id
        );
      }
    })
    .addCase(setMessageErrorAction, (state, action) => {
      const convo = state[action.payload.conversationId];
      if (convo) {
        const message = convo.messages.find((m) => m.id === action.payload.id);
        if (message) {
          message.status = "error";
        }
      }
    })
    .addCase(addMessagesAction, (state, action) => {
      const conversation = state[action.payload.conversationId];
      action.payload.messages.forEach((message) => {
        let existingMessageIndex = conversation.messages.findIndex(
          (m) => m.id === message.id
        );
        if (existingMessageIndex !== -1) {
          conversation.messages[existingMessageIndex] = message;
        } else {
          conversation.messages.push(message);
        }
        conversation.typingIds = conversation.typingIds.filter(
          (id) => id !== message.authorId
        );
      });
    })
    .addCase(addOptimisticMessageAction, (state, action) => {
      const conversation = state[action.payload.conversationId];
      const authorId = conversation.myId;
      const status = "sending";
      conversation.messages.push({
        ...action.payload,
        status,
        authorId,
        stanzaId: 0,
        subject: null,
      });
    })
    .addCase(setTypingAction, (state, action) => {
      const conversation = state[action.payload.conversationId];
      if (action.payload.isTyping) {
        if (!conversation.typingIds.includes(action.payload.userId)) {
          conversation.typingIds.push(action.payload.userId);
        }
      } else {
        conversation.typingIds = conversation.typingIds.filter(
          (id) => id !== action.payload.userId
        );
      }
    })
    .addCase(getPreviousMessagesAction, (state, action) => {
      const conversation = state[action.payload.conversationId];
      conversation.loading = true;
    })
    .addCase(getAllPreviousMessagesAction, (state, action) => {
      Object.values(state).forEach((convo) => {
        convo.loading = true;
      });
    })
    .addCase(addPreviousMessagesBatchedAction, (state, action) => {
      action.payload.forEach((c) => {
        addPreviousMessages(state, c);
      });
    })
    .addCase(removeConversationAction, (state, action) => {
      delete state[action.payload.id];
    })
    .addCase(endConversationAction, (state, action) => {
      const conversation = state[action.payload.id] as OneToOneConversation;
      if (conversation) {
        conversation.isEnded = true;
      }
    })
    .addCase(setConversationLoaded, (state, action) => {
      /** Never make it unloaded */
      if (action.payload.loaded) {
        const conversation = state[action.payload.conversationId];
        conversation.fullyLoaded = true;
      }
    })
    .addCase(setDisplayedIndicatorAction, (state, action) => {
      const convo = state[action.payload.conversationId];
      if (convo.type === "active" || convo.type === "dedicated") {
        /** {action.payload.by} sent a read indicator, mark the messages sent by the other person read */
        convo.messages = convo.messages.map((m) =>
          m.authorId !== action.payload.by &&
          m.status === "sent" &&
          action.payload.messageStanzaId >= m.stanzaId
            ? { ...m, displayed: true }
            : m
        );
      }
    })
    .addCase(setOnlineStatusAction, (state, action) => {
      Object.values(state).forEach((convo) => {
        convo.typingIds = convo.typingIds.filter(
          (id) => id !== action.payload.userId
        );
      });
    })
    .addCase(addParticipantAction, (state, action) => {
      const convo = state[action.payload.conversationId];
      if (convo && convo.type === "group") {
        if (!convo.participantIds.includes(action.payload.id)) {
          convo.participantIds.push(action.payload.id);
        }
      }
    })
    .addCase(setOptimisticDisplayedIndicatorAction, (state, action) => {
      const convo = state[action.payload.conversationId];
      if (convo.type === "active" || convo.type === "dedicated") {
        /** {action.payload.by} sent a read indicator, mark the messages sent by the other person read */
        convo.messages = convo.messages.map((m) =>
          m.authorId !== convo.myId ? { ...m, displayed: true } : m
        );
      }
    })
    .addCase(addParticipantsAction, (state, action) => {
      const additionsPerConvo = groupBy(
        action.payload.participants,
        (p) => p.conversationId
      );
      Object.keys(additionsPerConvo).forEach((conversationId) => {
        const participants = additionsPerConvo[conversationId];
        const convo = state[conversationId];
        if (convo && convo.type === "group") {
          convo.participantIds = uniq([
            ...convo.participantIds,
            ...participants.map((p) => p.id),
          ]);
        }
      });
    })
);

export default conversationsReducer;
