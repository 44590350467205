import React, { useContext } from "react";
import { Box, BoxProps, Text } from "rebass/styled-components";
import {
  Input as RebassInput,
  InputProps,
  Label,
} from "@rebass/forms/styled-components";
import { ThemeContext } from "styled-components";

export interface Props extends InputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  containerProps?: BoxProps;
  error?: string | null;
}

const Input = React.forwardRef((props: Props, ref) => {
  const theme = useContext(ThemeContext);
  return (
    <Box {...props.containerProps}>
      <Label
        sx={{
          fontSize: 12,
          fontFamily: "canada-type-gibson, sans-serif",
          fontWeight: 300,
          fontStyle: "normal",
          letterSpacing: 0,
          color: theme.colors.text.secondary,
        }}
        htmlFor={props.id}
      >
        {props.label}
      </Label>
      <RebassInput
        ref={ref}
        sx={{
          border: "none",
          borderBottom: "1px solid",
          color: "text.input",
          fontSize: 14,
          fontFamily: "canada-type-gibson, sans-serif",
          fontWeight: 300,
          fontStyle: "normal",
          ":focus": {
            outlineWidth: 0,
          },
        }}
        {...props}
      />
      <Text sx={{ height: 6 }} my={2} variant="error">
        {props.error}
      </Text>
    </Box>
  );
});

export default Input;
