import { OneToOneConversation } from "../types/entities";

export const getUnreadMessagesCount = (convo: OneToOneConversation) =>
  convo.messages.filter(
    (m) => m.authorId !== "system" && m.authorId !== convo.myId && !m.displayed
  ).length;

/**
 *
 * @param as First array
 * @param bs Second array
 * @param compare Item comparison function. Defaults to identity
 */
export const areArraysEqual = <T>(
  as: T[],
  bs: T[],
  compare: (a: T, b: T) => boolean = (a, b) => a === b
) => {
  if (as.length !== bs.length) return false;
  for (let i = 0; i < as.length; i++) {
    if (!compare(as[i], bs[i])) return false;
  }
  return true;
};
