import React from "react";
import {
  Textarea as RTextArea,
  TextareaProps,
} from "@rebass/forms/styled-components";
interface Props extends TextareaProps {}
export default function TextArea(props: Props) {
  const { sx, ...rest } = props;
  return (
    <RTextArea
      color="text.input"
      autoComplete="off"
      onChange={props.onChange}
      sx={{
        fontFamily: "canada-type-gibson, sans-serif",
        fontWeight: 300,
        fontStyle: "normal",
        height: 90,
        ":focus": {
          outlineWidth: 0,
        },
        "::-webkit-input-placeholder": {
          color: "text.secondary",
          fontSize: 14,
        },
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 5,
        ...sx,
      }}
      {...rest}
    />
  );
}
