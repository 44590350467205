import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Heading as RebassHeading,
  HeadingProps,
} from "rebass/styled-components";

export interface Props extends HeadingProps {}

export default function Heading(props: Props) {
  const theme = useContext(ThemeContext);
  return (
    <RebassHeading {...props} sx={{ ...theme.text.header, ...props.sx }} />
  );
}
