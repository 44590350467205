import React, { useContext, useState } from "react";
import { Box, Text, BoxProps } from "rebass/styled-components";
import Avatar from "components/Avatar";
import { formatDistanceToNow } from "date-fns";
import { ThemeContext } from "styled-components";
import { adjustColorBrightness } from "helpers/adjustColorBrightness";
import Icon from "components/Icon";
import Button from "components/Button";
import ReactLoading from "react-loading";

interface Props {
  onAccept: () => Promise<void>;
  avatarUrl?: string;
  name: string;
  topic?: string;
  sentAt: Date;
  isRecommended?: boolean | null;
  containerProps?: BoxProps;
}

export default function PendingRequest(props: Props) {
  const [isAccepting, setIsAccepting] = useState(false);
  const theme = useContext(ThemeContext);
  return (
    <Box
      px={20}
      py={15}
      onClick={() => props.onAccept}
      height={95}
      display="inline-flex"
      {...props.containerProps}
      sx={{
        position: "relative",
        justifyContent: "space-between",
        ":hover": {
          backgroundColor: adjustColorBrightness(theme.colors.overlay, -3),
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
        }}
      >
        <Avatar
          url={props.avatarUrl}
          name={props.name}
          mr={3}
          alignSelf="center"
        />
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <Text
            variant="main"
            mb={"6px"}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 200,
            }}
          >
            {props.name}
          </Text>
          <Text
            variant="body"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 200,
              fontFamily: "canada-type-gibson, sans-serif",
              fontWeight: 500,
              fontStyle: "normal",
            }}
            mb={"6px"}
          >
            {props.topic || "No Topic"}
          </Text>

          <Text
            variant="label"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {`${formatDistanceToNow(props.sentAt)} ago`}
          </Text>
        </Box>
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <Button
          label="accept"
          variant="small"
          onClick={async () => {
            setIsAccepting(true);
            await props.onAccept();
            setIsAccepting(false);
          }}
          disabled={isAccepting}
          mr={2}
        />
        {isAccepting ? (
          <ReactLoading
            type="spin"
            width={20}
            height={20}
            color={theme.colors.secondary}
          />
        ) : (
          <Icon
            name={props.isRecommended ? "star" : "star-outline"}
            fill={props.isRecommended ? "#DEBF3C" : theme.colors.disabled}
            containerProps={{
              "aria-label": props.isRecommended
                ? "recommended"
                : "not recommended",
            }}
          />
        )}
      </Box>
    </Box>
  );
}
