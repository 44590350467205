import { createAction } from "@reduxjs/toolkit";
import {
  OneToOneConversation,
  GenericConversation,
  Participant,
  Message,
  GroupConversation,
  InAppNotification,
  ConnectionStatus,
  BaseConversation,
  ActiveConversation,
} from "./entities";
import {
  EventMessage,
  EventPresence,
  EventTyping,
  EventMessageDisplayed,
} from "./events";

export interface AddConversationPayload {
  id: OneToOneConversation["id"];
  myId: OneToOneConversation["myId"];
  member: Omit<Participant, "isOnline" | "isListener">;
  listener: Omit<Participant, "isOnline" | "isListener">;
  type: OneToOneConversation["type"];
  startedAt?: string;
  memberJoined?: boolean;
  details?: ActiveConversation["details"];
}

export const getActiveConversationAction = createAction(
  "GetActiveConversation",
  (id: string, myUserId: string) => ({ payload: { id, myUserId } })
);

export const getConversationAction = createAction(
  "GetConversation",
  (payload: AddConversationPayload) => ({ payload })
);

export const addConversationAction = createAction(
  "AddConversation",
  (payload: AddConversationPayload) => ({ payload })
);

export interface AddGroupConversationPayload {
  id: GroupConversation["id"];
  myId: GroupConversation["myId"];
  name: GroupConversation["name"];
  participants: Omit<Participant, "isOnline">[];
}

export const getGroupConversationAction = createAction(
  "GetGroupConversation",
  (payload: AddGroupConversationPayload) => ({ payload })
);

export const addGroupConversationAction = createAction(
  "AddGroupConversation",
  (payload: AddGroupConversationPayload) => ({ payload })
);

export const sendTypingAction = createAction(
  "SendTypingAction",
  (conversationId: string) => ({
    payload: { conversationId },
  })
);

export const setConversationLoaded = createAction(
  "SetConversationLoaded",
  (conversationId: string, loaded: boolean) => ({
    payload: { conversationId, loaded },
  })
);

export const getPreviousMessagesAction = createAction(
  "GetPreviousMessagesAction",
  (conversationId: string, after?: boolean) => ({
    payload: { conversationId, after },
  })
);

export const getAllPreviousMessagesAction = createAction(
  "GetAllPreviousMessagesAction",
  (after?: boolean) => ({
    payload: { after },
  })
);

export const sendMessageAction = createAction(
  "SendMessage",
  (body: Message["body"], conversationId: GenericConversation["id"]) => ({
    payload: { body, conversationId },
  })
);

export const addMessageAction = createAction(
  "AddMessage",
  ({
    body,
    conversationId,
    createdAt,
    authorId,
    id,
    status,
    stanzaId,
    subject,
  }: EventMessage) => ({
    payload: {
      body,
      conversationId,
      authorId,
      id,
      createdAt,
      status,
      stanzaId,
      subject,
    },
  })
);

export const setMessageErrorAction = createAction(
  "SetMessageError",
  (id: Message["id"], conversationId: BaseConversation["id"]) => ({
    payload: { id, conversationId },
  })
);

export const setMessageRemovedAction = createAction(
  "SetMessageRemoved",
  (id: Message["id"], conversationId: BaseConversation["id"]) => ({
    payload: { id, conversationId },
  })
);

export const addMessagesAction = createAction(
  "AddMessages",
  (messages: EventMessage[], conversationId: string) => ({
    payload: { messages, conversationId },
  })
);

export const addPreviousMessagesAction = createAction(
  "AddPreviousMessages",
  (
    messages: (EventMessage | EventMessageDisplayed)[],
    conversationId: string
  ) => ({
    payload: { messages, conversationId },
  })
);

export const addPreviousMessagesBatchedAction = createAction(
  "AddPreviousMessagesBatched",
  (
    payload: {
      messages: (EventMessage | EventMessageDisplayed)[];
      conversationId: string;
    }[]
  ) => ({
    payload,
  })
);

export const addOptimisticMessageAction = createAction(
  "AddOptimisticMessage",
  (
    id: string,
    body: string,
    createdAt: string,
    conversationId: GenericConversation["id"]
  ) => ({
    payload: { id, body, createdAt, conversationId },
  })
);

export const addMessageFailedAction = createAction(
  "AddMessageFailed",
  (id: Message["id"], conversationId: GenericConversation["id"]) => ({
    payload: { id, conversationId },
  })
);

export const setTypingAction = createAction(
  "SetTyping",
  ({ userId, conversationId, isTyping }: EventTyping) => ({
    payload: { userId, conversationId, isTyping },
  })
);

export const sendReadIndicatorAction = createAction(
  "SendReadIndicator",
  (conversationId: string) => ({ payload: { conversationId } })
);

export const setOnlineStatusAction = createAction(
  "SetUserOnline",
  ({ userId, isOnline }: EventPresence) => ({
    payload: { userId, isOnline },
  })
);

export const setOnlineStatusesAction = createAction(
  "SetOnlineStatuses",
  (onlines: Omit<EventPresence, "type">[]) => ({ payload: onlines })
);

export const setDisplayedIndicatorAction = createAction(
  "SetDisplayedIndicator",
  (arg: EventMessageDisplayed) => ({ payload: arg })
);

export const setOptimisticDisplayedIndicatorAction = createAction(
  "SetOptimisticDisplayedIndicatorAction",
  (conversationId) => ({ payload: { conversationId } })
);

export const removeConversationAction = createAction(
  "RemoveConversation",
  (id: string) => ({ payload: { id } })
);

export const endConversationAction = createAction(
  "EndConversation",
  (id: string) => ({ payload: { id } })
);

export const sendPresenceIndicatorAction = createAction(
  "SendPresenceIndicator",
  (online: boolean) => ({ payload: { online } })
);

export const addInAppNotificationAction = createAction(
  "AddInAppNotification",
  (notification: InAppNotification) => ({ payload: { notification } })
);

export const removeInAppNotificationAction = createAction(
  "RemoveInAppNotification"
);

export type GetParticipantNextAction =
  | ReturnType<typeof setOnlineStatusAction>
  | ReturnType<typeof addMessagesAction>;
export const getParticipantAction = createAction(
  "GetParticipant",
  (
    id: string,
    conversationId: string,
    nextAction?: GetParticipantNextAction
  ) => ({
    payload: { id, conversationId, nextAction },
  })
);

export const addParticipantAction = createAction(
  "AddParticipant",
  (participant: Participant & { conversationId: string }) => ({
    payload: participant,
  })
);

export const addParticipantsAction = createAction(
  "AddParticipants",
  (participants: (Participant & { conversationId: string })[]) => ({
    payload: { participants },
  })
);

export const setConnectionStatusAction = createAction(
  "SetConnectionStatus",
  (status: ConnectionStatus) => ({ payload: { status } })
);

export type Action =
  /** Actions that we call ourselves */
  | ReturnType<typeof getActiveConversationAction>
  | ReturnType<typeof getConversationAction>
  | ReturnType<typeof getGroupConversationAction>
  | ReturnType<typeof removeConversationAction>
  | ReturnType<typeof sendMessageAction>
  | ReturnType<typeof endConversationAction>
  | ReturnType<typeof getPreviousMessagesAction>
  | ReturnType<typeof getAllPreviousMessagesAction>
  | ReturnType<typeof sendTypingAction>
  | ReturnType<typeof sendPresenceIndicatorAction>
  | ReturnType<typeof sendReadIndicatorAction>
  | ReturnType<typeof addParticipantsAction>
  | ReturnType<typeof setConnectionStatusAction>
  | ReturnType<typeof setMessageErrorAction>
  | ReturnType<typeof setMessageRemovedAction>
  /** Async actions */
  | ReturnType<typeof addConversationAction>
  | ReturnType<typeof addGroupConversationAction>
  | ReturnType<typeof addMessageAction>
  | ReturnType<typeof addMessagesAction>
  | ReturnType<typeof setTypingAction>
  | ReturnType<typeof addOptimisticMessageAction>
  | ReturnType<typeof addMessageFailedAction>
  | ReturnType<typeof setOnlineStatusAction>
  | ReturnType<typeof setOnlineStatusesAction>
  | ReturnType<typeof addPreviousMessagesAction>
  | ReturnType<typeof addPreviousMessagesBatchedAction>
  | ReturnType<typeof setConversationLoaded>
  | ReturnType<typeof addInAppNotificationAction>
  | ReturnType<typeof removeInAppNotificationAction>
  | ReturnType<typeof setDisplayedIndicatorAction>
  | ReturnType<typeof getParticipantAction>
  | ReturnType<typeof addParticipantAction>
  | ReturnType<typeof setOptimisticDisplayedIndicatorAction>;

export { default as useDispatch } from "./useDispatch";
export { default as useSelector } from "./useSelector";
