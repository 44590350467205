import React, { useState } from "react";
import { useAuth } from "context/auth";
import Input from "components/Input";
import AuthCard from "components/AuthCard";
import Link from "components/Link";

interface Props {}

export default function EmailLogin(props: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { emailLogin } = useAuth();
  return (
    <AuthCard
      as="form"
      onSubmit={onSubmit}
      mb={5}
      header="Sign in"
      subheader={
        <>
          Enter your phone number to log in. If you signed up with a phone
          number, click <Link to={"/auth"}>here</Link>.
        </>
      }
      input={
        <>
          <Input
            required
            id="email"
            label="Email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={(e: any) => {
              setError(null);
              setEmail(e.currentTarget.value);
            }}
            mb={2}
          />
          <Input
            required
            id="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e: any) => {
              setError(null);
              setPassword(e.currentTarget.value);
            }}
            error={error}
          />
        </>
      }
      mainButton="Login"
      disabled={loading}
    />
  );

  async function onSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();
    setLoading(true);
    try {
      await emailLogin(email, password);
    } catch (e) {
      setError("Incorrect email or password");
      setLoading(false);
    }
  }
}
