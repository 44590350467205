import React from "react";
// @ts-ignore
import EvaIcon from "react-eva-icons";
import { Box, BoxProps } from "rebass/styled-components";

export interface IconProps {
  name: string;
  size?: "small" | "medium" | "large" | "xlarge";
  fill?: string;
  containerProps?: BoxProps;
}

export default function Icon(props: IconProps) {
  return (
    <Box display="inline-block" mt="3px" {...props.containerProps}>
      <EvaIcon
        name={props.name}
        size={props.size || "large"}
        fill={props.fill}
      />
    </Box>
  );
}
