import { XmppClient } from "@xmpp/client";
import { ApolloClient } from "@apollo/client";

export interface IService {
  xmpp: XmppClient;
  apolloClient: ApolloClient<any>;
}

export function createService(service: IService) {
  service.xmpp.on("error", (err) => {
    // DO NOT DELETE. This event listener is needed to
    // catch exceptions which would otherwise crash the app.
    console.warn(err);
  });

  return service;
}

export default createService;
