import React, { useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useAuth } from "context/auth";
import AuthCard from "components/AuthCard";
import Link from "components/Link";
import Input from "components/Input";
import GoogleButton from "react-google-button";

interface Props {
  onSubmit: (confirm: firebase.auth.ConfirmationResult, phone: string) => void;
}

export default function PhoneLogin(props: Props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { sendVerificationCode, googleLogin } = useAuth();

  return (
    <Flex flexDirection="column" alignItems="center">
      <AuthCard
        as="form"
        onSubmit={onSubmit}
        mb={4}
        header="Sign in"
        subheader={
          <>
            Enter your phone number to log in. If you signed up with email,
            click <Link to={"/auth/email"}>here</Link>.
          </>
        }
        input={
          <>
            <PhoneInput
              value={phoneNumber}
              onChange={setPhoneNumber}
              placeholder="Phone number"
              defaultCountry="US"
              // @ts-ignore
              inputComponent={Input}
              numberInputProps={{
                containerProps: {
                  sx: {
                    marginTop: 20,
                  },
                },
                width: "150%",
              }}
            />
            <Text sx={{ height: 6 }} my={2} variant="error">
              {error}
            </Text>
          </>
        }
        mainButton="Get login code"
        disabled={loading}
      />
      <GoogleButton onClick={googleLogin} />
    </Flex>
  );

  async function onSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneNumber)) {
      setError("Invalid phone number");
      return;
    }
    setLoading(true);
    try {
      const confirm = await sendVerificationCode(phoneNumber);
      props.onSubmit(confirm, phoneNumber);
    } catch (e) {
      setError("Invalid phone number");
      setLoading(false);
    }
  }
}
