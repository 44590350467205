import React, {
  useState,
  useEffect,
  ReactNode,
  useContext,
  useRef,
} from "react";
import * as firebase from "firebase/app";
import { auth } from "firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

interface Props {
  children: ReactNode;
  value?: ContextProps;
}

interface ContextProps {
  isLoggedIn: boolean;
  verifier: auth.RecaptchaVerifier;
}

// @ts-ignore
export const FirebaseContext = React.createContext<ContextProps>();

export function FirebaseProvider(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const verifierRef = useRef<auth.RecaptchaVerifier | null>(null);
  if (verifierRef.current === null) {
    verifierRef.current = new firebase.auth.RecaptchaVerifier("reCAPTCHA", {
      size: "invisible",
      callback: function() {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    });
  }
  useEffect(() => {
    const unregisterAuthObserver = auth().onAuthStateChanged((user) => {
      setIsLoggedIn(user ? true : false);
      setIsLoading(false);
    });
    return () => unregisterAuthObserver();
  }, []);

  if (isLoading) return null;

  return (
    <FirebaseContext.Provider
      value={{
        isLoggedIn,
        verifier: verifierRef.current!,
      }}
      {...props}
    />
  );
}

export const useFirebase = (): ContextProps => useContext(FirebaseContext);
