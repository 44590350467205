import React, { useRef } from "react";
import { Box, Flex, Text, FlexProps, BoxProps } from "rebass/styled-components";
import Avatar from "components/Avatar";
import ScrollBar from "components/ScrollBar";
import { List as VList, AutoSizer, ListRowRenderer } from "react-virtualized";
import { Participant } from "store/types/entities";
import { useDedicatedChats } from "context/dedicatedChats";
import Button from "components/Button";

interface Props extends FlexProps {
  participants: Participant[];
}

export default function OnlineStatusSidebar(props: Props) {
  const { getListenersData, listenersDataGotten } = useDedicatedChats();
  const list = useRef(null);
  const { participants } = props;

  const onlines = participants.filter((p) => p.isOnline);
  const offlines = participants.filter((p) => !p.isOnline);
  const all = onlines.concat(offlines);

  const renderRow: ListRowRenderer = ({ index, key, style }) => {
    const p = all[index];
    return (
      <div style={style} key={key}>
        <ProfileCard
          avatarUrl={p.avatarUrl}
          name={p.name}
          isOnline={p.isOnline}
        />
      </div>
    );
  };

  const handleScroll = (scrollValues: any) => {
    const { scrollTop, scrollLeft } = scrollValues;
    const { Grid }: any = list.current;
    Grid.handleScrollEvent({ scrollTop, scrollLeft });
  };

  return (
    <Flex
      bg="overlay"
      flexDirection="column"
      width={310}
      minWidth={310}
      sx={{
        borderLeft: "1px solid",
        borderLeftColor: "divider",
      }}
    >
      {!listenersDataGotten && (
        <Button label="View all listeners" onClick={getListenersData} />
      )}
      <div style={{ flex: "1 1 auto" }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <ScrollBar
                noScrollX
                style={{ width, height }}
                onScroll={handleScroll}
              >
                <VList
                  width={width}
                  height={height}
                  rowCount={participants.length}
                  rowHeight={60}
                  rowRenderer={renderRow}
                  style={{ overflowY: undefined, overflowX: undefined }}
                  ref={list}
                />
              </ScrollBar>
            );
          }}
        </AutoSizer>
      </div>
    </Flex>
  );
}

interface ProfileCardProps {
  avatarUrl?: string;
  name: string;
  isOnline: boolean;
  containerProps?: BoxProps;
}

function ProfileCard(props: ProfileCardProps) {
  return (
    <Flex
      height={60}
      px={20}
      py={10}
      sx={{
        position: "relative",
        justifyContent: "space-between",
      }}
      {...props.containerProps}
    >
      <Flex>
        <Avatar
          url={props.avatarUrl}
          name={props.name}
          mr={3}
          alignSelf="center"
          size={35}
        />
        <Flex
          sx={{
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            variant="main"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 200,
            }}
          >
            {props.name}
          </Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        {props.isOnline && (
          <Box
            bg="secondary"
            sx={{ height: 10, width: 10, borderRadius: 100 }}
          />
        )}
      </Flex>
    </Flex>
  );
}
