import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetMyDedicatedChatsQuery,
  GetMyDedicatedChatsQuery,
  useEndDedicatedChatMutation,
  useAddedToDedicatedChatSubscription,
  useRemovedFromDedicatedChatSubscription,
  useGetListenersDataLazyQuery,
  useMyChatEndedSubscription,
  ChatRequest,
} from "graphql/__generated__/types";
import useChats from "store/hooks/useChats";
import useInAppNotificationHandler from "store/hooks/useInAppNotificationHandler";
import { NetworkStatus, ExecutionResult } from "@apollo/client";
import { useUser } from "context/user";
import { useAuth } from "context/auth";

interface ContextProps {
  networkStatus: NetworkStatus;
  endDedicatedChat: (
    chatId: string,
    reason: string
  ) => Promise<ExecutionResult>;
  loading: boolean;
  getListenersData: () => void;
  isListenersDataLoaded: boolean;
  listenersDataGotten: boolean;
  // totalUnreadCount: number;
}

const DedicatedChatsContext = React.createContext<ContextProps | null>(null);

function DedicatedChatsProvider(props: any) {
  const {
    getActiveConversation,
    getConversation,
    getGroupConversation,
    endConversation,
    addParticipants,
  } = useChats();

  const user = useUser();
  const { data: authData } = useAuth();
  const addInAppNotification = useInAppNotificationHandler();
  const gotDedicatedChatsRef = useRef<boolean>(false);

  const { data, networkStatus } = useGetMyDedicatedChatsQuery({
    skip: gotDedicatedChatsRef.current,
    onCompleted() {
      gotDedicatedChatsRef.current = true;
    },
  });

  useEffect(() => {
    const currentRequests = authData?.currentRequests as ChatRequest[];

    if(currentRequests && currentRequests.length > 0) {
      currentRequests.forEach((currentRequest) => {
        const { id, listener } = currentRequest;

        const myId = listener?.id === user.listener!.id ? listener.id : user.id;

        getActiveConversation(id, myId);
      });
    }
  }, [authData, getActiveConversation, user.id, user.listener]);

  useMyChatEndedSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      const newSub = subscriptionData.data?.myChatEnded;
      if (newSub) {
        endConversation(newSub.id);
      }
    },
  });

  useAddedToDedicatedChatSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      const newSub = subscriptionData.data?.addedToDedicatedChat;
      if (newSub) {
        const member = {
          id: newSub.user.id,
          name: newSub.user.alias!,
        };
        const listener = {
          id: newSub.listener.id,
          name: newSub.listener.firstName,
          avatarUrl: newSub.listener.avatarUrl || undefined,
        };
        getConversation({
          id: newSub.id,
          myId:
            user.listener?.id !== newSub.listener.id
              ? user.id
              : user.listener?.id,
          member,
          listener,
          type: "dedicated",
        });
        if (user.listener?.id !== newSub.listener.id) {
          addInAppNotification({
            text: `${newSub.listener.firstName} invited you to a dedicated chat`,
            avatarUrl: newSub.listener.avatarUrl || undefined,
            name: newSub.listener.firstName,
            conversationId: newSub.id,
            type: "AddedToDedicatedChat",
          });
        }
      }
    },
  });

  useRemovedFromDedicatedChatSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      const newSub = subscriptionData.data?.removedFromDedicatedChat;
      if (newSub) {
        endConversation(newSub.id);
      }
    },
  });
  const [
    getListenersData,
    { data: listenersData, called: listenersDataGotten },
  ] = useGetListenersDataLazyQuery();
  const [endDedicatedChatMutation] = useEndDedicatedChatMutation();
  const [isLoading, setIsLoading] = useState(true);

  const getChannels = useCallback(
    async (dedicatedChats: GetMyDedicatedChatsQuery["getMyDedicatedChats"]) => {
      dedicatedChats.forEach((dc) => {
        const member = { name: dc.member.alias!, id: dc.member.id };
        const listener = {
          name: dc.listener.firstName,
          id: dc.listener.id,
          avatarUrl: dc.listener.avatarUrl || undefined,
        };
        getConversation({
          id: dc.id,
          myId: dc.isListener ? listener.id : member.id,
          member,
          listener,
          type: "dedicated",
        });
      });
    },
    [getConversation]
  );

  useEffect(() => {
    if (data) {
      getChannels(data.getMyDedicatedChats);
      setIsLoading(false);
    }
  }, [data, getChannels]);

  useEffect(() => {
    if (user.listener?.isEnabled && !listenersData) {
      const conversationPayload = {
        id: "listeners-group-chat",
        name: "Listeners Group Chat",
        myId: user.listener.id,
        participants: [],
      };
      getGroupConversation(conversationPayload);
    }
    if (listenersData) {
      const participants = listenersData.getListenersData.map((l) => ({
        id: l.id,
        avatarUrl: l.avatarUrl || undefined,
        name: l.firstName,
        isListener: true,
        isModerator: l.isModerator || undefined,
        isOnline: false,
        conversationId: "listeners-group-chat",
      }));
      addParticipants(participants);
    }
  }, [addParticipants, getGroupConversation, listenersData, user.listener]);

  function endDedicatedChat(chatId: string, reason: string) {
    return endDedicatedChatMutation({
      variables: {
        chatId,
        reason,
      },
    });
  }

  return (
    <DedicatedChatsContext.Provider
      value={{
        networkStatus,
        endDedicatedChat,
        loading: isLoading,
        getListenersData,
        isListenersDataLoaded: !!listenersData?.getListenersData,
        listenersDataGotten,
      }}
      {...props}
    />
  );
}

const useDedicatedChats = () =>
  React.useContext(DedicatedChatsContext) as ContextProps;

export { DedicatedChatsProvider, useDedicatedChats };
