import React, { useState } from "react";
import { ThemeProvider as SThemeProvider } from "styled-components";
import themes from "constants/themes";

interface ContextProps {
  setIsDarkMode: (isDark: boolean) => void;
  isDark: boolean;
}

interface Props {}

// @ts-ignore
export const ThemeContext = React.createContext<ContextProps>();

function ThemeProvider(props: Props) {
  const stored = localStorage.getItem("isDarkMode");
  const [isDarkMode, _setIsDarkMode] = useState(
    stored === "true" ? true : false
  );

  const setIsDarkMode = (isDarkMode: boolean) => {
    localStorage.setItem("isDarkMode", isDarkMode ? "true" : "false");
    _setIsDarkMode(isDarkMode);
  };
  return (
    <SThemeProvider theme={isDarkMode ? themes.darkTheme : themes.lightTheme}>
      <ThemeContext.Provider
        value={{ setIsDarkMode, isDark: isDarkMode }}
        {...props}
      />
    </SThemeProvider>
  );
}

const useTheme = () => React.useContext(ThemeContext) as ContextProps;

export { ThemeProvider, useTheme };
