import { from, empty, EMPTY } from "rxjs";
import { filter, map, mergeMap, endWith, catchError } from "rxjs/operators";
import {
  getParticipantAction,
  addParticipantAction,
  addParticipantsAction,
} from "../types/actions";
import { IEpic } from ".";
import { GET_LISTENER } from "graphql/queries/getListener";
import { ApolloQueryResult } from "@apollo/client";
import { GetListenerQuery } from "graphql/__generated__/types";
import { batchActions } from "./helpers";

const getParticipantEpic: IEpic = (action$, state$, { service }) =>
  action$.pipe(
    filter(getParticipantAction.match),
    // filter((action) => action.payload.id !== "system"),
    mergeMap((a) =>
      from(
        service.apolloClient.query({
          query: GET_LISTENER,
          variables: { id: a.payload.id },
        }) as Promise<ApolloQueryResult<GetListenerQuery>>
      ).pipe(
        catchError(() => empty()),
        filter(({ data }) => Boolean(data && data.listener)),
        map(({ data }) =>
          addParticipantAction({
            id: data!.listener!.id,
            name: data!.listener!.firstName,
            isOnline: a.payload.nextAction?.type === "SetUserOnline",
            isListener: true,
            avatarUrl: data!.listener!.avatarUrl || undefined,
            isModerator: data!.listener!.isModerator || undefined,
            conversationId: a.payload.conversationId,
          })
        ),
        a.payload.nextAction && a.payload.nextAction.type !== "SetUserOnline"
          ? endWith(a.payload.nextAction)
          : map((a) => a)
      )
    ),
    batchActions(
      "AddParticipant",
      addParticipantsAction,
      (items: ReturnType<typeof addParticipantAction>[]) =>
        items.map((i) => i.payload)
    )
  );

export default getParticipantEpic;
