import React, { useEffect, useRef } from "react";
import { Flex, FlexProps, Text } from "rebass/styled-components";
import MessageComposer from "components/MessageComposer";
import ChatMessage from "components/ChatMessage";
import { Message } from "store/types/entities";
import Button from "components/Button";

export type RenderableMessage = Message & {
  author?: {
    name: string;
    avatarUrl?: string;
  };
};

interface Props extends FlexProps {
  messages: RenderableMessage[];
  conversationId: string;
  usersTyping?: string[];
  isGroup?: boolean;
  isEnded?: boolean;
  getOlderMessages: () => void;
  gettingOlderMessages: boolean;
  fullyLoaded: boolean;
  myId: string;
  sendMessage: (body: string) => void;
  sendTypingIndicator: () => void;
}

const getLastId = (messages: RenderableMessage[]) => {
  if (messages.length === 0) return null;
  return messages[messages.length - 1].id;
};

export default function MessagesContainer({
  messages,
  conversationId,
  usersTyping = [],
  isEnded,
  isGroup,
  getOlderMessages,
  gettingOlderMessages,
  fullyLoaded,
  myId,
  sendMessage,
  sendTypingIndicator,
  ...rest
}: Props) {
  /** Dummy div for scrolling to the bottom when a new message comes in */
  const scrollDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollIntoView();
    }
  }, [getLastId(messages)]);

  const formattedMessages = messages.map((m, i, arr) => ({
    ...m,
    showAvatar: i === 0 || arr[i - 1].authorId !== m.authorId,
    showSender: isGroup && (i === 0 || arr[i - 1].authorId !== m.authorId),
  }));

  return (
    <Flex mx={3} sx={{ flex: 1, flexDirection: "column" }} {...rest}>
      <Flex my={3} style={{ overflowY: "auto" }} flexDirection="column">
        {!fullyLoaded && (
          <Button
            label={gettingOlderMessages ? "Loading" : "Get older messages"}
            onClick={getOlderMessages}
            disabled={gettingOlderMessages}
            mx="auto"
            alignSelf="center"
            mb={3}
          />
        )}
        {formattedMessages.map((m) => {
          return (
            <div key={m.id}>
              {m.author === undefined ? (
                <Text
                  variant="label"
                  fontSize={12}
                  paddingBottom="1px"
                  textAlign="center"
                  mb={3}
                >
                  {m.body}
                </Text>
              ) : (
                <ChatMessage
                  side={m.authorId === myId ? "right" : "left"}
                  msg={m.body}
                  sentAt={new Date(m.createdAt)}
                  sender={m.author.name}
                  avatarUrl={m.author.avatarUrl}
                  showAvatar={m.showAvatar}
                  state={m.status}
                  showSender={m.showSender}
                  displayed={m.displayed}
                />
              )}
            </div>
          );
        })}
        <div ref={scrollDivRef} />
      </Flex>
      {renderChatFooter()}
      <MessageComposer
        disabled={isEnded}
        conversationId={conversationId}
        sendMessage={sendMessage}
        sendTypingIndicator={sendTypingIndicator}
        sx={{
          // position: "absolute",
          marginTop: "auto",
          marginBottom: 10,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      />
    </Flex>
  );

  function renderChatFooter() {
    const text =
      usersTyping.length === 0
        ? ""
        : usersTyping.length === 1
        ? `${usersTyping[0]} is typing...`
        : `${usersTyping.join(", ")} are typing...`;
    return (
      <Text
        variant="label"
        fontSize={12}
        paddingBottom="1px"
        mb={3}
        height={20}
      >
        {text}
      </Text>
    );
  }
}
