import colors from "./colors";

const baseTheme = {
  fonts: {
    body: "canada-type-gibson, sans-serif",
    heading: "canada-type-gibson, sans-serif",
  },
  fontSizes: [14, 16, 18, 20, 24],
  colors: {
    ...colors.light,
    modes: colors,
  } as const,
  buttons: {
    large: {
      paddingTop: "25px",
      paddingBottom: "25px",
      borderRadius: 99999,
      flex: 1,
      maxWidth: "90%",
    },
    small: {
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 3,
      paddingRight: 3,
      borderRadius: 9999,
    },
  },
};

const lightTheme = {
  name: "light",
  ...baseTheme,
  colors: colors.light,
  text: getTypographyStyles(colors.light),
};

const darkTheme = {
  name: "dark",
  ...baseTheme,
  colors: colors.dark,
  text: getTypographyStyles(colors.dark),
};

function getTypographyStyles(colors: any) {
  return {
    title: {
      fontSize: 28,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      color: colors.text.primary,
    },
    header: {
      fontSize: 24,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      color: colors.text.primary,
    },
    sectionHeader: {
      fontSize: 18,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      color: colors.text.primary,
    },
    smallSectionHeader: {
      fontSize: 14,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      color: colors.text.tertiary,
    },
    main: {
      fontSize: 16,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 300,
      fontStyle: "normal",
      color: colors.text.primary,
    },
    body: {
      fontSize: 14,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 300,
      fontStyle: "normal",
      color: colors.text.primary,
    },
    buttonLabel: {
      fontSize: 14,
      letterSpacing: 1.5,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 600,
      color: colors.text.primary,
    },
    label: {
      fontSize: 14,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 300,
      color: colors.text.tertiary,
    },
    large: {
      fontSize: 24,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 400,
      fontStyle: "normal",
      color: colors.text.primary,
    },
    medium: {
      fontSize: 16,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 500,
      fontStyle: "normal",
      color: colors.text.primary,
    },
    error: {
      fontSize: 14,
      fontFamily: "canada-type-gibson, sans-serif",
      fontWeight: 300,
      color: colors.error,
    },
  };
}

export default { lightTheme, darkTheme };
