import React, { useContext } from "react";
import { Button as RButton } from "rebass/styled-components";
import { ThemeContext } from "styled-components";
import { adjustColorBrightness } from "helpers/adjustColorBrightness";

interface Props {
  onToggle: () => void;
  isSelected: boolean;
  disabled?: boolean;
  label: string;
}

export default function ToggleButton(props: Props) {
  const theme = useContext(ThemeContext);
  return (
    <RButton
      type="button"
      bg={
        props.isSelected
          ? theme.colors.primaryLight
          : props.disabled
          ? theme.colors.disabled
          : theme.colors.panel
      }
      m={1}
      onClick={props.onToggle}
      disabled={props.disabled}
      sx={{
        flexShrink: 0,
        fontSize: 16,
        fontFamily: "canada-type-gibson, sans-serif",
        color: props.isSelected
          ? theme.colors.white
          : theme.colors.text.primary,
        border: "1px solid",
        borderColor: "divider",
        fontWeight: 600,
        ":hover": {
          backgroundColor: props.disabled
            ? theme.colors.disabled
            : adjustColorBrightness(
                props.isSelected
                  ? theme.colors.primaryLight
                  : theme.colors.panel,
                3
              ),
          cursor: props.disabled ? "default" : "pointer",
        },
        ":focus": {
          outline: "none",
          backgroundColor: adjustColorBrightness(
            props.isSelected ? theme.colors.primaryLight : theme.colors.panel,
            3
          ),
        },
      }}
    >
      {props.label}
    </RButton>
  );
}
