import { from, empty } from "rxjs";
import { filter, exhaustMap, map, catchError } from "rxjs/operators";
import {
  sendReadIndicatorAction,
  setOptimisticDisplayedIndicatorAction,
} from "../types/actions";
import { sendDisplayedMarker } from "../service/xmlGenerators";
import { IEpic } from "./";
import { OneToOneConversation } from "store/types/entities";
import { retryIfOffline, xmppSend } from "./helpers";

const sendReadIndicatorEpic: IEpic = (action$, state$, { service }) =>
  action$.pipe(
    filter(sendReadIndicatorAction.match),
    map(a => {
      const convo = state$.value.conversations[
        a.payload.conversationId
      ] as OneToOneConversation;
      const lastMessage = convo.messages[convo.messages.length - 1];
      return { ...a, lastMessage, convo };
    }),
    filter(
      a =>
        a.lastMessage &&
        a.lastMessage.authorId !== "system" &&
        a.lastMessage.authorId !== a.convo.myId &&
        !a.lastMessage.displayed,
    ),
    retryIfOffline(
      service,
      action =>
        state$.value.participants[
          state$.value.conversations[action.payload.conversationId].myId
        ].isOnline,
      "sendReadIndicatorEpic",
    ),
    exhaustMap(action => {
      // Send only if the last message is from the partner and not read yet
      return from(
        xmppSend(
          service.xmpp,
          sendDisplayedMarker(action.convo.id, action.lastMessage.stanzaId),
        ),
      ).pipe(
        map(() =>
          setOptimisticDisplayedIndicatorAction(action.payload.conversationId),
        ),
        catchError(() => empty()),
      );
    }),
  );

export default sendReadIndicatorEpic;
