import React from "react";
import { Flex } from "rebass/styled-components";
import { ReactComponent as Logo } from "assets/logo.svg";

interface Props {
  children?: React.ReactNode;
}

export default function HeaderBar(props: Props) {
  const { children, ...rest } = props;
  return (
    <Flex
      px={3}
      py={"15px"}
      height={70}
      color="white"
      bg="overlay"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: "1px solid",
        borderBottomColor: "divider",
      }}
      {...rest}
    >
      <Logo />
      {children}
    </Flex>
  );
}
