import React, { useContext } from "react";
import { Box, Text, Flex } from "rebass/styled-components";
import Avatar from "components/Avatar";
import { format } from "date-fns";
import Icon from "components/Icon";
import { ThemeContext } from "styled-components";

interface Props {
  side: "left" | "right";
  msg: string;
  sentAt: Date;
  sender: string;
  state: "sending" | "sent" | "error";
  displayed?: boolean;
  showAvatar?: boolean;
  showSender?: boolean;
  avatarUrl?: string;
}

export default function ChatMessage(props: Props) {
  return (
    <Flex
      mt={props.showAvatar ? 3 : 0}
      mb={1}
      flexDirection={props.side === "left" ? "row" : "row-reverse"}
    >
      {props.showAvatar ? (
        <Avatar name={props.sender} url={props.avatarUrl} />
      ) : (
        // Hidden element to take up space that avatar would
        <Box height={48} width={48} display="inline-block" />
      )}
      <Box
        bg={props.side === "left" ? "secondary" : "overlay"}
        paddingY={2}
        paddingX={"10px"}
        ml={props.side === "left" ? 2 : 0}
        mr={props.side === "right" ? 2 : 0}
        sx={{
          borderRadius: 6,
          border: props.side === "right" ? "1px solid" : null,
          borderColor: "divider",
          maxWidth: "60%",
          justifyItems: "start",
        }}
      >
        {props.showSender && props.side === "left" && (
          <Text
            variant="label"
            color={"white"}
            fontSize={12}
            mr={1}
            paddingBottom={"1px"}
            alignSelf="start"
            mb={1}
          >
            {props.sender}
          </Text>
        )}
        <Text
          variant="main"
          color={props.side === "left" ? "white" : "text.primary"}
          mb={2}
          sx={{
            wordBreak: "break-all",
          }}
        >
          {props.msg}
        </Text>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text
            variant="label"
            color={props.side === "left" ? "white" : "text.tertiary"}
            fontSize={12}
            textAlign="center"
          >
            {format(props.sentAt, "hh:mma")}
          </Text>
          {props.side === "right" && (
            <StatusIcon displayed={props.displayed} status={props.state} />
          )}
        </Flex>
      </Box>
    </Flex>
  );
}

const StatusIcon = ({
  status,
  displayed,
}: {
  status: "sending" | "sent" | "error";
  displayed?: boolean;
}) => {
  const theme = useContext(ThemeContext);

  const iconName =
    status === "sending"
      ? "loader-outline"
      : displayed
      ? "done-all-outline"
      : "checkmark-outline";

  const iconColor =
    status === "sending"
      ? theme.colors.disabled
      : status === "sent"
      ? theme.colors.secondary
      : theme.colors.error;

  const ariaLabel =
    status === "sending" ? "sending" : status === "sent" ? "sent" : "error";

  return (
    <Icon
      key={iconName}
      name={iconName}
      size="small"
      fill={iconColor}
      containerProps={{ ml: 1, "aria-label": ariaLabel }}
    />
  );
};
