import { createEpicMiddleware } from "redux-observable";
import { configureStore, Middleware } from "@reduxjs/toolkit";
import reducer, { RootState } from "./reducers";
import { Action } from "./types/actions";
import rootEpic, { Dependencies } from "./epics";

const createStore = ({ service }: Dependencies) => {
  const epicMiddleware = createEpicMiddleware<
    Action,
    Action,
    RootState,
    Dependencies
  >({
    dependencies: { service },
  });

  let middleware: Middleware[] = [epicMiddleware];

  // if (__DEV__) {
  //   middleware.push(...getDefaultMiddleware());
  // }

  const store = configureStore({
    reducer,
    middleware,
  });

  epicMiddleware.run(rootEpic);

  return store;
};

export default createStore;
