import gql from "graphql-tag";

export const GET_CHAT = gql`
  query getChat($id: ID!) {
    getChatRequest(id: $id) {
      createdAt
      id
      state
      member {
        id
        alias
        isNewMember
      }
      listener {
        id
        firstName
        avatarUrl
        bio
        createdAt
        gender
        age
        sessionCount
        topics {
          name
          id
        }
      }
      chat {
        id
        startedAt
        memberJoined
      }
      selectedTopic {
        id
        name
        description
      }
    }
  }
`;
